// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuPi2dZrLO6nJtQnOR6yqdpR9bNjJI4sg",
  authDomain: "officialkasun-67c2f.firebaseapp.com",
  projectId: "officialkasun-67c2f",
  storageBucket: "officialkasun-67c2f.firebasestorage.app",
  messagingSenderId: "40343799427",
  appId: "1:40343799427:web:6ac5ad6b8f7ff07743fdb6",
  measurementId: "G-89YP0R407F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'consent',
  tenant: 'common'
});
const storage = getStorage(app);

export { app, database as firestore, auth, googleProvider, microsoftProvider, storage };
