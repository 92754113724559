import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebaseConfig';
import { collection, setDoc, deleteDoc, doc, getDocs, addDoc, updateDoc, deleteField } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import {
  TextField, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip, FormControlLabel, Switch,
  MenuItem, Select, InputLabel, FormControl, RadioGroup, FormLabel, Radio, InputAdornment, LinearProgress, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
// Fix imports to match LovePage.jsx exactly
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { toast } from 'react-toastify';
import { countries } from 'countries-list';

const ManageProjects = () => {
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({
    alias: '',
    description: '',
    estimatedCost: '',
    currency: 'USD',
    image: '',
    link: '',
    category: '',
    technologies: [],
    title: '',
    year: new Date().getFullYear(),
    country: '',
    status: 'Completed',
    duration: '',
    clientType: 'Corporate',
    isActive: true, // Default to active for new projects
    isPublic: true, // Default to public visibility
    isPublicPaid: false // Default to not requiring payment for public visibility
  });
  const [open, setOpen] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dense, setDense] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [confirmDelete, setConfirmDelete] = useState({ open: false, id: null });
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [currentProjectForDocs, setCurrentProjectForDocs] = useState(null);
  const [newDocument, setNewDocument] = useState({
    title: '',
    link: '',
    issueDate: null,
    endDate: null,
    isUrl: false,
    file: null,
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [editDocumentMode, setEditDocumentMode] = useState(false);
  const [currentEditDocument, setCurrentEditDocument] = useState(null);
  const storage = getStorage();

  const countryList = Object.entries(countries).map(([code, country]) => ({
    code,
    name: country.name
  }));

  const categories = [
    'Web App Development',
    'Software Development',
    'Android App Development',
    'iOS App Development',
    'Cloud Solutions',
    'DevOps Implementation',
    'Enterprise Software',
    'E-commerce Solutions',
    'CRM Systems',
    'AI/ML Solutions',
    'Blockchain Development',
    'IoT Solutions'
  ];
  
  // Define project status options
  const projectStatusOptions = [
    'Just Started',
    'Pending',
    'Under Development',
    'Under Maintainance',
    'Completed',
    'Cancelled',
    'On Hold'
  ];

  const [charges, setCharges] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [balanceDue, setBalanceDue] = useState(0);

  const handleAddCharge = () => {
    // Open the item modal with a new empty item instead of directly adding to charges
    openItemModal();
  };

  const handleUpdateCharge = (index, field, value) => {
    const updatedCharges = [...charges];
    updatedCharges[index][field] = value;

    if (field === 'quantity' || field === 'unitPrice' || field === 'discount' || field === 'taxes') {
      const quantity = parseFloat(updatedCharges[index].quantity) || 0;
      const unitPrice = parseFloat(updatedCharges[index].unitPrice) || 0;
      const discount = updatedCharges[index].discount.type === 'percentage'
        ? (unitPrice * quantity * (parseFloat(updatedCharges[index].discount.value) || 0)) / 100
        : parseFloat(updatedCharges[index].discount.value) || 0;

      const taxAmount = updatedCharges[index].taxes.reduce((total, tax) => {
        return total + (tax.type === 'percentage'
          ? (unitPrice * quantity - discount) * (parseFloat(tax.value) || 0) / 100
          : parseFloat(tax.value) || 0);
      }, 0);

      updatedCharges[index].subtotal = unitPrice * quantity - discount + taxAmount;
    }

    setCharges(updatedCharges);
    calculateTotals(updatedCharges);
  };

  const handleDeleteCharge = (index) => {
    const updatedCharges = charges.filter((_, i) => i !== index);
    setCharges(updatedCharges);
    calculateTotals(updatedCharges);
  };

  const calculateTotals = (charges) => {
    const totalDiscount = charges.reduce((total, charge) => {
      const quantity = parseFloat(charge.quantity) || 0;
      const unitPrice = parseFloat(charge.unitPrice) || 0;
      return total + (charge.discount && charge.discount.type === 'percentage'
        ? (unitPrice * quantity * (parseFloat(charge.discount.value) || 0)) / 100
        : parseFloat(charge.discount?.value) || 0);
    }, 0);

    const totalTaxes = charges.reduce((total, charge) => {
      return total + (charge.taxes || []).reduce((taxTotal, tax) => {
        const quantity = parseFloat(charge.quantity) || 0;
        const unitPrice = parseFloat(charge.unitPrice) || 0;
        const discount = charge.discount && charge.discount.type === 'percentage'
          ? (unitPrice * quantity * (parseFloat(charge.discount.value) || 0)) / 100
          : parseFloat(charge.discount?.value) || 0;

        return taxTotal + (tax?.type === 'percentage'
          ? (unitPrice * quantity - discount) * (parseFloat(tax?.value) || 0) / 100
          : parseFloat(tax?.value) || 0);
      }, 0);
    }, 0);

    // Calculate total amount directly from subtotals of each charge
    const totalAmount = charges.reduce((total, charge) => 
      total + (charge.subtotal !== undefined && charge.subtotal !== null ? charge.subtotal : 0), 0);
    
    const balanceDue = totalAmount - amountPaid;

    setTotalDiscount(totalDiscount);
    setTotalTaxes(totalTaxes);
    setTotalAmount(totalAmount);
    setBalanceDue(balanceDue);
  };

  const handleAmountPaidChange = (value) => {
    setAmountPaid(value);
    setBalanceDue(totalAmount - value);
  };

  const [editCharges, setEditCharges] = useState([]);
  const [editTotalDiscount, setEditTotalDiscount] = useState(0);
  const [editTotalTaxes, setEditTotalTaxes] = useState(0);
  const [editTotalAmount, setEditTotalAmount] = useState(0);
  const [editAmountPaid, setEditAmountPaid] = useState(0);
  const [editBalanceDue, setEditBalanceDue] = useState(0);

  const handleAddEditCharge = () => {
    // Open the item modal with a new empty item instead of directly adding to edit charges
    openItemModal();
  };

  const handleUpdateEditCharge = (index, field, value) => {
    const updatedCharges = [...editCharges];
    updatedCharges[index][field] = value;

    if (field === 'quantity' || field === 'unitPrice' || field === 'discount' || field === 'taxes') {
      const quantity = parseFloat(updatedCharges[index].quantity) || 0;
      const unitPrice = parseFloat(updatedCharges[index].unitPrice) || 0;
      const discount = updatedCharges[index].discount.type === 'percentage'
        ? (unitPrice * quantity * (parseFloat(updatedCharges[index].discount.value) || 0)) / 100
        : parseFloat(updatedCharges[index].discount.value) || 0;

      const taxAmount = updatedCharges[index].taxes.reduce((total, tax) => {
        return total + (tax.type === 'percentage'
          ? (unitPrice * quantity - discount) * (parseFloat(tax.value) || 0) / 100
          : parseFloat(tax.value) || 0);
      }, 0);

      updatedCharges[index].subtotal = unitPrice * quantity - discount + taxAmount;
    }

    setEditCharges(updatedCharges);
    calculateEditTotals(updatedCharges);
  };

  const handleDeleteEditCharge = (index) => {
    const updatedCharges = editCharges.filter((_, i) => i !== index);
    setEditCharges(updatedCharges);
    calculateEditTotals(updatedCharges);
  };

  const calculateEditTotals = (charges) => {
    const totalDiscount = charges.reduce((total, charge) => {
      const quantity = parseFloat(charge.quantity) || 0;
      const unitPrice = parseFloat(charge.unitPrice) || 0;
      return total + (charge.discount && charge.discount.type === 'percentage'
        ? (unitPrice * quantity * (parseFloat(charge.discount.value) || 0)) / 100
        : parseFloat(charge.discount?.value) || 0);
    }, 0);

    const totalTaxes = charges.reduce((total, charge) => {
      return total + (charge.taxes || []).reduce((taxTotal, tax) => {
        const quantity = parseFloat(charge.quantity) || 0;
        const unitPrice = parseFloat(charge.unitPrice) || 0;
        const discount = charge.discount && charge.discount.type === 'percentage'
          ? (unitPrice * quantity * (parseFloat(charge.discount.value) || 0)) / 100
          : parseFloat(charge.discount?.value) || 0;

        return taxTotal + (tax?.type === 'percentage'
          ? (unitPrice * quantity - discount) * (parseFloat(tax?.value) || 0) / 100
          : parseFloat(tax?.value) || 0);
      }, 0);
    }, 0);

    // Calculate total amount directly from subtotals of each charge
    const totalAmount = charges.reduce((total, charge) => 
      total + (charge.subtotal !== undefined && charge.subtotal !== null ? charge.subtotal : 0), 0);
    
    const balanceDue = totalAmount - editAmountPaid;

    setEditTotalDiscount(totalDiscount);
    setEditTotalTaxes(totalTaxes);
    setEditTotalAmount(totalAmount);
    setEditBalanceDue(balanceDue);
  };

  const handleEditAmountPaidChange = (value) => {
    setEditAmountPaid(value);
    setEditBalanceDue(editTotalAmount - value);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'projects'));
      const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectsList);
    };
    fetchProjects();
  }, []);

  const handleAddProject = async () => {
    // Make sure each charge has a unique ID before saving
    const chargesWithIds = charges.map(charge => {
      if (!charge.id) {
        return { ...charge, id: uuidv4() };
      }
      
      // Create a copy to avoid modifying the original object
      const updatedCharge = { ...charge };
      
      // Ensure date fields are stored as ISO strings
      if (updatedCharge.purchasedDate instanceof Date) {
        updatedCharge.purchasedDate = updatedCharge.purchasedDate.toISOString();
      }
      if (updatedCharge.renewedDate instanceof Date) {
        updatedCharge.renewedDate = updatedCharge.renewedDate.toISOString();
      }
      if (updatedCharge.nextDueDate instanceof Date) {
        updatedCharge.nextDueDate = updatedCharge.nextDueDate.toISOString();
      }
      
      return updatedCharge;
    });
    
    const projectToAdd = {
      ...newProject,
      lowerTitle: newProject.title.toLowerCase(),
      charges: chargesWithIds, // Use the charges with guaranteed IDs
      totalDiscount: totalDiscount, // Save calculated totals
      totalTaxes: totalTaxes,
      totalAmount: totalAmount,
      amountPaid: amountPaid,
      balanceDue: balanceDue
    };
    
    await setDoc(doc(firestore, 'projects', newProject.alias), projectToAdd);
    setNewProject({
      alias: '',
      description: '',
      estimatedCost: '',
      currency: 'USD',
      image: '',
      link: '',
      category: '',
      technologies: [],
      title: '',
      year: new Date().getFullYear(),
      country: '',
      status: 'Completed',
      duration: '',
      clientType: 'Corporate',
      isActive: true, // Reset with default active
      isPublic: true, // Reset with default public
      isPublicPaid: false // Reset with default not paid
    });
    setCharges([]); // Reset charges after saving
    toast.success('Project added successfully');
    setOpen(false);
    const querySnapshot = await getDocs(collection(firestore, 'projects'));
    const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProjects(projectsList);
  };

  const handleDeleteProject = async (id) => {
    await deleteDoc(doc(firestore, 'projects', id));
    toast.success('Project deleted successfully');
    setProjects(projects.filter(project => project.id !== id));
    setConfirmDelete({ open: false, id: null });
  };

  const handleUpdateProject = async () => {
    // Make sure each charge has a unique ID before updating
    const chargesWithIds = editCharges.map(charge => {
      if (!charge.id) {
        return { ...charge, id: uuidv4() };
      }
      
      // Create a copy to avoid modifying the original object
      const updatedCharge = { ...charge };
      
      // Ensure date fields are stored as ISO strings
      if (updatedCharge.purchasedDate instanceof Date) {
        updatedCharge.purchasedDate = updatedCharge.purchasedDate.toISOString();
      }
      if (updatedCharge.renewedDate instanceof Date) {
        updatedCharge.renewedDate = updatedCharge.renewedDate.toISOString();
      }
      if (updatedCharge.nextDueDate instanceof Date) {
        updatedCharge.nextDueDate = updatedCharge.nextDueDate.toISOString();
      }
      
      return updatedCharge;
    });
    
    const projectToUpdate = {
      ...editProject,
      lowerTitle: editProject.title.toLowerCase(),
      charges: chargesWithIds, // Use the charges with guaranteed IDs
      totalDiscount: editTotalDiscount, // Save calculated totals
      totalTaxes: editTotalTaxes,
      totalAmount: editTotalAmount,
      amountPaid: editAmountPaid,
      balanceDue: editBalanceDue
    };
    await setDoc(doc(firestore, 'projects', editProject.alias), projectToUpdate);
    setEditProject(null);
    setEditCharges([]); // Reset edit charges after saving
    toast.success('Project updated successfully');
    const querySnapshot = await getDocs(collection(firestore, 'projects'));
    const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProjects(projectsList);
  };

  const handleAddTechnology = () => {
    setNewProject({ ...newProject, technologies: [...newProject.technologies, ''] });
  };

  const handleEditTechnology = (index, value) => {
    const newTechnologies = [...editProject.technologies];
    newTechnologies[index] = value;
    setEditProject({ ...editProject, technologies: newTechnologies });
  };

  const handleDeleteTechnology = (index) => {
    const newTechnologies = [...newProject.technologies];
    newTechnologies.splice(index, 1);
    setNewProject({ ...newProject, technologies: newTechnologies });
  };

  const handleDeleteEditTechnology = (index) => {
    const newTechnologies = [...editProject.technologies];
    newTechnologies.splice(index, 1);
    setEditProject({ ...editProject, technologies: newTechnologies });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = projects.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearch = (event) => {
    setSearchKeyword(event.target.value);
  };

  const refreshProjects = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'projects'));
      const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectsList);
      toast.success('Projects refreshed successfully');
    } catch (error) {
      toast.error('Failed to refresh projects');
      console.error('Error refreshing projects:', error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setNewDocument({ ...newDocument, file: e.target.files[0] });
    }
  };

  const handleDocumentTypeChange = (e) => {
    const isUrl = e.target.value === 'url';
    
    if (editDocumentMode && currentEditDocument) {
      setCurrentEditDocument({
        ...currentEditDocument,
        isUrl: isUrl,
        type: isUrl ? 'url' : 'file',
        file: null,
        // Only clear link if switching to file upload
        link: isUrl ? currentEditDocument.link : '',
      });
    } else {
      setNewDocument({
        ...newDocument,
        isUrl: isUrl,
        file: null,
        link: '',
      });
    }
  };

  const handleEditDocument = (document) => {
    setCurrentEditDocument({
      ...document,
      isUrl: document.type === 'url',
      issueDate: document.issueDate ? new Date(document.issueDate) : null,
      endDate: document.endDate ? new Date(document.endDate) : null,
      file: null
    });
    setEditDocumentMode(true);
  };

  const cancelEditDocument = () => {
    setCurrentEditDocument(null);
    setEditDocumentMode(false);
    setUploadProgress(0);
  };

  const uploadFileToStorage = async (file, projectId, docId) => {
    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `${projectId}_${docId}.${fileExtension}`;
      
      // Use the same storage path structure as in ManageClients.jsx
      const storageRef = ref(storage, `srs_documents/${fileName}`);
      
      // Upload the file as a Blob to ensure proper content type handling
      const fileBlob = new Blob([file], { type: file.type });
      
      // Create upload task
      const uploadTask = uploadBytesResumable(storageRef, fileBlob);
      
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            console.log(`Upload is ${progress}% complete`);
          },
          (error) => {
            console.error('Upload error:', error);
            reject(error);
          },
          async () => {
            try {
              // Get the download URL after successful upload
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              console.log('File uploaded successfully. Download URL:', downloadURL);
              resolve(downloadURL);
            } catch (error) {
              console.error('Error getting download URL:', error);
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.error('Error in uploadFileToStorage:', error);
      throw error;
    }
  };

  const deleteFileFromStorage = async (fileUrl) => {
    try {
      // Extract the file path from the URL
      const fileRef = ref(storage, fileUrl);
      await deleteObject(fileRef);
      console.log('File deleted successfully from storage');
      return true;
    } catch (error) {
      console.error('Error deleting file from storage:', error);
      // If file not found, consider it deleted
      if (error.code === 'storage/object-not-found') {
        console.log('File not found in storage, proceeding with document deletion');
        return true;
      }
      return false;
    }
  };

  const handleAddDocument = async () => {
    if (!currentProjectForDocs) return;
    
    try {
      const docId = uuidv4();
      let documentData = {
        id: docId,
        title: newDocument.title,
        issueDate: newDocument.issueDate ? newDocument.issueDate.toISOString() : null,
        endDate: newDocument.endDate ? newDocument.endDate.toISOString() : null,
        uploadDate: new Date().toISOString(),
        uid: uuidv4() // Generate a unique ID for the document
      };

      // Handle URL or file upload
      if (newDocument.isUrl) {
        if (!newDocument.link) {
          toast.error('Please enter a valid URL');
          return;
        }
        documentData.link = newDocument.link;
        documentData.type = 'url';
      } else if (newDocument.file) {
        try {
          // Upload file to Firebase Storage using the updated method
          const downloadURL = await uploadFileToStorage(newDocument.file, currentProjectForDocs.id, docId);
          documentData.link = downloadURL;
          documentData.type = 'file';
          documentData.fileName = newDocument.file.name;
          documentData.fileSize = newDocument.file.size;
          documentData.fileType = newDocument.file.type;
        } catch (error) {
          console.error('File upload error:', error);
          toast.error(`File upload failed: ${error.message}`);
          return;
        }
      } else {
        toast.error('Please select a file or enter a URL');
        return;
      }

      // Update the project document with the new document data
      const projectRef = doc(firestore, 'projects', currentProjectForDocs.id);
      
      // If this is the first document, initialize the documents array
      if (!currentProjectForDocs.documents) {
        await updateDoc(projectRef, { documents: [documentData] });
      } else {
        await updateDoc(projectRef, { 
          documents: [...currentProjectForDocs.documents, documentData] 
        });
      }

      // Update local state
      const updatedProject = {
        ...currentProjectForDocs,
        documents: [...(currentProjectForDocs.documents || []), documentData]
      };
      
      setProjects(projects.map(p => 
        p.id === currentProjectForDocs.id ? updatedProject : p
      ));
      
      // Reset form
      setNewDocument({
        title: '',
        link: '',
        issueDate: null,
        endDate: null,
        isUrl: false,
        file: null,
      });
      setUploadProgress(0);
      
      toast.success('Document added successfully');
      
      // Close dialog after successful upload
      setDocumentDialogOpen(false);
      
    } catch (error) {
      toast.error(`Error adding document: ${error.message}`);
      console.error('Error adding document:', error);
    }
  };

  const handleUpdateDocument = async () => {
    if (!currentProjectForDocs || !currentEditDocument) return;
    
    try {
      const project = projects.find(p => p.id === currentProjectForDocs.id);
      if (!project || !project.documents) return;
      
      let updatedDocumentData = {
        ...currentEditDocument,
        title: currentEditDocument.title,
        issueDate: currentEditDocument.issueDate ? currentEditDocument.issueDate.toISOString() : null,
        endDate: currentEditDocument.endDate ? currentEditDocument.endDate.toISOString() : null,
        lastUpdated: new Date().toISOString()
      };
      
      // Handle type change or file update
      if (currentEditDocument.isUrl) {
        if (!currentEditDocument.link) {
          toast.error('Please enter a valid URL');
          return;
        }
        
        // If changing from file to URL, delete the old file from storage if needed
        if (currentEditDocument.type === 'file') {
          try {
            await deleteFileFromStorage(currentEditDocument.link);
          } catch (error) {
            console.error('Error deleting old file:', error);
            // Continue with update even if delete fails
          }
        }
        
        updatedDocumentData.type = 'url';
        
        // Remove file-related properties if switching from file to URL
        delete updatedDocumentData.fileName;
        delete updatedDocumentData.fileSize;
        delete updatedDocumentData.fileType;
        
      } else if (currentEditDocument.file) {
        // New file was selected, upload it
        try {
          // Delete old file if it was a file type
          if (currentEditDocument.type === 'file' && currentEditDocument.link) {
            try {
              await deleteFileFromStorage(currentEditDocument.link);
            } catch (error) {
              console.error('Error deleting old file:', error);
              // Continue with update even if delete fails
            }
          }
          
          // Upload new file
          const downloadURL = await uploadFileToStorage(
            currentEditDocument.file, 
            currentProjectForDocs.id, 
            currentEditDocument.id
          );
          
          updatedDocumentData.link = downloadURL;
          updatedDocumentData.type = 'file';
          updatedDocumentData.fileName = currentEditDocument.file.name;
          updatedDocumentData.fileSize = currentEditDocument.file.size;
          updatedDocumentData.fileType = currentEditDocument.file.type;
          
        } catch (error) {
          console.error('File upload error:', error);
          toast.error(`File upload failed: ${error.message}`);
          return;
        }
      } else if (currentEditDocument.type === 'file' && !currentEditDocument.isUrl) {
        // Keep existing file, no change
        // Nothing to do here, keep existing file properties
      } else {
        toast.error('Please select a file or enter a URL');
        return;
      }

      // Update the documents array in the project
      const updatedDocuments = project.documents.map(doc => 
        doc.id === currentEditDocument.id ? updatedDocumentData : doc
      );
      
      // Update in Firestore
      const projectRef = doc(firestore, 'projects', currentProjectForDocs.id);
      await updateDoc(projectRef, { documents: updatedDocuments });
      
      // Update local state
      const updatedProject = { 
        ...project, 
        documents: updatedDocuments 
      };
      
      setProjects(projects.map(p => 
        p.id === currentProjectForDocs.id ? updatedProject : p
      ));
      
      // Reset edit state
      cancelEditDocument();
      setUploadProgress(0);
      
      toast.success('Document updated successfully');
      
    } catch (error) {
      toast.error(`Error updating document: ${error.message}`);
      console.error('Error updating document:', error);
    }
  };

  const handleDeleteDocument = async (projectId, docId) => {
    try {
      const project = projects.find(p => p.id === projectId);
      if (!project || !project.documents) return;
      
      // Find the document to delete
      const docToDelete = project.documents.find(doc => doc.id === docId);
      
      // If document is a file type, delete from storage first
      if (docToDelete && docToDelete.type === 'file' && docToDelete.link) {
        try {
          await deleteFileFromStorage(docToDelete.link);
        } catch (error) {
          console.error('Error deleting file from storage:', error);
          // Continue with document deletion even if file deletion fails
        }
      }

      const updatedDocuments = project.documents.filter(doc => doc.id !== docId);
      
      // Update in Firestore
      const projectRef = doc(firestore, 'projects', projectId);
      await updateDoc(projectRef, { documents: updatedDocuments });
      
      // Update local state
      const updatedProject = { ...project, documents: updatedDocuments };
      setProjects(projects.map(p => p.id === projectId ? updatedProject : p));
      
      toast.success('Document deleted successfully');
      
      // If in edit mode and deleting the current document, exit edit mode
      if (currentEditDocument && currentEditDocument.id === docId) {
        cancelEditDocument();
      }
    } catch (error) {
      toast.error(`Error deleting document: ${error.message}`);
      console.error('Error deleting document:', error);
    }
  };

  const openDocumentDialog = (project) => {
    setCurrentProjectForDocs(project);
    setDocumentDialogOpen(true);
    // Reset edit mode when opening dialog
    cancelEditDocument();
  };

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredProjects.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...filteredProjects]
        .sort((a, b) => (order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy]) ? 1 : -1)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, filteredProjects],
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const [editChargeModalOpen, setEditChargeModalOpen] = useState(false);
  const [currentChargeIndex, setCurrentChargeIndex] = useState(null);

  const openEditChargeModal = (index, isEditMode = false) => {
    const chargesArray = isEditMode ? editCharges : charges;
    if (chargesArray[index]) {
      setCurrentChargeIndex(index);
      setEditChargeModalOpen(true);
    } else {
      console.error("Invalid charge index:", index);
    }
  };

  const closeEditChargeModal = () => {
    setCurrentChargeIndex(null);
    setEditChargeModalOpen(false);
  };

  const handleEditProject = (project) => {
    setEditProject(project);
    // Ensure all charges have IDs before editing
    const chargesWithIds = (project.charges || []).map(charge => {
      if (!charge.id) {
        return { ...charge, id: uuidv4() };
      }
      return charge;
    });
    setEditCharges(chargesWithIds);
    
    // Calculate totals when loading project for editing
    calculateEditTotals(chargesWithIds);
    
    // Set the amount paid from project if it exists, otherwise default to 0
    setEditAmountPaid(project.amountPaid || 0);
  };

  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const openItemModal = (item = null) => {
    if (item) {
      // If item is provided, it's edit mode
      const itemWithParsedDates = { ...item };
      
      // Convert date strings back to Date objects for editing
      if (itemWithParsedDates.purchasedDate && typeof itemWithParsedDates.purchasedDate === 'string') {
        try {
          itemWithParsedDates.purchasedDate = new Date(itemWithParsedDates.purchasedDate);
        } catch (error) {
          console.error("Error parsing purchasedDate", error);
          itemWithParsedDates.purchasedDate = null;
        }
      }
      
      if (itemWithParsedDates.renewedDate && typeof itemWithParsedDates.renewedDate === 'string') {
        try {
          itemWithParsedDates.renewedDate = new Date(itemWithParsedDates.renewedDate);
        } catch (error) {
          console.error("Error parsing renewedDate", error);
          itemWithParsedDates.renewedDate = null;
        }
      }
      
      if (itemWithParsedDates.nextDueDate && typeof itemWithParsedDates.nextDueDate === 'string') {
        try {
          itemWithParsedDates.nextDueDate = new Date(itemWithParsedDates.nextDueDate);
        } catch (error) {
          console.error("Error parsing nextDueDate", error);
          itemWithParsedDates.nextDueDate = null;
        }
      }
      
      setCurrentItem({ 
        ...itemWithParsedDates, 
        original: item // Store the original item for reference during update
      });
      setIsEditMode(true);
    } else {
      // Otherwise it's add mode - generate a unique ID for the new item
      setCurrentItem({ 
        id: uuidv4(), // Add a unique ID for each new item
        item: '', 
        description: '', 
        quantity: 1, 
        unitPrice: 0, 
        discount: { type: 'percentage', value: 0 }, 
        taxes: [], 
        subtotal: 0, 
        purchasedDate: null, 
        renewedDate: null, 
        nextDueDate: null 
      });
      setIsEditMode(false);
    }
    setItemModalOpen(true);
  };

  const closeItemModal = () => {
    setCurrentItem(null);
    setIsEditMode(false);
    setItemModalOpen(false);
  };

  const handleSaveItem = async () => {
    if (currentItem) {
      try {
        // Function to sanitize data by removing undefined values
        const sanitizeData = (obj) => {
          if (!obj) return null;
          if (Array.isArray(obj)) {
            return obj.map(item => sanitizeData(item)).filter(item => item !== null);
          }
          if (typeof obj === 'object' && !(obj instanceof Date)) {
            const result = {};
            for (const key in obj) {
              // Skip 'original' field and any undefined values
              if (key === 'original') continue;
              if (obj[key] !== undefined) {
                result[key] = sanitizeData(obj[key]);
              }
            }
            return result;
          }
          return obj;
        };
        
        // Create a deep copy of current item without 'original' property
        const sanitizedItem = {...currentItem};
        delete sanitizedItem.original;
        
        // Ensure discount and taxes objects exist
        if (!sanitizedItem.discount) {
          sanitizedItem.discount = { type: 'percentage', value: 0 };
        }
        if (!sanitizedItem.taxes) {
          sanitizedItem.taxes = [];
        }

        // Convert dates to ISO strings for Firebase storage
        if (sanitizedItem.purchasedDate instanceof Date) {
          sanitizedItem.purchasedDate = sanitizedItem.purchasedDate.toISOString();
        }
        if (sanitizedItem.renewedDate instanceof Date) {
          sanitizedItem.renewedDate = sanitizedItem.renewedDate.toISOString();
        }
        if (sanitizedItem.nextDueDate instanceof Date) {
          sanitizedItem.nextDueDate = sanitizedItem.nextDueDate.toISOString();
        }
        
        // Calculate subtotal for the item
        const quantity = parseFloat(sanitizedItem.quantity) || 0;
        const unitPrice = parseFloat(sanitizedItem.unitPrice) || 0;
        const discount = sanitizedItem.discount?.type === 'percentage'
          ? (unitPrice * quantity * (parseFloat(sanitizedItem.discount?.value) || 0)) / 100
          : parseFloat(sanitizedItem.discount?.value) || 0;

        const taxAmount = (sanitizedItem.taxes || []).reduce((total, tax) => {
          return total + (tax?.type === 'percentage'
            ? (unitPrice * quantity - discount) * (parseFloat(tax?.value) || 0) / 100
            : parseFloat(tax?.value) || 0);
        }, 0);
        
        sanitizedItem.subtotal = unitPrice * quantity - discount + taxAmount;
        
        let updatedCharges;

        // If we're editing an existing project, we need to update the editCharges array
        if (editProject) {
          if (isEditMode) {
            // Update the existing item in the editCharges array
            updatedCharges = editCharges.map(charge => 
              charge.id === sanitizedItem.id ? sanitizedItem : charge
            );
          } else {
            // Add new item to editCharges
            updatedCharges = [...editCharges, sanitizedItem];
          }
          
          // Update editCharges state and calculate totals
          setEditCharges(updatedCharges);
          calculateEditTotals(updatedCharges);
          
          // Update the project in Firebase
          if (editProject.alias) {
            const updatedProject = { ...editProject, charges: updatedCharges };
            await setDoc(doc(firestore, 'projects', editProject.alias), updatedProject);
            setEditProject(updatedProject);
            toast.success(isEditMode ? 'Item updated successfully' : 'Item added successfully');
          } else {
            toast.error('Project alias is missing, cannot update the project');
          }
        } else {
          // We're in the new project flow, update the charges array
          if (isEditMode) {
            // Update existing item
            updatedCharges = charges.map(charge => 
              charge.id === sanitizedItem.id ? sanitizedItem : charge
            );
          } else {
            // Add new item
            updatedCharges = [...charges, sanitizedItem];
          }
          
          // Update charges state and calculate totals
          setCharges(updatedCharges);
          calculateTotals(updatedCharges);
        }

        closeItemModal();
      } catch (error) {
        console.error('Error saving item:', error);
        toast.error(`Failed to save item: ${error.message}`);
      }
    } else {
      toast.error('No item to save');
    }
  };

  const handleDeleteItem = async () => {
    if (isEditMode && currentItem && currentItem.id) {
      try {
        // Find which array contains the item we want to delete
        let updatedCharges;
        
        // Check if the item is in the main charges array
        const mainItemIndex = charges.findIndex(charge => charge.id === currentItem.id);
        if (mainItemIndex !== -1) {
          // Remove from main charges array
          updatedCharges = charges.filter(charge => charge.id !== currentItem.id);
          setCharges(updatedCharges);
          calculateTotals(updatedCharges);
        } 
        // Check if the item is in edit charges array
        else {
          const editItemIndex = editCharges.findIndex(charge => charge.id === currentItem.id);
          if (editItemIndex !== -1) {
            // Remove from edit charges array
            updatedCharges = editCharges.filter(charge => charge.id !== currentItem.id);
            setEditCharges(updatedCharges);
            calculateEditTotals(updatedCharges);
          }
        }

        // Update the project in Firebase if we're editing an existing project
        if (editProject && editProject.alias) {
          // Check which array was updated and use that
          const projectCharges = mainItemIndex !== -1 ? updatedCharges : editCharges;
          const updatedProject = { ...editProject, charges: projectCharges };
          
          await setDoc(doc(firestore, 'projects', editProject.alias), updatedProject);
          // Update the local edit project state
          setEditProject(updatedProject);
          toast.success('Item deleted successfully');
        }

        closeItemModal();
      } catch (error) {
        console.error('Error deleting item:', error);
        toast.error(`Failed to delete item: ${error.message}`);
      }
    } else {
      toast.error('No item selected for deletion or item has no ID');
    }
  };

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4">Manage Projects</h1>
      <div className="flex w-full justify-between gap-2 mb-2">
        <Button variant="contained" color="primary" onClick={() => setOpen(true)} startIcon={<AddIcon />}>
          Add New Project
        </Button>
        <Button variant="outlined" color="primary" onClick={refreshProjects} startIcon={<RefreshIcon />}>
          Refresh
        </Button>
      </div>
     <div className='bg-white rounded-sm mt-2 px-2 mb-2'>
      <TextField
        label="Search by Title"
        value={searchKeyword}
        onChange={handleSearch}
        fullWidth
        margin="normal"
      />
     </div>
      <Dialog 
        open={open} 
        onClose={() => setOpen(false)} 
        fullScreen={isFullScreen} 
        maxWidth="md" 
        fullWidth={!isFullScreen}
      >
        <DialogTitle>
          Add New Project
          <IconButton 
            onClick={toggleFullScreen} 
            sx={{ position: 'absolute', right: 16, top: 16 }}
          >
            {isFullScreen ? <FilterListIcon /> : <AddIcon />}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Project ID"
            value={newProject.alias}
            onChange={(e) => setNewProject({ ...newProject, alias: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Project Title"
            value={newProject.title}
            onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newProject.description}
            onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Estimated Cost"
            value={newProject.estimatedCost}
            onChange={(e) => setNewProject({ ...newProject, estimatedCost: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={newProject.currency}
              onChange={(e) => setNewProject({ ...newProject, currency: e.target.value })}
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="GBP">GBP</MenuItem>
              <MenuItem value="LKR">LKR</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Project Status</InputLabel>
            <Select
              value={newProject.status}
              onChange={(e) => setNewProject({ ...newProject, status: e.target.value })}
            >
              {projectStatusOptions.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={newProject.category}
              onChange={(e) => setNewProject({ ...newProject, category: e.target.value })}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={newProject.country}
              onChange={(e) => setNewProject({ ...newProject, country: e.target.value })}
            >
              {countryList.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Year"
            value={newProject.year}
            onChange={(e) => setNewProject({ ...newProject, year: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          {newProject.technologies.map((tech, index) => (
            <Box key={index} display="flex" alignItems="center">
              <TextField
                label={`Technology ${index + 1}`}
                value={tech}
                onChange={(e) => {
                  const newTechnologies = [...newProject.technologies];
                  newTechnologies[index] = e.target.value;
                  setNewProject({ ...newProject, technologies: newTechnologies });
                }}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={() => handleDeleteTechnology(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddTechnology}>Add Technology</Button>
          <TextField
            label="Project Link"
            value={newProject.link}
            onChange={(e) => setNewProject({ ...newProject, link: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Image Link"
            value={newProject.image}
            onChange={(e) => setNewProject({ ...newProject, image: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newProject.isActive}
                onChange={(e) => setNewProject({ ...newProject, isActive: e.target.checked })}
                color="primary"
              />
            }
            label="Active Project"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newProject.isPublic}
                onChange={(e) => setNewProject({ ...newProject, isPublic: e.target.checked })}
                color="primary"
              />
            }
            label="Public Visibility"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newProject.isPublicPaid}
                onChange={(e) => setNewProject({ ...newProject, isPublicPaid: e.target.checked })}
                color="primary"
              />
            }
            label="Paid Public Visibility"
          />
          <Typography variant="h6" sx={{ mt: 4 }}>Itemized List of Charges</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
            {charges.map((charge, index) => (
              <Paper key={index} elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 300 }}>
                <Typography variant="subtitle1" fontWeight="bold">{charge.item || 'Unnamed Item'}</Typography>
                <Typography variant="body2">{charge.description || 'No description provided.'}</Typography>
                <Typography variant="body2">Quantity: {charge.quantity}</Typography>
                <Typography variant="body2">Unit Price: LKR {charge.unitPrice}</Typography>
                <Typography variant="body2">Discount: {charge.discount.value} {charge.discount.type === 'percentage' ? '%' : 'LKR'}</Typography>
                <Typography variant="body2">Subtotal: LKR {(charge.subtotal !== undefined && charge.subtotal !== null) ? 
                  charge.subtotal.toFixed(2) : '0.00'}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button size="small" variant="outlined" onClick={() => openItemModal(charge)}>Edit</Button>
                  <Button size="small" variant="outlined" color="error" onClick={() => handleDeleteCharge(index)}>Delete</Button>
                </Box>
              </Paper>
            ))}
          </Box>
          <Button onClick={handleAddCharge} sx={{ mt: 2 }}>Add Item</Button>
          
          {/* Add summary section for totals */}
          <Typography variant="h6" sx={{ mt: 4 }}>Summary</Typography>
          <Typography>Total Discount: LKR {totalDiscount.toFixed(2)}</Typography>
          <Typography>Total Taxes: LKR {totalTaxes.toFixed(2)}</Typography>
          <Typography>Total Amount: LKR {totalAmount.toFixed(2)}</Typography>
          <TextField
            label="Amount Paid"
            type="number"
            value={amountPaid}
            onChange={(e) => handleAmountPaidChange(parseFloat(e.target.value) || 0)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Typography>Balance Due: LKR {balanceDue.toFixed(2)}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddProject} color="primary">
            Add Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={!!editProject} 
        onClose={() => setEditProject(null)} 
        fullScreen={isFullScreen} 
        maxWidth="md" 
        fullWidth={!isFullScreen}
      >
        <DialogTitle>
          Edit Project
          <IconButton 
            onClick={toggleFullScreen} 
            sx={{ position: 'absolute', right: 16, top: 16 }}
          >
            {isFullScreen ? <FilterListIcon /> : <AddIcon />}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {editProject && (
            <>
              <TextField
                label="Project ID"
                value={editProject.alias}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Project Title"
                value={editProject.title}
                onChange={(e) => setEditProject({ ...editProject, title: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editProject.description}
                onChange={(e) => setEditProject({ ...editProject, description: e.target.value })}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <TextField
                label="Estimated Cost"
                value={editProject.estimatedCost}
                onChange={(e) => setEditProject({ ...editProject, estimatedCost: e.target.value })}
                fullWidth
                margin="normal"
                type="number"
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  value={editProject.currency}
                  onChange={(e) => setEditProject({ ...editProject, currency: e.target.value })}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="LKR">LKR</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Project Status</InputLabel>
                <Select
                  value={editProject.status || 'Completed'}
                  onChange={(e) => setEditProject({ ...editProject, status: e.target.value })}
                >
                  {projectStatusOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Category</InputLabel>
                <Select
                  value={editProject.category}
                  onChange={(e) => setEditProject({ ...editProject, category: e.target.value })}
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Country</InputLabel>
                <Select
                  value={editProject.country}
                  onChange={(e) => setEditProject({ ...editProject, country: e.target.value })}
                >
                  {countryList.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Year"
                value={editProject.year}
                onChange={(e) => setEditProject({ ...editProject, year: e.target.value })}
                fullWidth
                margin="normal"
                type="number"
              />
              {editProject.technologies.map((tech, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    label={`Technology ${index + 1}`}
                    value={tech}
                    onChange={(e) => handleEditTechnology(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <IconButton onClick={() => handleDeleteEditTechnology(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={() => handleEditTechnology(editProject.technologies.length, '')}>Add Technology</Button>
              <TextField
                label="Project Link"
                value={editProject.link}
                onChange={(e) => setEditProject({ ...editProject, link: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Image Link"
                value={editProject.image}
                onChange={(e) => setEditProject({ ...editProject, image: e.target.value })}
                fullWidth
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editProject.isActive !== undefined ? editProject.isActive : true}
                    onChange={(e) => setEditProject({ ...editProject, isActive: e.target.checked })}
                    color="primary"
                  />
                }
                label="Active Project"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editProject.isPublic !== undefined ? editProject.isPublic : true}
                    onChange={(e) => setEditProject({ ...editProject, isPublic: e.target.checked })}
                    color="primary"
                  />
                }
                label="Public Visibility"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editProject.isPublicPaid !== undefined ? editProject.isPublicPaid : false}
                    onChange={(e) => setEditProject({ ...editProject, isPublicPaid: e.target.checked })}
                    color="primary"
                  />
                }
                label="Paid Public Visibility"
              />
              <Typography variant="h6" sx={{ mt: 4 }}>Itemized List of Charges</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                {editCharges.map((charge, index) => (
                  <Paper key={index} elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 300 }}>
                    <Typography variant="subtitle1" fontWeight="bold">{charge.item || 'Unnamed Item'}</Typography>
                    <Typography variant="body2">{charge.description || 'No description provided.'}</Typography>
                    <Typography variant="body2">Quantity: {charge.quantity}</Typography>
                    <Typography variant="body2">Unit Price: LKR {charge.unitPrice}</Typography>
                    <Typography variant="body2">Discount: {charge.discount.value} {charge.discount.type === 'percentage' ? '%' : 'LKR '}</Typography>
                    <Typography variant="body2">Subtotal: LKR {(charge.subtotal !== undefined && charge.subtotal !== null) ? 
                      charge.subtotal.toFixed(2) : '0.00'}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <Button size="small" variant="outlined" onClick={() => openItemModal(charge)}>Edit</Button>
                      <Button size="small" variant="outlined" color="error" onClick={() => handleDeleteEditCharge(index)}>Delete</Button>
                    </Box>
                  </Paper>
                ))}
              </Box>
              <Button onClick={handleAddEditCharge} sx={{ mt: 2 }}>Add Item</Button>
              <Typography variant="h6" sx={{ mt: 4 }}>Summary</Typography>
              <Typography>Total Discount: LKR {editTotalDiscount.toFixed(2)}</Typography>
              <Typography>Total Taxes: LKR {editTotalTaxes.toFixed(2)}</Typography>
              <Typography>Total Amount: LKR {editTotalAmount.toFixed(2)}</Typography>
              <TextField
                label="Amount Paid"
                type="number"
                value={editAmountPaid}
                onChange={(e) => handleEditAmountPaidChange(parseFloat(e.target.value) || 0)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <Typography>Balance Due: LKR {editBalanceDue.toFixed(2)}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditProject(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateProject} color="primary">
            Update Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDelete.open} onClose={() => setConfirmDelete({ open: false, id: null })}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this project?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete({ open: false, id: null })} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteProject(confirmDelete.id)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={documentDialogOpen} onClose={() => setDocumentDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {currentProjectForDocs ? 
            editDocumentMode ? 
              `Edit Document in ${currentProjectForDocs.title}` : 
              `Add Documents to ${currentProjectForDocs.title}` 
            : 'Manage Documents'
          }
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Document Type</FormLabel>
            <RadioGroup
              row
              name="documentType"
              value={editDocumentMode ? 
                (currentEditDocument?.isUrl ? 'url' : 'file') : 
                (newDocument.isUrl ? 'url' : 'file')
              }
              onChange={handleDocumentTypeChange}
            >
              <FormControlLabel value="file" control={<Radio />} label="Upload File" />
              <FormControlLabel value="url" control={<Radio />} label="External URL" />
            </RadioGroup>
          </FormControl>

          <TextField
            label="Document Title"
            value={editDocumentMode ? currentEditDocument?.title || '' : newDocument.title}
            onChange={(e) => editDocumentMode ? 
              setCurrentEditDocument({ ...currentEditDocument, title: e.target.value }) : 
              setNewDocument({ ...newDocument, title: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />

          {(editDocumentMode ? currentEditDocument?.isUrl : newDocument.isUrl) ? (
            <TextField
              label="Document URL"
              value={editDocumentMode ? currentEditDocument?.link || '' : newDocument.link}
              onChange={(e) => editDocumentMode ? 
                setCurrentEditDocument({ ...currentEditDocument, link: e.target.value }) : 
                setNewDocument({ ...newDocument, link: e.target.value })
              }
              fullWidth
              margin="normal"
              required
              helperText="Enter a shareable link to the document"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Button
                variant="contained"
                component="label"
                startIcon={<AttachFileIcon />}
              >
                Select PDF
                <input
                  type="file"
                  hidden
                  accept="application/pdf"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      if (editDocumentMode) {
                        setCurrentEditDocument({ ...currentEditDocument, file: e.target.files[0] });
                      } else {
                        setNewDocument({ ...newDocument, file: e.target.files[0] });
                      }
                    }
                  }}
                />
              </Button>
              {editDocumentMode ? (
                currentEditDocument?.file ? (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected: {currentEditDocument.file.name}
                  </Typography>
                ) : currentEditDocument?.fileName ? (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Current file: {currentEditDocument.fileName}
                  </Typography>
                ) : null
              ) : (
                newDocument.file && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected: {newDocument.file.name}
                  </Typography>
                )
              )}
              {uploadProgress > 0 && uploadProgress < 100 && (
                <Box sx={{ width: '100%', mt: 2 }}>
                  <LinearProgress variant="determinate" value={uploadProgress} />
                  <Typography variant="body2" color="text.secondary">
                    {`${Math.round(uploadProgress)}%`}
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <DatePicker
                label="Issue Date"
                value={editDocumentMode ? 
                  (currentEditDocument?.issueDate ? dayjs(currentEditDocument.issueDate) : null) : 
                  (newDocument.issueDate ? dayjs(newDocument.issueDate) : null)
                }
                onChange={(date) => {
                  if (editDocumentMode) {
                    setCurrentEditDocument({ 
                      ...currentEditDocument, 
                      issueDate: date ? date.toDate() : null 
                    });
                  } else {
                    setNewDocument({ 
                      ...newDocument, 
                      issueDate: date ? date.toDate() : null 
                    });
                  }
                }}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    borderRadius: '0.5rem',
                    '&:hover': {
                      borderColor: '#1976d2',
                    },
                  },
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined"
                  }
                }}
              />
              <DatePicker
                label="End Date"
                value={editDocumentMode ? 
                  (currentEditDocument?.endDate ? dayjs(currentEditDocument.endDate) : null) : 
                  (newDocument.endDate ? dayjs(newDocument.endDate) : null)
                }
                onChange={(date) => {
                  if (editDocumentMode) {
                    setCurrentEditDocument({ 
                      ...currentEditDocument, 
                      endDate: date ? date.toDate() : null 
                    });
                  } else {
                    setNewDocument({ 
                      ...newDocument, 
                      endDate: date ? date.toDate() : null 
                    });
                  }
                }}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    borderRadius: '0.5rem',
                    '&:hover': {
                      borderColor: '#1976d2',
                    },
                  },
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined"
                  }
                }}
              />
            </Box>
          </LocalizationProvider>

          {currentProjectForDocs && currentProjectForDocs.documents && currentProjectForDocs.documents.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Existing Documents</Typography>
              <List>
                {currentProjectForDocs.documents.map((doc) => (
                  <ListItem
                    key={doc.id}
                    secondaryAction={
                      <Box>
                        <IconButton 
                          edge="end" 
                          aria-label="edit" 
                          onClick={() => handleEditDocument(doc)}
                          disabled={editDocumentMode && currentEditDocument?.id === doc.id}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          edge="end" 
                          aria-label="delete" 
                          onClick={() => handleDeleteDocument(currentProjectForDocs.id, doc.id)}
                          disabled={editDocumentMode && currentEditDocument?.id === doc.id}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                  >
                    <ListItemIcon>
                      {doc.type === 'url' ? <LinkIcon /> : <ArticleIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={doc.title}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            {doc.type === 'url' ? 'External URL' : doc.fileName || 'PDF File'}
                          </Typography>
                          {doc.issueDate && (
                            <Typography component="span" display="block">
                              Issue Date: {new Date(doc.issueDate).toLocaleDateString()}
                            </Typography>
                          )}
                          {doc.endDate && (
                            <Typography component="span" display="block">
                              End Date: {new Date(doc.endDate).toLocaleDateString()}
                            </Typography>
                          )}
                          <Typography component="span" display="block">
                            Added: {new Date(doc.uploadDate).toLocaleString()}
                          </Typography>
                          {doc.lastUpdated && (
                            <Typography component="span" display="block">
                              Updated: {new Date(doc.lastUpdated).toLocaleString()}
                            </Typography>
                          )}
                        </>
                      }
                    />
                    <Button
                      href={doc.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ml: 2 }}
                    >
                      View
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            if (editDocumentMode) {
              cancelEditDocument();
            } else {
              setDocumentDialogOpen(false);
            }
          }} color="secondary">
            {editDocumentMode ? "Cancel Edit" : "Close"}
          </Button>
          {editDocumentMode ? (
            <Button 
              onClick={handleUpdateDocument} 
              color="primary" 
              disabled={
                !currentEditDocument?.title || 
                (currentEditDocument?.isUrl && !currentEditDocument?.link) || 
                (!currentEditDocument?.isUrl && !currentEditDocument?.file && !currentEditDocument?.fileName)
              }
            >
              Update Document
            </Button>
          ) : (
            <Button 
              onClick={handleAddDocument} 
              color="primary" 
              disabled={(!newDocument.isUrl && !newDocument.file) || !newDocument.title}
            >
              Add Document
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Toolbar
            sx={[
              {
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              },
              selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              },
            ]}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Projects
              </Typography>
            )}
            {selected.length > 0 ? (
              <Tooltip title="Delete">
                <IconButton onClick={() => setConfirmDelete({ open: true, id: selected[0] })}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Filter list">
                <IconButton>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
             
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < projects.length}
                      checked={projects.length > 0 && selected.length === projects.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all projects',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    key="title"
                    align="left"
                    padding="normal"
                    sortDirection={orderBy === 'title' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'title')}
                    >
                      Title
                      {orderBy === 'title' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Estimated Cost</TableCell>
                  <TableCell align="left">Year</TableCell>
                  <TableCell align="left">Country</TableCell>
                  <TableCell align="left">Active</TableCell>
                  <TableCell align="left">Public</TableCell>
                  <TableCell align="left">Paid</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((project, index) => {
                  const isItemSelected = selected.includes(project.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const documentCount = project.documents ? project.documents.length : 0;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, project.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={project.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {project.title}
                      </TableCell>
                      <TableCell align="left">{project.category}</TableCell>
                      <TableCell align="left">{project.status || 'Completed'}</TableCell>
                      <TableCell align="left">{project.estimatedCost}</TableCell>
                      <TableCell align="left">{project.year}</TableCell>
                      <TableCell align="left">{project.country}</TableCell>
                      <TableCell align="left">
                        <Switch
                          checked={project.isActive !== undefined ? project.isActive : true}
                          onChange={(e) => {
                            e.stopPropagation();
                            const updatedProject = { ...project, isActive: e.target.checked };
                            setDoc(doc(firestore, 'projects', project.id), updatedProject)
                              .then(() => {
                                // Update the local state
                                setProjects(projects.map(p => 
                                  p.id === project.id ? updatedProject : p
                                ));
                                // Show toast message with correct status
                                const statusText = e.target.checked ? 'inactive' : 'active';
                                toast.success(`Project "${project.title}" is now ${statusText}`);
                              })
                              .catch((error) => {
                                toast.error(`Failed to update status: ${error.message}`);
                                console.error('Error updating project status:', error);
                              });
                          }}
                          onClick={(e) => e.stopPropagation()}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Switch
                          checked={project.isPublic !== undefined ? project.isPublic : true}
                          onChange={(e) => {
                            e.stopPropagation();
                            const updatedProject = { ...project, isPublic: e.target.checked };
                            setDoc(doc(firestore, 'projects', project.id), updatedProject)
                              .then(() => {
                                // Update the local state
                                setProjects(projects.map(p => 
                                  p.id === project.id ? updatedProject : p
                                ));
                                // Show toast message
                                const visibilityText = e.target.checked ? 'private' : 'public';
                                toast.success(`Project "${project.title}" is now ${visibilityText}`);
                              })
                              .catch((error) => {
                                toast.error(`Failed to update visibility: ${error.message}`);
                                console.error('Error updating project visibility:', error);
                              });
                          }}
                          onClick={(e) => e.stopPropagation()}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Switch
                          checked={project.isPublicPaid !== undefined ? project.isPublicPaid : false}
                          onChange={(e) => {
                            e.stopPropagation();
                            const updatedProject = { ...project, isPublicPaid: e.target.checked };
                            setDoc(doc(firestore, 'projects', project.id), updatedProject)
                              .then(() => {
                                // Update the local state
                                setProjects(projects.map(p => 
                                  p.id === project.id ? updatedProject : p
                                ));
                                // Show toast message
                                const paidText = e.target.checked ? 'not paid' : 'paid';
                                toast.success(`Project "${project.title}" now has ${paidText}`);
                              })
                              .catch((error) => {
                                toast.error(`Failed to update paid status: ${error.message}`);
                                console.error('Error updating project paid status:', error);
                              });
                          }}
                          onClick={(e) => e.stopPropagation()}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <IconButton onClick={(e) => {
                          e.stopPropagation();
                          handleEditProject(project);
                        }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={(e) => {
                          e.stopPropagation();
                          setConfirmDelete({ open: true, id: project.id });
                        }}>
                          <DeleteIcon />
                        </IconButton>
                        <Tooltip title="Manage Documents">
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            openDocumentDialog(project);
                          }}>
                            <ArticleIcon color={documentCount > 0 ? "primary" : "action"} />
                            {documentCount > 0 && (
                              <Typography variant="caption" sx={{ position: 'absolute', top: 0, right: 0, bgcolor: 'primary.main', color: 'white', borderRadius: '50%', width: '16px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {documentCount}
                              </Typography>
                            )}
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
      <Dialog open={editChargeModalOpen} onClose={closeEditChargeModal} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Charge</DialogTitle>
        <DialogContent>
          {currentChargeIndex !== null && charges[currentChargeIndex] && (
            <>
              <TextField
                label="Item"
                value={charges[currentChargeIndex]?.item || ''}
                onChange={(e) => handleUpdateCharge(currentChargeIndex, 'item', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={charges[currentChargeIndex]?.description || ''}
                onChange={(e) => handleUpdateCharge(currentChargeIndex, 'description', e.target.value)}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                variant="outlined"
                InputProps={{
                  style: { 
                    whiteSpace: 'pre-wrap',
                    fontFamily: 'inherit'
                  }
                }}
              />
              <TextField
                label="Quantity"
                type="number"
                value={charges[currentChargeIndex]?.quantity || ''}
                onChange={(e) => handleUpdateCharge(currentChargeIndex, 'quantity', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Unit Price"
                type="number"
                value={charges[currentChargeIndex]?.unitPrice || ''}
                onChange={(e) => handleUpdateCharge(currentChargeIndex, 'unitPrice', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Discount"
                type="number"
                value={charges[currentChargeIndex]?.discount?.value || ''}
                onChange={(e) => handleUpdateCharge(currentChargeIndex, 'discount', { ...charges[currentChargeIndex]?.discount, value: e.target.value })}
                fullWidth
                margin="normal"
              />
              <Select
                value={charges[currentChargeIndex]?.discount?.type || 'percentage'}
                onChange={(e) => handleUpdateCharge(currentChargeIndex, 'discount', { ...charges[currentChargeIndex]?.discount, type: e.target.value })}
                fullWidth
                sx={{ mt: 2 }}
              >
                <MenuItem value="percentage">%</MenuItem>
                <MenuItem value="amount">LKR </MenuItem>
              </Select>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Purchased Date"
                  value={charges[currentChargeIndex]?.purchasedDate ? dayjs(charges[currentChargeIndex]?.purchasedDate) : null}
                  onChange={(date) => handleUpdateCharge(currentChargeIndex, 'purchasedDate', date ? date.toDate() : null)}
                  sx={{ width: '100%', mt: 2 }}
                />
                <DatePicker
                  label="Renewed Date"
                  value={charges[currentChargeIndex]?.renewedDate ? dayjs(charges[currentChargeIndex]?.renewedDate) : null}
                  onChange={(date) => handleUpdateCharge(currentChargeIndex, 'renewedDate', date ? date.toDate() : null)}
                  sx={{ width: '100%', mt: 2 }}
                />
                <DatePicker
                  label="Next Due Date"
                  value={charges[currentChargeIndex]?.nextDueDate ? dayjs(charges[currentChargeIndex]?.nextDueDate) : null}
                  onChange={(date) => handleUpdateCharge(currentChargeIndex, 'nextDueDate', date ? date.toDate() : null)}
                  sx={{ width: '100%', mt: 2 }}
                />
              </LocalizationProvider>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditChargeModal} color="secondary">Cancel</Button>
          <Button onClick={closeEditChargeModal} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={itemModalOpen} onClose={closeItemModal} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? 'Edit Item' : 'Add New Item'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Item"
            value={currentItem?.item || ''}
            onChange={(e) => setCurrentItem({ ...currentItem, item: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={currentItem?.description || ''}
            onChange={(e) => setCurrentItem({ ...currentItem, description: e.target.value })}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Quantity"
            type="number"
            value={currentItem?.quantity || ''}
            onChange={(e) => setCurrentItem({ ...currentItem, quantity: parseFloat(e.target.value) || 0 })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Unit Price"
            type="number"
            value={currentItem?.unitPrice || ''}
            onChange={(e) => setCurrentItem({ ...currentItem, unitPrice: parseFloat(e.target.value) || 0 })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Discount"
            type="number"
            value={currentItem?.discount?.value || ''}
            onChange={(e) => setCurrentItem({ 
              ...currentItem, 
              discount: { ...currentItem.discount, value: parseFloat(e.target.value) || 0 } 
            })}
            fullWidth
            margin="normal"
          />
          <Select
            value={currentItem?.discount?.type || 'percentage'}
            onChange={(e) => setCurrentItem({ 
              ...currentItem, 
              discount: { ...currentItem.discount, type: e.target.value } 
            })}
            fullWidth
            sx={{ mt: 2 }}
          >
            <MenuItem value="percentage">%</MenuItem>
            <MenuItem value="amount">LKR </MenuItem>
          </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Purchased Date"
              value={currentItem?.purchasedDate ? dayjs(currentItem.purchasedDate) : null}
              onChange={(date) => setCurrentItem({ ...currentItem, purchasedDate: date ? date.toDate() : null })}
              sx={{ width: '100%', mt: 2 }}
            />
            <DatePicker
              label="Renewed Date"
              value={currentItem?.renewedDate ? dayjs(currentItem.renewedDate) : null}
              onChange={(date) => setCurrentItem({ ...currentItem, renewedDate: date ? date.toDate() : null })}
              sx={{ width: '100%', mt: 2 }}
            />
            <DatePicker
              label="Next Due Date"
              value={currentItem?.nextDueDate ? dayjs(currentItem.nextDueDate) : null}
              onChange={(date) => setCurrentItem({ ...currentItem, nextDueDate: date ? date.toDate() : null })}
              sx={{ width: '100%', mt: 2 }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          {isEditMode && (
            <Button onClick={handleDeleteItem} color="error">
              Delete
            </Button>
          )}
          <Button onClick={closeItemModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveItem} color="primary">
            {isEditMode ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageProjects;
