import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  onAuthStateChanged, 
  signOut, 
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, googleProvider, microsoftProvider, firestore } from '../firebaseConfig';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Helper function to create/check user in Firestore
  async function ensureUserInFirestore(user) {
    if (!user) return;
    
    try {
      // Check if user already exists in firestore
      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      
      if (!userSnap.exists()) {
        // Generate a timestamp-based username with random string
        const timestamp = new Date().toISOString().replace(/[-:T.Z]/g, "").substring(0, 14);
        const randomString = Math.random().toString(36).substring(2, 7);
        const defaultUsername = `${timestamp}${randomString}`;
        
        // Create new user document
        const userData = {
          uid: user.uid,
          email: user.email,
          name: user.displayName || user.email.split('@')[0],
          phone: user.phoneNumber || "",
          profilePicture: user.photoURL || "",
          role: ["student"],
          is_active: true,
          show_public: true,
          username: defaultUsername
        };
        
        await setDoc(userRef, userData);
        return userData;
      }
      
      return userSnap.data();
    } catch (error) {
      console.error("Error ensuring user in Firestore:", error);
      throw error;
    }
  }

  async function login(email, password) {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    await ensureUserInFirestore(userCredential.user);
    return userCredential;
  }

  async function signup(email, password) {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await ensureUserInFirestore(userCredential.user);
    return userCredential;
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  async function googleLogin() {
    const userCredential = await signInWithPopup(auth, googleProvider);
    await ensureUserInFirestore(userCredential.user);
    return userCredential;
  }

  async function microsoftLogin() {
    const userCredential = await signInWithPopup(auth, microsoftProvider);
    await ensureUserInFirestore(userCredential.user);
    return userCredential;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await ensureUserInFirestore(user);
        user.firestoreData = userData; // Attach Firestore data to the user object
      }
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    googleLogin,
    microsoftLogin
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
