import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import './App.css';

import Navbar from './components/navbar';
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Footer from './components/footer';
import Refund from './pages/tc/Refund';
import Privacy from './pages/tc/Privacy';
import Terms from './pages/tc/Terms';
import All from './pages/courses/All';
import CourseSearch from './pages/courses/CourseSearch';
import Login from './pages/Login';
import Register from './pages/Register';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import RedirectAuthenticatedUser from './components/RedirectAuthenticatedUser';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import Dashboard from './pages/dashboard/Dashboard';
import Projects from './pages/projects/Projects';
import Services from './pages/services/Services';
import PayTrack from './pages/payments/PayTrack';
import MyAccountPage from './pages/myaccount/MyAccountPage';
import Users from './pages/user/User';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

const getDefaultMetaImage = () => 'https://officialkasun.com/logo.png';
const getDefaultMetaDescription = () => 'KASUN MADHUSANKA JAYASINGHE | Delivering top-notch solutions for web development, software development, and Android app development. As a professional freelance developer, I am committed to turning your ideas into reality with precision and excellence.';
const getDefaultMetaTitle = () => 'KASUN MADHUSANKA JAYASINGHE | Professional Freelance Developer';

// Enhanced loader component for auth loading state
function AuthLoader() {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      zIndex: 9999
    }}>
      <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <img 
          src="/logo.png" 
          alt="Kasun Madhusanka" 
          style={{ 
            width: '100px', 
            height: 'auto',
            marginBottom: '20px',
            animation: 'pulse 1.5s infinite ease-in-out'
          }} 
        />
        <CircularProgress 
          size={60} 
          thickness={4} 
          sx={{ color: '#1976d2' }}
        />
        <Typography 
          variant="h6" 
          sx={{ 
            mt: 2, 
            fontWeight: 500, 
            color: '#333',
            animation: 'fadeInOut 1.5s infinite ease-in-out'
          }}
        >
          Loading your profile...
        </Typography>
      </Box>
      <style jsx="true">{`
        @keyframes pulse {
          0% { transform: scale(0.95); opacity: 0.8; }
          50% { transform: scale(1.05); opacity: 1; }
          100% { transform: scale(0.95); opacity: 0.8; }
        }
        @keyframes fadeInOut {
          0% { opacity: 0.7; }
          50% { opacity: 1; }
          100% { opacity: 0.7; }
        }
      `}</style>
    </div>
  );
}

// ScrollTop component handles showing/hiding the button and scroll action
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Fade  in={trigger}>
      <Box 
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

// BackToTop component wraps the main content and includes the ScrollTop button
export default function BackToTop(props) {
    const [theme, setTheme] = useState(
      localStorage.getItem("theme") || "system"
    );
    const [isAuthLoading, setIsAuthLoading] = useState(false);
    const [hasUsernameCookie, setHasUsernameCookie] = useState(false);
    const [userAuthChecked, setUserAuthChecked] = useState(false);

    // Check for username cookie on initial load
    useEffect(() => {
      const cookies = document.cookie.split(';').map(cookie => cookie.trim());
      const usernameCookie = cookies.find(cookie => cookie.startsWith('username='));
      
      if (usernameCookie) {
        setHasUsernameCookie(true);
        setIsAuthLoading(true);
      }
      
      // Set up Firebase auth state listener
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (hasUsernameCookie) {
          // Short delay to ensure smooth transition
          setTimeout(() => {
            setIsAuthLoading(false);
            setUserAuthChecked(true);
          }, 500); // Small delay for better UX
        } else {
          setUserAuthChecked(true);
        }
      });
      
      return () => unsubscribe(); // Clean up listener on unmount
      
    }, [hasUsernameCookie]);

    useEffect(() => {
      localStorage.getItem("theme") || "system"
    }, [theme]);

  // Only render the main app when Firebase auth has been checked
  // or when no username cookie exists (no need to wait for auth)
  if (!userAuthChecked && hasUsernameCookie) {
    return <AuthLoader />;
  }

  return (
    <HelmetProvider>
      <AuthProvider onAuthStateResolved={() => setIsAuthLoading(false)}>
        <Router>
          <ScrollToTop />
          <React.Fragment>
            <CssBaseline />
            
            {/* Show loader if still loading auth data */}
            {isAuthLoading && <AuthLoader />}
            
            {/* Default Helmet that will be overridden by page-specific Helmets */}
            <Helmet defaultTitle={getDefaultMetaTitle()} titleTemplate="%s | KASUN MADHUSANKA">
              <title>{getDefaultMetaTitle()}</title>
              <meta name="description" content={getDefaultMetaDescription()} />
              <meta property="og:title" content={getDefaultMetaTitle()} />
              <meta property="og:description" content={getDefaultMetaDescription()} />
              <meta property="og:image" content={getDefaultMetaImage()} />
              <meta property="og:url" content="https://officialkasun.com" />
              <meta property="og:type" content="website" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={getDefaultMetaTitle()} />
              <meta name="twitter:description" content={getDefaultMetaDescription()} />
              <meta name="twitter:image" content={getDefaultMetaImage()} />
            </Helmet>
            
            {/* AppBar with Navbar */}
            <AppBar className='select-none'>
              <Navbar />
              <ToastContainer 
                theme={theme} 
                position="top-right"
                autoClose={5000}
                closeOnClick
                draggable
                pauseOnHover
              />
            </AppBar>

            <Navbar /> {/* Navbar remains fixed */}
            
            <div className='select-none' style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
              <div className='mb-20' style={{ flex: 1, overflowY: 'auto' }}>
                <Toolbar id="back-to-top-anchor" />
               
                <Container>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route 
                      path="/login" 
                      element={
                        <RedirectAuthenticatedUser>
                          <Login />
                        </RedirectAuthenticatedUser>
                      } 
                    />
                    <Route 
                      path="/register" 
                      element={
                        <RedirectAuthenticatedUser>
                          <Register />
                        </RedirectAuthenticatedUser>
                      } 
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/courses" element={<All />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/courses/:course" element={<CourseSearch />} />
                    <Route path="/tc/refund" element={<Refund />} />
                    <Route path="/tc/privacy" element={<Privacy />} />
                    <Route path="/tc/terms" element={<Terms />} />
                    <Route path="/user/:username" element={<Users />} />
                    <Route path="/u/:username" element={<Users />} />
                    
                    {/* Protected Routes */}
                    <Route 
                      path="/myaccount" 
                      element={
                        <ProtectedRoute>
                          <MyAccountPage />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/dashboard" 
                      element={
                        <ProtectedRoute>
                          <Dashboard />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/payments/tracker" 
                      element={
                        <ProtectedRoute>
                          <PayTrack />
                        </ProtectedRoute>
                      } 
                    />
                    
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Container>
              </div>

              <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
                <Footer />
              </div>
            </div>
            {/* ScrollTop Button */}
            <ScrollTop {...props} >
              <Fab size="small" aria-label="scroll back to top"  >
                <KeyboardArrowUpIcon  />
              </Fab>
            </ScrollTop>
          </React.Fragment>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}

function ScrollToTop() {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}
