import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebaseConfig';
import { collection, query, getDocs, where, orderBy as fsOrderBy, limit, doc, updateDoc, getDoc } from 'firebase/firestore';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  Paper, TextField, MenuItem, IconButton, Chip, Typography, Tab, Tabs, useTheme, useMediaQuery,
  CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert,
  List, ListItem, ListItemText, Divider, Switch, Badge
} from '@mui/material';
import { Visibility, Refresh, Payments, Assignment } from '@mui/icons-material';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';

const PAYMENT_STATUS = {
  2: { label: 'Success', color: 'success' },
  0: { label: 'Pending', color: 'warning' },
  '-1': { label: 'Canceled', color: 'error' },
  '-2': { label: 'Failed', color: 'error' },
  '-3': { label: 'Chargedback', color: 'error' },
  'A': { label: 'Success', color: 'success' },
  'P': { label: 'Pending', color: 'warning' },
  'C': { label: 'Canceled', color: 'error' },
  'F': { label: 'Failed', color: 'error' }
};

const PAYMENT_STATUS_MESSAGES = {
  '2': 'Success',
  '0': 'Pending',
  '-1': 'Canceled',
  '-2': 'Failed',
  '-3': 'Chargedback',
  'A': 'Accepted',
  'P': 'Pending',
  'C': 'Canceled',
  'F': 'Failed'
};

const ManagePayments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [partnerFilter, setPartnerFilter] = useState('all');
  const partners = ['payhere', 'ipay', 'stripe'];

  useEffect(() => {
    fetchData();
  }, [tabValue]);

  const handleTimestamp = (timestamp) => {
    if (!timestamp) return null;
    if (typeof timestamp === 'string') {
      return new Date(timestamp);
    }
    const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);
    return isNaN(date.getTime()) ? null : date;
  };

  const refreshModalData = async (docRef) => {
    try {
      const updatedDoc = await getDoc(docRef);
      const data = updatedDoc.data();
      setSelectedItem({
        id: updatedDoc.id,
        ...data,
        timestamp: handleTimestamp(data.timestamp),
        updated_at: handleTimestamp(data.updated_at),
        admin_checked_at: handleTimestamp(data.admin_checked_at)
      });
    } catch (error) {
      console.error('Error refreshing modal data:', error);
      toast.error('Error refreshing details');
    }
  };

  const handleAdminCheck = async (item, checked) => {
    try {
      const collectionName = tabValue === 0 ? 'payments' : 'payment_requests';
      const docRef = doc(firestore, collectionName, item.id);
      await updateDoc(docRef, {
        admin_checked: checked,
        admin_checked_at: new Date()
      });
      
      // Refresh both the table and modal data
      fetchData();
      if (selectedItem && selectedItem.id === item.id) {
        await refreshModalData(docRef);
      }
      
      toast.success(`Payment ${checked ? 'accepted' : 'unaccepted'} successfully`);
    } catch (error) {
      console.error('Error updating admin check:', error);
      toast.error('Failed to update payment status');
    }
  };

  const handlePaymentStatusUpdate = async (paymentId, newStatusCode) => {
    try {
      const docRef = doc(firestore, 'payments', paymentId);
      await updateDoc(docRef, {
        status_code: newStatusCode,
        updated_at: new Date()
      });
      
      // Refresh both table and modal data
      fetchData();
      await refreshModalData(docRef);
      
      toast.success(`Payment status updated to ${PAYMENT_STATUS_MESSAGES[newStatusCode]}`);
      setUpdateError('');
    } catch (error) {
      console.error('Error updating payment status:', error);
      setUpdateError('Failed to update status');
      toast.error('Failed to update payment status');
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const collectionName = tabValue === 0 ? 'payments' : 'payment_requests';
      const q = query(
        collection(firestore, collectionName),
        fsOrderBy('timestamp', 'desc'),
        limit(100)
      );
      
      const snapshot = await getDocs(q);
      const data = snapshot.docs.map(doc => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
          timestamp: handleTimestamp(docData.timestamp),
          updated_at: handleTimestamp(docData.updated_at),
          admin_checked_at: handleTimestamp(docData.admin_checked_at),
          processed_at: handleTimestamp(docData.processed_at),
          // Extract provider info for better access
          provider_name: docData.provider?.name || '',
          transaction_id: docData.transaction_id || docData.order_id
        };
      });

      if (tabValue === 0) {
        setPayments(data);
      } else {
        setPaymentRequests(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = (tabValue === 0 ? payments : paymentRequests).filter(item => {
    const searchMatch = searchTerm === '' || 
      Object.values(item).some(val => 
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    
    const statusMatch = statusFilter === 'all' || 
      String(item.status_code || item.status) === statusFilter;
      
    const partnerFilterMatch = partnerFilter === 'all' || 
      (item.provider_name?.toLowerCase() === partnerFilter || 
       item.provider?.name?.toLowerCase() === partnerFilter);

    return searchMatch && statusMatch && partnerFilterMatch;
  });

  const handleStatusUpdate = async (requestId, newStatus) => {
    try {
      const docRef = doc(firestore, 'payment_requests', requestId);
      await updateDoc(docRef, {
        status: newStatus,
        updated_at: new Date()
      });
      
      // Refresh both table and modal data
      fetchData();
      await refreshModalData(docRef);
      
      toast.success(`Payment request status updated to ${newStatus}`);
      setUpdateError('');
    } catch (error) {
      console.error('Error updating status:', error);
      setUpdateError('Failed to update status');
      toast.error('Failed to update payment request status');
    }
  };

  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const actionCell = (item) => (
    <TableCell>
      <IconButton size="small" onClick={() => handleViewDetails(item)}>
        <Visibility />
      </IconButton>
    </TableCell>
  );

  const renderDetailsModal = () => {
    if (!selectedItem) return null;

    const isPaymentRequest = tabValue === 1;
    const providerName = selectedItem.provider?.name || selectedItem.provider_name || '';
    const isIpay = providerName.toLowerCase() === 'ipay';
    const isPayhere = providerName.toLowerCase() === 'payhere';
    
    const renderSection = (title, fields) => (
      <Box sx={{ mb: 3 }} className="bg-base-300 text-base-content p-4 rounded-lg">
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>{title}</Typography>
        <List dense >
          {Object.entries(fields).map(([key, value]) => (
            value !== undefined && value !== null && (
              <React.Fragment key={key}>
                <ListItem>
                  <ListItemText className='text-base-content' 
                    primary={key}
                    secondary={value}
                    primaryTypographyProps={{ 
                      variant: 'body2',
                      color: 'primary',
                    }}
                    secondaryTypographyProps={{ 
                      variant: 'body1',
                      color: 'text.success',
                      className: 'hidden sm:block'
                    }}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          ))}
        </List>
      </Box>
    );

    // Get raw payment data details
    const rawResponse = selectedItem.provider?.raw_response || selectedItem.raw_response || {};
    
    const paymentDetails = isPaymentRequest ? {
      'Order Information': {
        'Order ID': selectedItem.order_id,
        'Amount': `${selectedItem.amount} ${selectedItem.currency}`,
        'Items': selectedItem.items,
        'Course ID': selectedItem.courseId,
        'Status': selectedItem.status,
        'Created Date': selectedItem.timestamp ? format(selectedItem.timestamp, 'MMM dd, yyyy HH:mm') : 'N/A',
        'Last Updated': selectedItem.updated_at ? format(selectedItem.updated_at, 'MMM dd, yyyy HH:mm') : 'N/A',
        'Server Request Updated Date': selectedItem.pr_updated_date ? 
          format(selectedItem.pr_updated_date.toDate(), 'MMM dd, yyyy HH:mm') : 'Not Updated',
        'Admin Check': selectedItem.admin_checked ? 'Accepted' : 'Not Accepted',
        'Admin Check Date': selectedItem.admin_checked_at ? 
          format(selectedItem.admin_checked_at, 'MMM dd, yyyy HH:mm') : 'N/A'
      },
      'Customer Information': {
        'Customer Name': `${selectedItem.first_name} ${selectedItem.last_name}`,
        'Email': selectedItem.email,
        'Phone': selectedItem.phone,
        'User ID': selectedItem.userId
      },
      'Address Information': {
        'Address': selectedItem.address,
        'City': selectedItem.city,
        'Country': selectedItem.country
      },
      'Technical Information': {
        'Merchant ID': selectedItem.merchant_id,
        'Hash': selectedItem.hash
      }
    } : isIpay ? {
      'Transaction Information': {
        'Transaction ID': selectedItem.transaction_id,
        'Order ID': rawResponse.orderId || selectedItem.order_id,
        'Amount': `${rawResponse.transactionAmount || selectedItem.amount} ${selectedItem.currency}`,
        'Credited Amount': rawResponse.creditedAmount || selectedItem.provider?.credited_amount,
        'Status': PAYMENT_STATUS[rawResponse.transactionStatus || selectedItem.status]?.label,
        'Status Message': rawResponse.transactionMessage || selectedItem.status_message,
        'Transaction Reference': rawResponse.transactionReference || selectedItem.transaction_reference,
        'Date': selectedItem.timestamp ? format(selectedItem.timestamp, 'MMM dd, yyyy HH:mm') : 'N/A',
        'Processed At': selectedItem.processed_at ? format(selectedItem.processed_at, 'MMM dd, yyyy HH:mm') : 'N/A',
        'Admin Check': selectedItem.admin_checked ? 'Accepted' : 'Not Accepted',
        'Admin Check Date': selectedItem.admin_checked_at ? 
          format(selectedItem.admin_checked_at, 'MMM dd, yyyy HH:mm') : 'N/A'
      },
      'Metadata Information': {
        'Payment Method': selectedItem.payment_method || 'Not specified',
        'Course ID': selectedItem.metadata?.custom1,
        'Course Name': selectedItem.metadata?.custom2
      },
      'Technical Information': {
        'Provider': selectedItem.provider?.name || 'iPay',
        'Transaction Reference': selectedItem.transaction_reference,
        'Service Endpoint': rawResponse.serviceEndpoint,
        'Checksum': rawResponse.checksum || selectedItem.provider?.signature
      }
    } : isPayhere ? {
      'Transaction Information': {
        'Order ID': rawResponse.order_id || selectedItem.order_id,
        'Payment ID': rawResponse.payment_id || selectedItem.payment_id,
        'Amount': `${rawResponse.payhere_amount || selectedItem.amount} ${rawResponse.payhere_currency || selectedItem.currency}`,
        'Status': PAYMENT_STATUS[rawResponse.status_code || selectedItem.status_code]?.label,
        'Status Message': rawResponse.status_message || selectedItem.status_message,
        'Date': selectedItem.timestamp ? format(selectedItem.timestamp, 'MMM dd, yyyy HH:mm') : 'N/A',
        'Processed At': selectedItem.processed_at ? format(selectedItem.processed_at, 'MMM dd, yyyy HH:mm') : 'N/A',
        'Admin Check': selectedItem.admin_checked ? 'Accepted' : 'Not Accepted',
        'Admin Check Date': selectedItem.admin_checked_at ? 
          format(selectedItem.admin_checked_at, 'MMM dd, yyyy HH:mm') : 'N/A'
      },
      'Card Information': {
        'Card Holder': rawResponse.card_holder_name || selectedItem.provider?.card_details?.holder_name,
        'Card Number': rawResponse.card_no || selectedItem.provider?.card_details?.masked_number,
        'Expiry': rawResponse.card_expiry || selectedItem.provider?.card_details?.expiry,
        'Payment Method': rawResponse.method || selectedItem.payment_method
      },
      'Metadata Information': {
        'Custom Field 1': rawResponse.custom_1 || selectedItem.metadata?.custom1,
        'Custom Field 2': rawResponse.custom_2 || selectedItem.metadata?.custom2,
      },
      'Technical Information': {
        'Merchant ID': rawResponse.merchant_id || selectedItem.provider?.merchant_id,
        'Status Code': rawResponse.status_code || selectedItem.status_code,
        'MD5 Signature': rawResponse.md5sig || selectedItem.provider?.signature,
        'Recurring': rawResponse.recurring
      }
    } : {
      'Payment Information': {
        'Transaction ID': selectedItem.transaction_id || selectedItem.order_id,
        'Amount': `${selectedItem.amount || selectedItem.payhere_amount} ${selectedItem.currency || selectedItem.payhere_currency}`,
        'Status': PAYMENT_STATUS[selectedItem.status_code || selectedItem.status]?.label || 'Unknown',
        'Status Message': selectedItem.status_message,
        'Date': selectedItem.timestamp ? format(selectedItem.timestamp, 'MMM dd, yyyy HH:mm') : 'N/A',
        'Provider': selectedItem.provider?.name || 'Unknown',
        'Admin Check': selectedItem.admin_checked ? 'Accepted' : 'Not Accepted',
        'Admin Check Date': selectedItem.admin_checked_at ? 
          format(selectedItem.admin_checked_at, 'MMM dd, yyyy HH:mm') : 'N/A'
      },
      'Technical Information': {
        'Provider': selectedItem.provider?.name || 'Unknown',
        'Status Code': selectedItem.status_code || selectedItem.status,
        'Signature': selectedItem.signature || selectedItem.md5sig,
        'Payment Method': selectedItem.payment_method || selectedItem.method
      }
    };

    return (
      <Dialog 
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          className: 'bg-base-200'
        }}
      >
        <DialogTitle sx={{ pb: 1 }} className="bg-primary text-white">
          {isPaymentRequest ? 'Payment Request Details' : 
           `Payment Details - ${selectedItem.provider?.name || selectedItem.provider_name || 'Unknown Provider'}`}
        </DialogTitle>
        <DialogContent className="bg-base-200 text-base-content">
          {updateError && <Alert severity="error" sx={{ mb: 2 }}>{updateError}</Alert>}
          
          {Object.entries(paymentDetails).map(([section, fields]) => (
            renderSection(section, fields)
          ))}

          <Box sx={{ mt: 2 }} className="bg-base-300 p-4 rounded-lg">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Typography variant="subtitle2" className="text-base-content">
                Admin Accept:
              </Typography>
              <Switch
                checked={selectedItem.admin_checked || false}
                onChange={(e) => handleAdminCheck(selectedItem, e.target.checked)}
              />
              <Typography variant="body2" color={selectedItem.admin_checked ? "success.main" : "error.main"}>
                {selectedItem.admin_checked ? "Accepted" : "Not Accepted"}
              </Typography>
            </Box>

            <Typography variant="subtitle2" gutterBottom className="text-base-content">
              {isPaymentRequest ? 'Update Request Status:' : 'Update Payment Status:'}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {isPaymentRequest ? 
                ['success', 'pending', 'cancelled', 'failed', 'chargedback'].map((status) => (
                  <Button
                    key={status}
                    variant="outlined"
                    size="small"
                    color={status === 'success' ? 'success' : 
                           status === 'pending' ? 'warning' : 'error'}
                    onClick={() => handleStatusUpdate(selectedItem.id, status)}
                    disabled={selectedItem.status === status}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {status}
                  </Button>
                )) : 
                isIpay ?
                ['A', 'P', 'C', 'F'].map((code) => (
                  <Button
                    key={code}
                    variant="outlined"
                    size="small"
                    color={code === 'A' ? 'success' : 
                           code === 'P' ? 'warning' : 'error'}
                    onClick={() => handlePaymentStatusUpdate(selectedItem.id, code)}
                    disabled={selectedItem.status === code}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {PAYMENT_STATUS_MESSAGES[code]}
                  </Button>
                )) :
                Object.entries(PAYMENT_STATUS_MESSAGES).map(([code, label]) => (
                  <Button
                    key={code}
                    variant="outlined"
                    size="small"
                    color={code === '2' || code === 'A' ? 'success' : 
                           code === '0' || code === 'P' ? 'warning' : 'error'}
                    onClick={() => handlePaymentStatusUpdate(selectedItem.id, code)}
                    disabled={selectedItem.status_code === code}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {label}
                  </Button>
                ))
              }
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className="bg-base-200 text-base-content">
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const getStatusDisplay = (item) => {
    const statusCode = item.status_code || item.status;
    const statusInfo = PAYMENT_STATUS[statusCode];
    return {
      label: statusInfo?.label || 'Unknown',
      color: statusInfo?.color || 'default'
    };
  };

  const renderStatusCell = (item) => (
    <TableCell>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Chip 
          label={item.status_code ? 
            PAYMENT_STATUS_MESSAGES[item.status_code] || 'Unknown' :
            item.status}
          color={item.status_code === '2' || item.status === 'success' || item.status === 'A' ? 'success' : 
                 item.status_code === '0' || item.status === 'pending' || item.status === 'P' ? 'warning' : 'error'}
          size="small"
        />
        <Switch
          size="small"
          checked={item.admin_checked || false}
          onChange={(e) => handleAdminCheck(item, e.target.checked)}
        />
      </Box>
    </TableCell>
  );

  const renderPaymentTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            {!isMobile && <TableCell>Date</TableCell>}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((payment) => {
              const statusDisplay = getStatusDisplay(payment);
              const providerName = payment.provider?.name || payment.provider_name || 'Unknown';
              const amount = payment.amount || payment.payhere_amount || 
                payment.provider?.raw_response?.payhere_amount || 
                payment.provider?.raw_response?.transactionAmount || '0.00';
              const currency = payment.currency || payment.payhere_currency || 
                payment.provider?.raw_response?.payhere_currency || 'LKR';
              
              return (
                <TableRow key={payment.id}>
                  <TableCell>{payment.transaction_id || payment.order_id}</TableCell>
                  <TableCell>
                    <Chip 
                      label={providerName}
                      color={
                        providerName.toLowerCase() === 'payhere' ? 'primary' :
                        providerName.toLowerCase() === 'ipay' ? 'secondary' : 'default'
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {amount} {currency}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip 
                        label={statusDisplay.label}
                        color={statusDisplay.color}
                        size="small"
                      />
                      <Switch
                        size="small"
                        checked={payment.admin_checked || false}
                        onChange={(e) => handleAdminCheck(payment, e.target.checked)}
                      />
                    </Box>
                  </TableCell>
                  {!isMobile && <TableCell>
                    {payment.timestamp ? format(payment.timestamp, 'MMM dd, yyyy HH:mm') : 'N/A'}
                  </TableCell>}
                  {actionCell(payment)}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderRequestTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            {!isMobile && <TableCell>Customer</TableCell>}
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            {!isMobile && <TableCell>Date</TableCell>}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((request) => (
              <TableRow key={request.order_id}>
                <TableCell>{request.order_id}</TableCell>
                {!isMobile && <TableCell>
                  {`${request.first_name} ${request.last_name}`}
                </TableCell>}
                <TableCell>
                  {request.amount} {request.currency}
                </TableCell>
                {renderStatusCell(request)}
                {!isMobile && <TableCell>
                  {request.timestamp ? format(request.timestamp, 'MMM dd, yyyy HH:mm') : 'N/A'}
                </TableCell>}
                {actionCell(request)}
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom className="text-base-content">
        Manage Payments
      </Typography>

      <motion.div initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.1 }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
            setPage(0);
          }}
          sx={{ mb: 2 }}
          className="bg-slate-100 rounded-xl"
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile ? "fullWidth" : "standard"}
        >
          <Tab 
            label={
              <Badge badgeContent={payments.length} color="primary" max={99}>
                Payments
              </Badge>
            } 
            icon={<Payments />} 
            iconPosition="start" 
          />
          <Tab 
            label={
              <Badge badgeContent={paymentRequests.length} color="secondary" max={99}>
                Payment Requests
              </Badge>
            } 
            icon={<Assignment />}
            iconPosition="start"
          />
        </Tabs>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ delay: 0.2 }}
        className="bg-white p-4 rounded-lg shadow-sm mb-4"
      >
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flexGrow: 1, minWidth: '200px' }}
            placeholder="Search by order ID, email, customer name..."
          />
          
          <TextField
            select
            label="Partner"
            value={partnerFilter}
            onChange={(e) => setPartnerFilter(e.target.value)}
            size="small"
            sx={{ minWidth: '120px' }}
          >
            <MenuItem value="all">All Partners</MenuItem>
            {partners.map((partner) => (
              <MenuItem key={partner} value={partner}>
                {partner === 'payhere' ? 'PayHere' : 
                 partner === 'ipay' ? 'iPay' : 
                 partner.charAt(0).toUpperCase() + partner.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          {tabValue === 0 && (
            <TextField
              select
              label="Status"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              size="small"
              sx={{ minWidth: '150px' }}
            >
              <MenuItem value="all">All Status</MenuItem>
              {Object.entries(PAYMENT_STATUS).map(([code, { label }]) => (
                <MenuItem key={code} value={code}>{label}</MenuItem>
              ))}
            </TextField>
          )}
          
          <Button 
            variant="contained" 
            onClick={fetchData}
            disabled={loading}
            startIcon={<Refresh />}
            color="primary"
          >
            {loading ? 'Loading...' : 'Refresh'}
          </Button>
        </Box>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }} className="bg-base-200 rounded-lg">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="bg-base-200 rounded-lg">
            {tabValue === 0 ? renderPaymentTable() : renderRequestTable()}
            
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              className="text-base-content"
            />
          </Box>
        )}
      </motion.div>

      {renderDetailsModal()}
    </Box>
  );
};

export default ManagePayments;
