import React, { use, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { doc, getDoc, addDoc, collection, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebaseConfig';
import { FaMoneyBillWave, FaRocket } from 'react-icons/fa';
import { motion } from 'framer-motion';
import NotFound from '../NotFound';
import md5 from 'crypto-js/md5';

import { toast } from 'react-toastify';

const CourseSearch = () => {
    const { course } = useParams();
    const [courseData, setCourseData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        agreedToTerms: false
    });
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        const fetchCourseData = async () => {
            const courseRef = doc(firestore, 'courses', course);
            const courseSnap = await getDoc(courseRef);
            if (courseSnap.exists() && courseSnap.data().enabled === true) {
                setCourseData(courseSnap.data());
            } else {
                setCourseData(null);
            }
            setLoading(false);
        };

        fetchCourseData();
    }, [course]);

    useEffect(() => {const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userRef = doc(firestore, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUser({ ...user, name: userSnap.data().name || user.email });
          } else {
            setUser({ ...user, name: user.email });
          }
        } else {
          setUser(null);
        }
      });
   
     return () => unsubscribe();
    
    }, []);

    // Add this useEffect to pre-fill user details when modal opens
    useEffect(() => {
        if (user && user.displayName) {
            const names = user.displayName.split(' ');
            setUserDetails(prev => ({
                ...prev,
                firstName: names[0] || '',
                lastName: names.slice(1).join(' ') || ''
            }));
        }
    }, [isModalOpen, user]);
    
    const submitPaymentForm = (formData) => {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://sandbox.payhere.lk/pay/checkout';

        for (const [key, value] of Object.entries(formData)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
        }

        document.body.appendChild(form);
        
        
        form.submit();
        document.body.removeChild(form);
    };

    // Create a standardized payment data function to ensure consistency across gateways
    const createStandardPaymentData = (order, gateway, params = {}) => {
        if (!user || !courseData) return null;

        const baseData = {
            order_id: order,
            amount: parseFloat(courseData.fees || 0).toLocaleString('en-us', { minimumFractionDigits: 2 }).replaceAll(',', ''),
            currency: courseData.currency || 'LKR',
            items: courseData.title,
            email: user.email || "officialkasun@officialkasun.com",
            phone: user.phoneNumber || '0764783434',
            address: "No. 173/A, 5th Lane, Bodhirajapura, Puttalam (61328)",
            city: "Puttalam",
            country: "Sri Lanka",
            first_name: userDetails.firstName,
            last_name: userDetails.lastName,
            timestamp: new Date(),
            userId: user.uid,
            courseId: course,
            status: 'pending',
            partner: gateway, // Add partner field to identify the payment gateway
            ...params   // Additional gateway-specific fields
        };

        return baseData;
    };

    const handleUserDetailsSubmit = async () => {
        if (!user) {
            toast.error('Login First to Proceed');
            return;
        }

        if (!userDetails.firstName || !userDetails.lastName) {
            toast.error('Please fill in all fields');
            return;
        }

        if (!userDetails.agreedToTerms) {
            toast.error('Please agree to the terms and policies');
            return;
        }

        try {
            let order = "CORDER_" + courseData.alias.toUpperCase() + "_" + new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
            let merchantSecret = 'NjE3MjI3ODMyNDY5NzYzODMzODk5OTE0MjIxNDE4OTQ0OTk=';
            let merchantId = '1220863';
            let amount = courseData.fees || 0;
            let hashedSecret = md5(merchantSecret).toString().toUpperCase();
            let amountFormated = parseFloat(amount).toLocaleString('en-us', { minimumFractionDigits: 2 }).replaceAll(',', '');
            let currency = courseData.currency || 'LKR';
            let hash = md5(merchantId + order + amountFormated + currency + hashedSecret).toString().toUpperCase();

            const base_url = window.location.origin;

            const paymentData = {
                merchant_id: merchantId,
                return_url: base_url + '/payments/tracker',
                cancel_url: window.location.href,
                notify_url: 'https://api-v2.officialkasun.com/payment/callback/officialkasuntestlocal',
                hash: hash
            };

            // Create standardized payment data
            const standardData = createStandardPaymentData(order, 'payhere', { merchant_id: merchantId, hash: hash });

            // Save to Firebase, excluding the URLs
            const { notify_url, return_url, cancel_url, ...dataToSave } = {
                ...standardData,
                return_url: base_url + '/payments/tracker',
                cancel_url: window.location.href,
                notify_url: 'https://api-v2.officialkasun.com/payment/callback/officialkasuntestlocal'
            };

            await addDoc(collection(firestore, 'payment_requests'), dataToSave);
            
            // Submit the complete form to PayHere
            submitPaymentForm({
                ...standardData,
                return_url: base_url + '/payments/tracker',
                cancel_url: window.location.href,
                notify_url: 'https://api-v2.officialkasun.com/payment/callback/officialkasuntestlocal'
            });
            
            setIsModalOpen(false);
        } catch (error) {
            toast.error('Error processing payment');
            console.error(error);
        }
    };

    // Add a function to dynamically load scripts
    const loadExternalScript = (src, id) => {
        return new Promise((resolve, reject) => {
            // Check if script already exists
            if (document.getElementById(id)) {
                resolve();
                return;
            }
            
            const script = document.createElement('script');
            script.src = src;
            script.id = id;
            script.async = true;
            
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
            
            document.body.appendChild(script);
        });
    };

    const handleUserDetailsSubmitIpay = async () => {
        if (!user) {
            toast.error('Login First to Proceed');
            return;
        }
        
        // Validation checks first
        if (!userDetails.firstName || !userDetails.lastName) {
            toast.error('Please fill in all fields');
            return;
        }

        if (!userDetails.agreedToTerms) {
            toast.error('Please agree to the terms and policies');
            return;
        }
        
        try {
            // Show loading indication
            toast.info('Preparing payment...', { autoClose: 3000 });
            
            // Create payment order
            const order = "CORDER_" + courseData.alias.toUpperCase() + "_" + new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
            const amount = courseData.fees || 0;
            const formattedAmount = parseFloat(amount).toLocaleString('en-us', { minimumFractionDigits: 2 }).replaceAll(',', '');
            const currency = courseData.currency || 'LKR';
            const customerName = `${userDetails.firstName} ${userDetails.lastName}`;
            
            // iPay Web Token
            const merchantWebTokensand = "eyJhbGciOiJIUzUxMiJ9.eyJtaWQiOiIwMDAwMDEwOSJ9.fbVfjPzigubzyrZviDIhYhrKVPOkmx8bt0FkXESyzZ3DhVz7x7olegOM8FdXZS3ygfUU4ZXA7SeboYbUW8Pe9g"; // Sandbox token

            const merchantWebToken = "eyJhbGciOiJIUzUxMiJ9.eyJtaWQiOiIwMDA1MTQyOSJ9.mxnMrPWjjTKGvhhNtuzx5GGuciYkvr-D0x_MEwGLLwzU2WQi2H8XjtjX_8eSKxFaYhKB26gBPxz_Rh_uJVkyqA"; // Sandbox token
            
            // Create standardized payment data
            const standardData = createStandardPaymentData(order, 'ipay', { 
                merchantWebToken: merchantWebToken,
                customerName: customerName
            });
            
            // Save to Firebase
            await addDoc(collection(firestore, 'payment_requests'), standardData);
            
            // Clear any existing toasts
            toast.dismiss();
            
            // Create and submit the form
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://ipay.lk/ipg/checkout'; // Use 'https://ipay.lk/ipg/checkout' for production
            
            // Add form fields for iPay
            const formFields = {
                merchantWebToken: merchantWebToken,
                totalAmount: formattedAmount,
                orderId: order,
                orderDescription: courseData.title,
                returnUrl: window.location.origin + '/payments/tracker?source=ipay&order_id=' + order,
                cancelUrl: window.location.href,
                customerName: customerName,
                customerPhone: standardData.phone,
                customerEmail: standardData.email,
                merchantParam1: course, // Course ID as additional parameter
                merchantParam2: courseData.title // Course title as additional parameter
            };
            
            // Create form inputs
            for (const [key, value] of Object.entries(formFields)) {
                if (value) { // Only add fields that have values
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                }
            }
            
            // Append form to body and submit
            document.body.appendChild(form);
            form.submit();
            
            // Remove form from DOM after submission
            setTimeout(() => {
                document.body.removeChild(form);
            }, 100);
            
        } catch (error) {
            console.error('iPay setup error:', error);
            toast.error(error.message || 'Error setting up payment');
        }
    };

    const handleUserDetailsSubmitPRO = async () => {
        if (!user) {
            toast.error('Login First to Proceed');
            return;
        }
        
        if (!userDetails.firstName || !userDetails.lastName) {
            toast.error('Please fill in all fields');
            return;
        }

        if (!userDetails.agreedToTerms) {
            toast.error('Please agree to the terms and policies');
            return;
        }

        try {
            let order = "CORDER_" + courseData.alias.toUpperCase() + "_" + new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
            let merchantSecret = 'ced2374b22316069381a4a2453b4f85c';
            let merchantId = '219272';
            let amount = courseData.fees || 0;
            let hashedSecret = md5(merchantSecret).toString().toUpperCase();
            let amountFormated = parseFloat(amount).toLocaleString('en-us', { minimumFractionDigits: 2 }).replaceAll(',', '');
            let currency = courseData.currency || 'LKR';
            let hash = md5(merchantId + order + amountFormated + currency + hashedSecret).toString().toUpperCase();

            const base_url = window.location.origin;

            // Create standardized payment data
            const standardData = createStandardPaymentData(order, 'prolearners', { 
                merchant_id: merchantId, 
                hash: hash 
            });

            // Full data for form submission
            const paymentData = {
                ...standardData,
                return_url: base_url + '/payments/tracker',
                cancel_url: window.location.href,
                notify_url: 'https://api-v2.officialkasun.com/payment/callback/prolearnerstest'
            };

            // Save to Firebase, excluding URLs
            await addDoc(collection(firestore, 'payment_requests'), standardData);
            
            // Construct URL with parameters
            const queryParams = new URLSearchParams(paymentData).toString();
            const paymentUrl = `https://pay.prolearners.lk?${queryParams}`;
            
            window.location.href = paymentUrl;
            setIsModalOpen(false);
        } catch (error) {
            toast.error('Error processing payment');
            console.error(error);
        }
    };

    const handledEnroll = () => {
        if (user) {
            
            setIsModalOpen(true);
        } else {
            toast.warning('Please Login to Enroll', { autoClose: 800 });
        }
    }

    // Loading Animation
    if (loading) {
        return (
            <div className="w-full flex justify-center items-center h-screen">
                <motion.div
                    className="w-16 h-16 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-full"
                    animate={{
                        scale: [1, 1.5, 1.5, 1, 1],
                        rotate: [0, 0, 180, 180, 0],
                        borderRadius: ["50%", "50%", "50%", "50%", "50%"],
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut",
                        repeat: Infinity,
                    }}
                />
            </div>
        );
    }

    // If course data is not found
    if (!courseData) {
        return <NotFound />;
    }

    return (
        <div className='w-full flex justify-center items-center flex-wrap overflow-hidden mb-10 pb-10 '>
            <motion.div
                className="card bg-base-100 w-full md:w-2/3 lg:w-1/2 h-max shadow-xl  hover:bg-base-200 hover:scale-105  transition-all duration-500"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 ,  rotate: [0, 180, 180, 0, 0],
                    borderRadius: ["60%", "50%", "30%", "20%", "10%" , "0%"], scale: [0, 0.5,1],}}
                transition={{ duration: 0.5 }}
                
            >
                <div className="card-body">
                    <div className="flex items-center justify-center">
                        {courseData.image ? (
                            <img
                                src={courseData.image}
                                alt={courseData.title}
                                className="w-32 p-2 object-cover border-4 border-indigo-600"
                            />
                        ) : (
                            <div className="skeleton w-24 h-24 rounded-full bg-base-300 animate-pulse" />
                        )}
                    </div>
                    <div className='text-center w-full flex flex-row justify-center'>
                        <h2 className="card-title text-3xl font-bold">{courseData.title}</h2>
                    </div>
                    <p className="text-center text-lg text-gray-600 dark:text-gray-400 flex flex-row gap-2 items-center justify-center font-semibold">
                        {courseData.description} <FaRocket />
                    </p>
                    <div className="mt-4 bg-base-300 p-3 rounded-md">
                        <p className='font-semibold mb-5'>Course Modules:</p>
                        <ul className="list-disc list-inside text-sm md:text-base">
                            {courseData.modules.map((module, index) => (
                                <li key={index} className='hover:text-red-600 cursor-default hover:text-xl text-base hover:font-semibold'>
                                    {module}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="mt-6">
                        <div className="flex justify-between items-center">
                            <span className="badge badge-outline badge-primary text-sm">Medium: {courseData.medium}</span>
                            <span className="badge badge-outline badge-accent text-sm">Duration: {courseData.duration}</span>
                        </div>
                    </div>
                    <div className="mt-8 flex justify-center">
                        <div className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white px-6 py-4 rounded-2xl shadow-lg flex items-center gap-3 animate-bounce">
                            <FaMoneyBillWave className="text-3xl animate-pulse" />
                            <span className="text-xl font-bold">Fees: {courseData.fees} {courseData.currency}</span>
                        </div>
                    </div>
                    <div className="mt-8 flex justify-center">
                        {user ? (<button className="btn btn-primary btn-wide" onClick={handledEnroll}>
                            Enroll Now
                        </button>) : (<Link to={"/login/?redirect=" + location.href} className="btn btn-primary btn-wide" onClick={handledEnroll}>Login to Enroll</Link>)}
                    </div>
                </div>
            </motion.div>
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-base-100 p-6 rounded-lg w-[32rem] max-h-[90vh] overflow-y-auto">
                        <h3 className="text-lg font-bold mb-4">Enter Your Details</h3>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">First Name</span>
                            </label>
                            <input
                                type="text"
                                placeholder="First Name"
                                className="input input-bordered"
                                value={userDetails.firstName}
                                onChange={(e) => setUserDetails({...userDetails, firstName: e.target.value})}
                            />
                        </div>
                        <div className="form-control mt-4">
                            <label className="label">
                                <span className="label-text">Last Name</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Last Name"
                                className="input input-bordered"
                                value={userDetails.lastName}
                                onChange={(e) => setUserDetails({...userDetails, lastName: e.target.value})}
                            />
                        </div>

                        <div className="divider my-6">Policies & Agreements</div>

                        <div className="text-sm space-y-2 mb-4">
                            <p>Please read and agree to our policies:</p>
                            <ul className="list-disc list-inside space-y-1 ml-2">
                                <li>
                                    <Link to="/tc/privacy" className="link link-primary" target="_blank">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/tc/terms" className="link link-primary" target="_blank">
                                        Terms and Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/tc/refund" className="link link-primary" target="_blank">
                                        Refund Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text">I agree to the Privacy Policy, Terms and Conditions, and Refund Policy</span>
                                <input
                                    type="checkbox"
                                    className="checkbox checkbox-primary"
                                    checked={userDetails.agreedToTerms}
                                    onChange={(e) => setUserDetails({...userDetails, agreedToTerms: e.target.checked})}
                                />
                            </label>
                        </div>

                        <div className="modal-action mt-6">
                            <button className="btn btn-ghost" onClick={() => setIsModalOpen(false)}>Cancel</button>
                            {/* <button 
                                className="btn bg-blue-500 text-white" 
                                onClick={handleUserDetailsSubmit}
                                disabled={!userDetails.agreedToTerms}
                            >
                                 Pay with <img className="w-7" src="https://payherestorage.blob.core.windows.net/payhere-resources/www/images/favicon.png"></img>
                            </button> */}
                            <button 
                                className="btn bg-red-600 hover:bg-red-700 text-white" 
                                onClick={handleUserDetailsSubmitPRO}
                                disabled={!userDetails.agreedToTerms}
                            >
                                Pay with <img className="w-7" src="https://prolearners.lk/logo.png"></img>
                            </button>

                            <button 
                                className="btn bg-blue-600 hover:bg-blue-700 text-white" 
                                onClick={handleUserDetailsSubmitIpay}
                                disabled={!userDetails.agreedToTerms}
                            >
                                Pay with <img className="w-7" src="https://ipay.lk/resources/img/logo-new.svg"></img>
                            </button>

                            
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CourseSearch;