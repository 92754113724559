import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { FaHeart, FaDove } from 'react-icons/fa';
import 'tailwindcss/tailwind.css';

const LovePage = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs('2025-04-10'));
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loveStartDate] = useState(new Date('2025-01-26'));
  const [timeDiff, setTimeDiff] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentDate(now);
      const diff = selectedDate.toDate() - now;
      setTimeDiff({
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [selectedDate]);

  const formatTimeUnit = (unit) => (unit !== undefined ? unit.toString().padStart(2, '0') : '00');

  const renderTimeCounter = (label, time) => (
    <motion.div 
      className="flex flex-col items-center p-3 mx-1 my-2 bg-gradient-to-br from-pink-100 to-purple-50 rounded-xl shadow-md border border-pink-200"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-2xl font-bold text-purple-800">{formatTimeUnit(time)}</div>
      <div className="text-xs text-gray-600">{label}</div>
    </motion.div>
  );

  const handleDateChange = (date) => {
    if (date && date.isValid()) {
      setSelectedDate(date);
    }
  };

  return (
    <div className="flex flex-col w-full items-center justify-center  bg-pink-100 p-4 min-h-screen relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <div className='w-full flex justify-center z-10'><FaHeart className="text-red-500 text-6xl mb-4 animate-pulse" /></div>
        <Typography variant="h4" className="text-red-500 font-bold mb-4">
          <div className=' font-semibold text-pink-600 bg-white p-3 rounded-lg shadow-xl z-10 flex flex-col md:flex-row justify-center items-center gap-2'>
            <div className='text-blue-500'>
            HaPpiness 💕
            </div>
             <div className='text-black'>
              &
             </div>
             <div>
             Su 🤍💕
             </div>
             </div>
        </Typography>
       <div className='mt-10'>
       <Typography variant="h6" className="text-gray-700 mt-10 top-5">
          Since, <span className='font-semibold text-pink-700'> {loveStartDate.toDateString()}</span>
        </Typography>
       </div>
        <Box className="mb-4 mt-6 z-10 relative max-w-xs mx-auto">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select countdown date"
              value={selectedDate}
              onChange={handleDateChange}
              minDate={dayjs()}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                  borderRadius: '0.5rem',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                  '&:hover': {
                    borderColor: '#ec4899',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fbcfe8',
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#db2777 !important',
                  borderWidth: '2px',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#db2777',
                }
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined"
                }
              }}
            />
          </LocalizationProvider>
        </Box>
        <Typography variant="h5" className="text-gray-700 mb-4">
          Counting down to <span className='font-semibold text-blue-600'>{selectedDate.toDate().toDateString()}</span>
        </Typography>
        <div className="flex flex-wrap justify-center bg-base-200 p-3 rounded-lg shadow-xl z-10 mt-5">
          {renderTimeCounter("Days", timeDiff.days)}
          {renderTimeCounter("Hours", timeDiff.hours)}
          {renderTimeCounter("Minutes", timeDiff.minutes)}
          {renderTimeCounter("Seconds", timeDiff.seconds)}
        </div>
      </motion.div>

      {/* Heart Animations */}
      <motion.div 
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {[...Array(10)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute text-red-500"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              fontSize: `${Math.random() * 2 + 1}rem`,
            }}
            animate={{ y: ["0%", "-100%"], opacity: [1, 0] }}
            transition={{ duration: Math.random() * 5 + 3, repeat: Infinity, delay: Math.random() * 5 }}
          >
            <FaHeart />
          </motion.div>
        ))}
      </motion.div>

      {/* Bird Animations */}
      <motion.div 
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {[...Array(5)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute text-blue-500"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              fontSize: `${Math.random() * 3 + 2}rem`,
            }}
            animate={{ x: ["0%", "100%"], opacity: [1, 0] }}
            transition={{ duration: Math.random() * 10 + 5, repeat: Infinity, delay: Math.random() * 5 }}
          >
            <FaDove />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default LovePage;