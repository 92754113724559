import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { 
  Container, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Paper, 
  Divider,
  Alert,
  CircularProgress
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Window';
import { toast } from 'react-toastify';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const { signup, googleLogin, microsoftLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Parse redirect URL from query parameters if available
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirect');
  
  // Use redirect URL if available, otherwise use dashboard
  const from = redirectUrl || '/dashboard';

  // Function to set cookie
  const setCookie = (name, value, days = 7) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }
    
    try {
      setError('');
      setLoading(true);
      await signup(email, password);
      setCookie('username', email); // Set username cookie to email
      toast.success('Account created successfully!');
      
      // Handle the redirect properly
      if (redirectUrl && redirectUrl.startsWith('http')) {
        window.location.href = redirectUrl;
      } else {
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/email-already-in-use') {
        setError('Email is already in use.');
      } else if (error.code === 'auth/weak-password') {
        setError('Password is too weak. It should be at least 6 characters.');
      } else {
        setError('Failed to create an account.');
      }
      toast.error('Registration failed.');
    } finally {
      setLoading(false);
    }
  }

  async function handleGoogleSignup() {
    try {
      setError('');
      setLoading(true);
      const result = await googleLogin();
      // Get email from Google auth result
      const userEmail = result.user.email;
      setCookie('username', userEmail); // Set username cookie to email
      toast.success('Account created successfully!');
      
      // Handle the redirect properly
      if (redirectUrl && redirectUrl.startsWith('http')) {
        window.location.href = redirectUrl;
      } else {
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error(error);
      setError('Failed to sign up with Google.');
      toast.error('Google signup failed.');
    } finally {
      setLoading(false);
    }
  }

  async function handleMicrosoftSignup() {
    try {
      setError('');
      setLoading(true);
      const result = await microsoftLogin();
      // Get email from Microsoft auth result
      const userEmail = result.user.email;
      setCookie('username', userEmail); // Set username cookie to email
      toast.success('Account created successfully!');
      
      // Handle the redirect properly
      if (redirectUrl && redirectUrl.startsWith('http')) {
        window.location.href = redirectUrl;
      } else {
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error(error);
      setError('Failed to sign up with Microsoft.');
      toast.error('Microsoft signup failed.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 8, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Create an Account
        </Typography>
        
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            helperText="Password must be at least 6 characters"
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} color="inherit" />}
            >
              {loading ? 'Creating account...' : 'Sign Up'}
            </Button>
          </Box>
        </form>
        
        <Divider sx={{ my: 2 }}>OR</Divider>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={loading ? <CircularProgress size={20} /> : <GoogleIcon />}
              onClick={handleGoogleSignup}
              disabled={loading}
            >
              Sign up with Google
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={loading ? <CircularProgress size={20} /> : <MicrosoftIcon />}
              onClick={handleMicrosoftSignup}
              disabled={loading}
            >
              Sign up with Microsoft
            </Button>
          </Grid>
        </Grid>
        
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Typography variant="body2">
            Already have an account? <Link to="/login">Sign in</Link>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Register;
