import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageSummary from './slt/ManageSummary';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import { AiOutlineReload } from 'react-icons/ai';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Card, CardContent, CardHeader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WifiIcon from '@mui/icons-material/Wifi';
import CallIcon from '@mui/icons-material/Call';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ManageAddons from './slt/ManageAddons';
import ManageBillPayment from './slt/ManageBillPayment';
import ReceiptIcon from '@mui/icons-material/Receipt';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  { icon: <MenuIcon />, name: 'Menu', code: 'menu' },
  
];

export default function TemporaryDrawer() {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [direction, setDirection] = useState('down');
  const [hidden, setHidden] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [accountDetails, setAccountDetails] = useState(null);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // New state for tracking expanded sections
  const [expandedSections, setExpandedSections] = useState({
    accountDetails: false,
    voiceServices: false,
    broadbandServices: false
  });

  // Common menu items array used in both drawer and grid
  const menuItems = [
    { text: 'Manage Summary', icon: <SummarizeIcon />, code: 'manage-summary', description: 'View your account summary' },
    { text: 'Data Addons', icon: <WidgetsIcon />, code: 'manage-addons', description: 'Manage your data addons' },
    { text: 'Manage Bills', icon: <ReceiptIcon />, code: 'manage-bill-payment', description: 'View and pay your bills' },
  ];

  // Toggle function for expanding/collapsing sections
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    const page = searchParams.get('page');
    const board = searchParams.get('board');
    if (page && board === 'slt') {
      setCurrentPage(page);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentPage === null) {
      fetchAccountDetails();
    }
  }, [currentPage]);

  const fetchAccountDetails = async () => {
    setLoading(true);
    setError(null);
    const token = getCookie('slt_token');
    const username = getCookie('username');
    try {
      const response = await fetch(`https://omniscapp.slt.lk/slt/ext/api/AccountOMNI/GetAccountDetailRequest?username=${username}`, {
        "cache": "default",
        "credentials": "include",
        "headers": {
          "Accept": "application/json, text/plain, */*",
          "Accept-Language": "en-US,en;q=0.9",
          "Authorization": `bearer ${token}`,
          "Priority": "u=3, i",
          "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.3 Safari/605.1.15",
          "X-IBM-Client-Id": "b7402e9d66808f762ccedbe42c20668e"
        },
        "method": "GET",
        "mode": "cors",
        "redirect": "follow",
        "referrer": "https://myslt.slt.lk/",
        "referrerPolicy": "strict-origin-when-cross-origin"
      });

      if (response.status === 401) {
        await reAuthenticate();
      } else if (response.ok) {
        const data = await response.json();
        setAccountDetails(data.dataBundle);
        if (data.dataBundle && data.dataBundle.length > 0) {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 7);
            document.cookie = `slt_connection=${data.dataBundle[0].telephoneno}; expires=${expiryDate.toUTCString()}; path=/`;
          fetchServiceDetails(data.dataBundle[0].telephoneno);
        }
      } else {
        throw new Error('Failed to fetch account details');
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchServiceDetails = async (telephoneNo) => {
    const token = getCookie('slt_token');
    try {
      const response = await fetch(`https://omniscapp.slt.lk/slt/ext/api/AccountOMNI/GetServiceDetailRequest?telephoneNo=${telephoneNo}`, {
        "cache": "default",
        "credentials": "include",
        "headers": {
          "Accept": "application/json, text/plain, */*",
          "Accept-Language": "en-US,en;q=0.9",
          "Authorization": `bearer ${token}`,
          "Priority": "u=3, i",
          "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.3 Safari/605.1.15",
          "X-IBM-Client-Id": "b7402e9d66808f762ccedbe42c20668e"
        },
        "method": "GET",
        "mode": "cors",
        "redirect": "follow",
        "referrer": "https://officialkasun.com/",
        "referrerPolicy": "strict-origin-when-cross-origin"
      });

      if (response.ok) {
        const data = await response.json();
        setServiceDetails(data.dataBundle);
      } else {
        throw new Error('Failed to fetch service details');
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    }
  };

  const reAuthenticate = async () => {
    setAuthDialogOpen(true);
  };

  const handleAuthSubmit = async () => {
    setAuthDialogOpen(false);
    try {
      const response = await fetch("https://omniscapp.slt.lk/slt/ext/api/Account/Login", {
        "body": `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&channelID=WEB`,
        "cache": "default",
        "credentials": "omit",
        "headers": {
          "Accept": "application/json, text/plain, */*",
          "Accept-Language": "en-US,en;q=0.9",
          "Content-Type": "application/x-www-form-urlencoded",
          "Priority": "u=3, i",
          "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.3 Safari/605.1.15",
          "X-IBM-Client-Id": "b7402e9d66808f762ccedbe42c20668e"
        },
        "method": "POST",
        "mode": "cors",
        "redirect": "follow",
        "referrer": "https://myslt.slt.lk/",
        "referrerPolicy": "strict-origin-when-cross-origin"
      });

      if (response.ok) {
        const data = await response.json();
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 7);
        const cookieOptions = `; expires=${expiryDate.toUTCString()}; path=/`;
        document.cookie = `slt_token=${data.accessToken}${cookieOptions}`;
        document.cookie = `username=${username}${cookieOptions}`;
        toast.success("Authentication successful. Token valid for 7 days.");

        fetchAccountDetails();
      } else {
        throw new Error('Failed to re-authenticate');
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    toast.info('Token deleted. Please re-authenticate.');
    setAccountDetails(null);
    setServiceDetails(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuClick = (text) => () => {
    switch (text) {
      case 'menu':
        toggleDrawer(true)();
        break;
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const handleClick = (text) => () => {
    switch (text) {
      case 'manage-summary':
      case 'manage-addons':
      case 'manage-bill-payment':
        setCurrentPage(text);
        setSearchParams({ board: 'slt', page: text });
        break;
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const renderPage = () => {
    if (currentPage === 'manage-summary') {
      return <ManageSummary />;
    }else  if (currentPage === 'manage-addons') {
      return <ManageAddons />;
    }else  if (currentPage === 'manage-bill-payment') {
      return <ManageBillPayment />;
    } else {
      return null;
    }
  };

  const DrawerList = (
    <div className="bg-base-300 text-base-content h-screen">
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={item.text} disablePadding className='hover:scale-105 duration-500 transition-all ease-in-out hover:font-semibold'>
              <ListItemButton onClick={handleClick(item.code)}>
                <ListItemIcon>
                  {React.cloneElement(item.icon, { className: 'text-base-content' })}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    </div>
  );

  return (
    <div>
      <div className='z-20 w-full fixed flex justify-start items-center top-[230px] left-20 '>
        <Box sx={{ position: 'absolute', mt: 3, height: 320 }}>
          <StyledSpeedDial
            ariaLabel="Admin Buddy"
            hidden={hidden}
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            direction={direction}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={handleMenuClick(action.code)}
              />
            ))}
          </StyledSpeedDial>
        </Box>
      </div>
      <div className="flex justify-center items-center">
        {currentPage === null ? (
          <div className='w-full flex flex-col gap-4'>
            <div className='flex flex-col justify-center items-center gap-5 mt-5'>
              <h1 className="text-3xl font-bold">SLT Home Broadband</h1>
              <p className="text-lg">Welcome to v2.0</p>
              {accountDetails && accountDetails.length > 0 && (
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  onClick={handleMenuClick('menu')}
                  tabIndex={-1}
                  startIcon={<MenuIcon />}
                > Broadband Menu
                </Button>
              )}
            </div>
            
            {/* Display grid menu if account details are available */}
            {accountDetails && accountDetails.length > 0 && (
              <div id='slt-options' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6'>
                {menuItems.map((item) => (
                  <div 
                    key={item.text} 
                    onClick={handleClick(item.code)}
                    className='bg-base-100 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center gap-3 cursor-pointer hover:shadow-xl hover:scale-105 duration-300 transition-all ease-in-out border border-base-300'
                  >
                    <div className='p-3 rounded-full bg-base-200'>
                      {React.cloneElement(item.icon, { className: 'text-primary text-4xl' })}
                    </div>
                    <h3 className='text-xl font-semibold'>{item.text}</h3>
                    <p className='text-sm text-center text-base-content/70'>{item.description}</p>
                  </div>
                ))}
              </div>
            )}

            <div id='slt_home' className='w-full'>
              {loading ? (
                <motion.div
                  className="flex justify-center items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="loader"></div>
                </motion.div>
              ) : error ? (
                <div className="text-red-500 flex flex-col gap-4 items-center mt-10 bg-base-300 rounded-lg shadow-md p-4 hover:scale-110 duration-500 transition-all ease-in-out">
                  <p className='font-semibold text-xl'>{error}</p>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    onClick={reAuthenticate}
                    tabIndex={-1}
                    startIcon={<AiOutlineReload />}
                  > Re-Authenticate
                  </Button>
                  
                </div>
              ) : accountDetails ? (
                <div className="flex flex-col gap-4">
                  {accountDetails.map((detail, index) => (
                    <div key={index} className="p-4 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-500">
                      <p className='font-semibold text-yellow-300'>Account No: <span className=' select-text'>{detail.accountno}</span></p>
                      <p className='font-semibold'>Telephone No: <span className=' select-text'>{detail.telephoneno}</span></p>
                      <p className='font-semibold'>Status: {detail.status}</p>
                    </div>
                  ))}
                  {serviceDetails && (
                    <div className="p-4  bg-blue-600 rounded-lg shadow-md">
                      {/* Account Details Section */}
                      <Card className="mb-4 overflow-visible">
                        <CardHeader
                          title={
                            <div className="flex items-center">
                              <AccountCircleIcon className="mr-2 text-orange-600" />
                              <span className="font-semibold text-orange-600">Account Details</span>
                            </div>
                          }
                          action={
                            <IconButton 
                              onClick={() => toggleSection('accountDetails')}
                              aria-expanded={expandedSections.accountDetails}
                              aria-label="show more"
                            >
                              {expandedSections.accountDetails ? <ExpandLessIcon className='text-base-content' /> : <ExpandMoreIcon className='text-base-content' />}
                            </IconButton>
                          }
                          className="bg-base-200"
                        />
                        <AnimatePresence>
                          {expandedSections.accountDetails && (
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <CardContent className='bg-base-100'>
                                <div className="p-2 bg-base-content text-base-100 rounded-lg shadow-sm">
                                  <p className='font-semibold text-red-600'>Account No: <span className='select-text'>{serviceDetails.accountNo}</span></p>
                                  <p>Promotion Name: {serviceDetails.promotionName}</p>
                                  <p>Account Category: {serviceDetails.accountCategory}</p>
                                  <p>Contact Name: <span className='select-text'>{serviceDetails.contactNamewithInit}</span></p>
                                  <p>Contact Mobile: <span className='select-text'>{serviceDetails.contactMobile}</span></p>
                                  <p>Promotion Type: {serviceDetails.promotionType}</p>
                                </div>
                              </CardContent>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Card>

                      {/* Voice Services Section */}
                      <Card className="mb-4 overflow-visible">
                        <CardHeader 
                          title={
                            <div className="flex items-center">
                              <CallIcon className="mr-2 text-blue-500" />
                              <span className="font-semibold text-blue-500">Voice Services</span>
                            </div>
                          }
                          action={
                            <IconButton 
                              onClick={() => toggleSection('voiceServices')}
                              aria-expanded={expandedSections.voiceServices}
                              aria-label="show more"
                              
                            >
                              {expandedSections.voiceServices ? <ExpandLessIcon className='text-base-content' /> : <ExpandMoreIcon className='text-base-content' />}
                            </IconButton>
                          }
                          className="bg-base-200"
                        />
                        <AnimatePresence>
                          {expandedSections.voiceServices && (
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <CardContent className='bg-base-200'>
                                {serviceDetails.listofVoiceService.map((service, index) => (
                                  <motion.div 
                                    key={index} 
                                    className="p-2 bg-base-content text-base-100 rounded-lg shadow-sm mt-2"
                                    initial={{ y: 20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ delay: index * 0.1 }}
                                  >
                                    <p>Service ID: {service.serviceID}</p>
                                    <p>Package Name: {service.packageName}</p>
                                    <p>Status: {service.serviceStatus}</p>
                                    <p>Type: {service.serviceType}</p>
                                  </motion.div>
                                ))}
                              </CardContent>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Card>

                      {/* Broadband Services Section */}
                      <Card className="overflow-visible">
                        <CardHeader 
                          title={
                            <div className="flex items-center">
                              <WifiIcon className="mr-2 text-red-600" />
                              <span className="font-semibold text-red-600">Broadband Services</span>
                            </div>
                          }
                          action={
                            <IconButton 
                              onClick={() => toggleSection('broadbandServices')}
                              aria-expanded={expandedSections.broadbandServices}
                              aria-label="show more"
                            >
                              {expandedSections.broadbandServices ? <ExpandLessIcon className='text-base-content' /> : <ExpandMoreIcon className='text-base-content' />}
                            </IconButton>
                          }
                          className="bg-base-200"
                        />
                        <AnimatePresence>
                          {expandedSections.broadbandServices && (
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <CardContent className='bg-base-200'>
                                {serviceDetails.listofBBService.map((service, index) => (
                                  <motion.div 
                                    key={index} 
                                    className="p-2 bg-base-content text-base-100 rounded-lg shadow-sm mt-2"
                                    initial={{ y: 20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ delay: index * 0.1 }}
                                  >
                                    <p>Service ID: {service.serviceID}</p>
                                    <p>Package Name: {service.packageName}</p>
                                    <p>Status: {service.serviceStatus}</p>
                                    <p>Type: {service.serviceType}</p>
                                  </motion.div>
                                ))}
                              </CardContent>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Card>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-red-500 flex flex-col gap-4 items-center mt-10 bg-base-300 rounded-lg shadow-md p-4 hover:scale-110 duration-500 transition-all ease-in-out">
                  <p className='font-semibold text-xl'>Please re-authenticate to view details</p>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    onClick={reAuthenticate}
                    tabIndex={-1}
                    startIcon={<AiOutlineReload />}
                  > Re-Authenticate
                  </Button>
                </div>
              )}
             <div className='mt-10 border-t-2 py-3 w-full flex justify-center gap-4'>
             <Button
                component="label"
                role={undefined}
                variant="contained"
                onClick={fetchAccountDetails}
                tabIndex={-1}
                startIcon={<AiOutlineReload />}
              > Reload
              </Button>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                onClick={() => deleteCookie('slt_token') && deleteCookie('username')}
                tabIndex={-1}
                color='error'
                startIcon={<DeleteIcon />}
              > Remove Token
              </Button>
             </div>
            </div>
          </div>
        ) : (
          <div className='w-full'>{renderPage()}</div>
        )}
      </div>
     {accountDetails && accountDetails.length > 0 && (
       <div>
       <Drawer open={open} onClose={toggleDrawer(false)}>
         {DrawerList}
       </Drawer>
     </div>
     )}
      <Dialog open={authDialogOpen} onClose={() => setAuthDialogOpen(false)}>
        <DialogTitle>Re-Authenticate</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="email"
            fullWidth
            variant="standard"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAuthDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAuthSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
