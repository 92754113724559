import React, { useState, useEffect } from 'react';
import { RxReload } from 'react-icons/rx';
import { motion } from 'framer-motion';
import { MdOutlineSummarize } from 'react-icons/md';

const Summary = () => {
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const fetchData = async () => {
    setLoading(true);
    try {

      const username = getCookie('slt_connection');
      const slt_token = getCookie('slt_token');
      let number = username ? username : "null";
      let token = slt_token ? slt_token : "null";
      const response = await fetch(
        'https://omniscapp.slt.lk/slt/ext/api/BBVAS/UsageSummary?subscriberID=' + number,
        {
          cache: 'default',
          credentials: 'include',
          headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization:
              'bearer ' + token,
            'User-Agent': 'Mozilla/5.0',
            'X-IBM-Client-Id': 'b7402e9d66808f762ccedbe42c20668e',
          },
          method: 'GET',
          mode: 'cors',
          redirect: 'follow',
          referrer: 'https://myslt.slt.lk/',
          referrerPolicy: 'strict-origin-when-cross-origin',
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setSuccess(data);
      console.log(data);
    } catch (error) {
      console.error(error);
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container my-5">
      {loading ? (
        <div className="flex flex-col items-center justify-center p-10">
          <motion.div
            initial={{ scale: 0.8, opacity: 0.5 }}
            animate={{ scale: 1.2, opacity: 1 }}
            transition={{ 
              repeat: Infinity, 
              repeatType: "reverse", 
              duration: 0.8 
            }}
            className="text-primary text-5xl mb-3"
          >
            <MdOutlineSummarize />
          </motion.div>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-lg font-medium text-gray-600 dark:text-gray-300"
          >
            Loading summary data...
          </motion.p>
        </div>
      ) : success && success.isSuccess !== undefined ? (
        <div className="bg-base-200 p-10 shadow-lg card">
          <div className="">
            <h5 className="text-green-500 card-title">
              {success.isSuccess ? 'Success' : 'Error'} | Summary{' '}
              <span className="cursor-pointer" onClick={fetchData}>
                <RxReload />
              </span>
            </h5>

            {success.errorMessege && (
              <p className="text-danger">{success.errorMessege}</p>
            )}

            <p className="card-text">
              <strong>Reported Time:</strong> {success.dataBundle?.reported_time}
            </p>

            <p className="card-text">
              <strong>Package:</strong> {success.dataBundle?.my_package_info?.package_name}
            </p>

            <p className="card-text">
              <strong>Speed:</strong> {success.dataBundle?.status}
            </p>

            <div className="my-5">
              {/* {success.dataBundle?.my_package_summary && (
                <div className="card mb-3">
                  <div className="card-body">
                    <h6 className="card-subtitle mb-2 text-muted">Package Summary</h6>
                    <p>
                      <strong>Limit:</strong> {success.dataBundle.my_package_summary.limit || 'N/A'}{' '}
                      {success.dataBundle.my_package_summary.volume_unit}
                    </p>
                    <p>
                      <strong>Used:</strong> {success.dataBundle.my_package_summary.used || 'N/A'}{' '}
                      {success.dataBundle.my_package_summary.volume_unit}
                    </p>
                  </div>
                </div>
              )}

              {success.dataBundle?.vas_data_summary && (
                <div className="card mb-3">
                  <div className="card-body">
                    <h6 className="card-subtitle mb-2 text-muted">VAS Data</h6>
                    <p>
                      <strong>Limit:</strong> {success.dataBundle.vas_data_summary.limit || 'N/A'}{' '}
                      {success.dataBundle.vas_data_summary.volume_unit}
                    </p>
                    <p>
                      <strong>Used:</strong> {success.dataBundle.vas_data_summary.used || 'N/A'}{' '}
                      {success.dataBundle.vas_data_summary.volume_unit}
                    </p>
                  </div>
                </div>
              )} */}

              {success.dataBundle?.my_package_info?.usageDetails?.length > 0 && (
                <div className="flex md:flex-row flex-col justify-center gap-4 mt-5">
                  
                  {success.dataBundle.my_package_info.usageDetails.map(
                    (usage, index) => (
                      <div
                        key={index}
                        className="col-md-4 bg-base-300 card hover:scale-105 shadow-lg"
                      >
                        <div className="card mb-3">
                          <div className="card-body">
                            <h6 className="card-title text-red-600">{usage.name}</h6>
                            <p>
                              <strong>Used:</strong>{' '}
                              <span className="text-blue-500 font-semibold">
                                {usage.used || 'N/A'} {usage.volume_unit}
                              </span>{' '}
                              / {usage.limit || 'Unlimited'}{' '}
                              {usage.limit ? usage.volume_unit : ''}
                            </p>
                            <p className="text-green-600">
                              <strong>Expiry Date:</strong>{' '}
                              {usage.expiry_date || 'N/A'}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  {success.dataBundle?.bonus_data_summary && (
                    <div
                      className="col-md-4 bg-base-300 card hover:scale-105 shadow-lg"
                    >
                      <div className="card mb-3">
                        <div className="card-body">
                          <h6 className="card-title text-red-600">Bonus Data</h6>
                          <p>
                            <strong>Used:</strong>{' '}
                            <span className="text-blue-500 font-semibold">
                              {success.dataBundle.bonus_data_summary.used || 'N/A'}{' '}
                              {success.dataBundle.bonus_data_summary.volume_unit}
                            </span>
                            /{' '}{success.dataBundle.bonus_data_summary.limit || 'N/A'}{' '}
                            {success.dataBundle.bonus_data_summary.volume_unit}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p className="text-danger">No data available. Please try again.</p>
      )}
    </div>
  );
};

export default Summary;
