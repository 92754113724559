import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import {
  Box, Typography, useTheme, useMediaQuery, CircularProgress, Card,
  CardContent, List, ListItem, ListItemText, Divider, Alert, Avatar,
  Grid, Chip, Button, Link, Dialog, DialogTitle, DialogContent, 
  DialogActions, Tab, Tabs, IconButton, Paper
} from '@mui/material';
import { 
  Business, Email, Phone, Category, Check, ErrorOutline, 
  Download, Visibility, Description, Language 
} from '@mui/icons-material';

const MyBusiness = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(0);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loadingLogos, setLoadingLogos] = useState({});
  const [loadingDocument, setLoadingDocument] = useState(false);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  const fetchBusinessData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Get the current user
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError("You need to be logged in to view your business details.");
        setLoading(false);
        return;
      }

      // Get user document
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      
      if (!userDoc.exists()) {
        setError("User profile not found.");
        setLoading(false);
        return;
      }

      const userData = userDoc.data();
      
      // Check if user has businesses
      if (!userData.businesses || userData.businesses.length === 0) {
        setError("You don't have any business associated with your account.");
        setLoading(false);
        return;
      }

      // Fetch all businesses data
      const businessesData = [];
      
      for (const businessItem of userData.businesses) {
        // Get business details from businesses collection
        const businessDoc = await getDoc(doc(firestore, "businesses", businessItem.businessId));
        
        if (businessDoc.exists()) {
          const businessDetails = { 
            id: businessDoc.id,
            ...businessDoc.data(),
            userRole: businessItem.role || []
          };
          
          businessesData.push(businessDetails);
        }
      }
      
      if (businessesData.length === 0) {
        setError("No business information found.");
      } else {
        setBusinesses(businessesData);
        setSelectedBusiness(0);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
      setError("An error occurred while fetching business data.");
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentView = (docUrl, docName) => {
    // Set the selected document and open modal
    setSelectedDocument({
      url: docUrl,
      name: docName
    });
    setLoadingDocument(true);
    setDocumentModalOpen(true);
  };

  const handleDownloadDocument = (url, filename) => {
    // Open document in new window
    window.open(url, '_blank');
  };

  const handleImageLoad = (businessId) => {
    setLoadingLogos(prev => ({...prev, [businessId]: false}));
  };

  const handleImageError = (businessId) => {
    setLoadingLogos(prev => ({...prev, [businessId]: false}));
  };

  const handleDocumentLoad = () => {
    setLoadingDocument(false);
  };

  const handleDocumentError = () => {
    setLoadingDocument(false);
  };

  // Set initial loading state for all logos
  useEffect(() => {
    if (businesses.length > 0) {
      const initialLoadingState = businesses.reduce((acc, business) => {
        acc[business.id] = business.logo_url ? true : false;
        return acc;
      }, {});
      setLoadingLogos(initialLoadingState);
    }
  }, [businesses]);

  const renderBusinessTabs = () => {
    if (businesses.length <= 0) return null;
    
    return (
      <Box 
      className="p-4"
        sx={{ 
          display: 'flex', 
          overflowX: 'auto', 
          gap: 2,
          pb: 1,
          mb: 2,
          '&::-webkit-scrollbar': {
            height: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '3px',
          }
        }}
      >
        {businesses.map((business, index) => (
          <Card 
            key={business.id} 
            sx={{ 
              minWidth: 220,
              cursor: 'pointer',
              transform: selectedBusiness === index ? 'scale(1.02)' : 'scale(1)',
              border: selectedBusiness === index ? `2px solid ${theme.palette.primary.main}` : 'none',
              transition: 'all 0.3s ease',
            }}
            onClick={() => setSelectedBusiness(index)}
            className={selectedBusiness === index ? "bg-base-300 hover:scale-105" : "bg-base-200 hover:scale-105"}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {business.logo_url ? (
                  <Box sx={{ position: 'relative', width: 60, height: 60, mr: 1 }}>
                    {loadingLogos[business.id] && (
                      <Box
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                          zIndex: 1
                        }}
                      >
                        <CircularProgress size={30} />
                      </Box>
                    )}
                    <Avatar 
                      src={business.logo_url} 
                      alt={business.name}
                      variant="rounded"
                      sx={{ 
                        width: 60, 
                        height: 60, 
                        visibility: loadingLogos[business.id] ? 'hidden' : 'visible'
                      }}
                      imgProps={{
                        onLoad: () => handleImageLoad(business.id),
                        onError: () => handleImageError(business.id)
                      }}
                    />
                  </Box>
                ) : (
                  <Avatar 
                 
                    sx={{ width: 60, height: 60, mr: 1, bgcolor: theme.palette.primary.main }}
                    variant="rounded"
                  >
                    <Business />
                  </Avatar>
                )}
                <Box>
                  <Typography variant="h6" noWrap sx={{ maxWidth: 150 }}>
                    {business.name}
                  </Typography>
                  <Chip 
                    icon={business.active ? <Check /> : <ErrorOutline />}
                    label={business.active ? "Active" : "Inactive"}
                    color={business.active ? "success" : "error"}
                    size="small"
                  />
                </Box>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Since {business.business_registered_year || 'N/A'}
              </Typography>
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                Your role: {business.userRole.join(", ")}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };

  const renderDocumentModal = () => (
    <Dialog
      open={documentModalOpen}
      onClose={() => setDocumentModalOpen(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            {selectedDocument?.name || 'Business Document'}
          </Typography>
          <IconButton
            onClick={() => handleDownloadDocument(selectedDocument?.url, selectedDocument?.name)}
            color="primary"
          >
            <Download />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {selectedDocument?.url && (
          <Box sx={{ position: 'relative', minHeight: '500px' }}>
            {loadingDocument && (
              <Box sx={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                bgcolor: 'rgba(0,0,0,0.05)',
                zIndex: 1
              }}>
                <CircularProgress />
              </Box>
            )}
            
            {selectedDocument.url.toLowerCase().endsWith('.pdf') ? (
              <iframe 
                src={`${selectedDocument.url}#toolbar=0`} 
                width="100%" 
                height="500px" 
                title="Document Viewer"
                style={{ border: 'none' }}
                onLoad={handleDocumentLoad}
                onError={handleDocumentError}
              />
            ) : (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                bgcolor: 'black',
                p: 2,
                borderRadius: 1,
                minHeight: '500px'
              }}>
                <img 
                  src={selectedDocument.url} 
                  alt="Business Document" 
                  style={{ maxWidth: '100%', maxHeight: '70vh' }}
                  onLoad={handleDocumentLoad}
                  onError={handleDocumentError}
                />
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => handleDownloadDocument(selectedDocument?.url, selectedDocument?.name)}
          startIcon={<Download />}
        >
          Download
        </Button>
        <Button onClick={() => setDocumentModalOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderBusinessDetails = () => {
    if (businesses.length === 0) return null;
    
    const business = businesses[selectedBusiness];
    
    if (!business.active) {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
          <Typography variant="h6">Business Terminated</Typography>
          <Typography>
            Your business "{business.name}" has been deactivated or terminated. 
            Please contact support for more information.
          </Typography>
        </Alert>
      );
    }

    return (
      <Card sx={{ mt: 2 }} className="bg-base-200">
        <CardContent className='bg-base-100 text-base-content'>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 3, 
            flexWrap: 'wrap', 
            gap: 2,
            flexDirection: isMobile ? 'column' : 'row'
          }}>
            {business.logo_url && (
              <Box sx={{ position: 'relative', width: isMobile ? 150 : 100, height: isMobile ? 150 : 100 }}>
                {loadingLogos[business.id] && (
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      zIndex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Avatar 
                  src={business.logo_url} 
                  alt={business.name} 
                  sx={{ 
                    width: isMobile ? 150 : 100, 
                    height: isMobile ? 150 : 100,
                    mr: isMobile ? 0 : 2,
                    visibility: loadingLogos[business.id] ? 'hidden' : 'visible'
                  }}
                  variant="rounded"
                  imgProps={{
                    onLoad: () => handleImageLoad(business.id),
                    onError: () => handleImageError(business.id)
                  }}
                />
              </Box>
            )}
            <Box sx={{ textAlign: isMobile ? 'center' : 'left', width: isMobile ? '100%' : 'auto' }}>
              <Typography variant="h4" gutterBottom component="div" className="text-primary select-text">
                {business.name}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                justifyContent: isMobile ? 'center' : 'flex-start',
                flexWrap: 'wrap'
              }}>
                <Chip 
                  icon={<Check />} 
                  label="Active" 
                  color="success" 
                  size="small" 
                  sx={{ mr: 1 }}
                />
                <Typography variant="body2" color="text.secondarys">
                  Since {business.business_registered_year}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondarys" sx={{ mt: 1 }}>
                Your role: {business.userRole.join(", ")}
              </Typography>
            </Box>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card variant="outlined" className="bg-base-100" >
                <CardContent className='bg-base-200 text-base-content'>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Category sx={{ mr: 1 }} /> Business Information
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary="Category" 
                        secondary={business.business_category || "Not specified"}
                        primaryTypographyProps={{ color: 'text.secondarys', variant: 'body2' }}
                        secondaryTypographyProps={{ color: 'text.primarys', variant: 'body1' }}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <ListItemText 
                        primary="Established" 
                        secondary={business.business_registered_year || "Not specified"}
                        primaryTypographyProps={{ color: 'text.secondarys', variant: 'body2' }}
                        secondaryTypographyProps={{ color: 'text.primarys', variant: 'body1' }}
                      />
                    </ListItem>
                    
                    {business.description && (
                      <>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Description fontSize="small" sx={{ mr: 1 }} /> 
                                Description
                              </Box>
                            }
                            secondary={business.description}
                            primaryTypographyProps={{ color: 'text.secondarys', variant: 'body2' }}
                            secondaryTypographyProps={{ color: 'text.primarys', variant: 'body1' }}
                          />
                        </ListItem>
                      </>
                    )}
                    
                    {business.website && (
                      <>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Language fontSize="small" sx={{ mr: 1 }} /> 
                                Website
                              </Box>
                            }
                            secondary={
                              <Link className=' select-text' href={business.website} target="_blank" rel="noopener">
                                {business.website}
                              </Link>
                            }
                            primaryTypographyProps={{ color: 'text.secondarys', variant: 'body2' }}
                          />
                        </ListItem>
                      </>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Card variant="outlined" className="bg-base-100">
                <CardContent className='bg-base-200 text-base-content'>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Business sx={{ mr: 1 }} /> Contact Information
                  </Typography>
                  
                  {business.emails && business.emails.length > 0 && (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Email fontSize="small" sx={{ mr: 1 }} />
                        <Typography variant="subtitle2">Email Addresses</Typography>
                      </Box>
                      <List dense>
                        {business.emails.map((email, index) => (
                          <ListItem key={index}>
                           <a className=' select-text' href={`mailto:${email}`} > <ListItemText 
                              primary={email}
                              primaryTypographyProps={{ variant: 'body1' }}
                            /></a>
                          </ListItem>
                        ))}
                      </List>
                      <Divider sx={{ my: 1 }} />
                    </>
                  )}
                  
                  {business.phones && business.phones.length > 0 && (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Phone fontSize="small" sx={{ mr: 1 }} />
                        <Typography variant="subtitle2">Phone Numbers</Typography>
                      </Box>
                      <List dense>
                        {business.phones.map((phone, index) => (
                          <ListItem key={index}>
                              <a className=' select-text' href={`tel:${phone}`} ><ListItemText 
                              primary={phone}
                              primaryTypographyProps={{ variant: 'body1' }}
                            /></a>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </CardContent>
              </Card>
              
              {business.br_document_url && (
                <Card variant="outlined" className="bg-base-100 mt-2">
                  <CardContent className='bg-base-200 text-base-content'>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      Business Registration
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" gutterBottom>
                        BR Number: <span className='font-semibold'>{business.business_registration_number || 'Business Registration Number'}</span>
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                        <Button 
                          variant="outlined" 
                          startIcon={<Visibility />}
                          onClick={() => handleDocumentView(
                            business.br_document_url, 
                            business.br_document_name || 'Business Registration'
                          )}
                        >
                          View
                        </Button>
                        <Button 
                          variant="outlined" 
                          startIcon={<Download />}
                          onClick={() => handleDownloadDocument(
                            business.br_document_url, 
                            business.br_document_name || 'Business Registration'
                          )}
                        >
                          Download
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography variant="h5" className="text-base-content">
          My {businesses.length > 1 ? 'Businesses' : 'Business'}
        </Typography>
        
        <Button 
          variant="outlined" 
          onClick={fetchBusinessData}
          disabled={loading}
        >
          Refresh
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }} className="bg-base-200">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="info" sx={{ mt: 2 }}>
          {error}
        </Alert>
      ) : (
        <>
          {renderBusinessTabs()}
          {renderBusinessDetails()}
        </>
      )}
      
      {renderDocumentModal()}
    </Box>
  );
};

export default MyBusiness;
