'use client';
import React, { useState, useEffect } from 'react';
import { RxReload } from 'react-icons/rx';
import { motion } from 'framer-motion';
import { MdOutlinePayments } from 'react-icons/md';
import { FaMoneyBillWave } from 'react-icons/fa';

const ManageBillPayment = () => {
  const [balance, setBalance] = useState(null);
  const [billHistory, setBillHistory] = useState(null);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [loadingBillHistory, setLoadingBillHistory] = useState(false);
  const [paymentAmounts, setPaymentAmounts] = useState({});

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const fetchBalance = async () => {
    setLoadingBalance(true);
    try {
      const slt_token = getCookie('slt_token');
      const slt_connection = getCookie('slt_connection');
      let token = slt_token ? slt_token : "null";
      let telephoneNo = slt_connection ? slt_connection : "null";
      const accountNo = "0046449486"; // This should be dynamically set based on user data

      const response = await fetch(
        `https://omniscapp.slt.lk/slt/ext/api/AccountOMNI/BillPaymentRequest?telephoneNo=${telephoneNo}&accountNo=${accountNo}`,
        {
          cache: 'default',
          credentials: 'include',
          headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'bearer ' + token,
            'User-Agent': 'Mozilla/5.0',
            'X-IBM-Client-Id': 'b7402e9d66808f762ccedbe42c20668e',
          },
          method: 'GET',
          mode: 'cors',
          redirect: 'follow',
          referrer: 'https://myslt.slt.lk/',
          referrerPolicy: 'strict-origin-when-cross-origin',
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setBalance(data);
      console.log('Balance data:', data);
    } catch (error) {
      console.error('Balance fetch error:', error);
      setBalance(null);
    } finally {
      setLoadingBalance(false);
    }
  };

  const fetchBillHistory = async () => {
    setLoadingBillHistory(true);
    try {
      const slt_token = getCookie('slt_token');
      const slt_connection = getCookie('slt_connection');
      let token = slt_token ? slt_token : "null";
      let telephoneNo = slt_connection ? slt_connection : "null";
      const accountNo = "0046449486"; // This should be dynamically set based on user data

      const response = await fetch(
        `https://omniscapp.slt.lk/slt/ext/api/AccountOMNI/BillHistoryRequestV2?telephoneNo=${telephoneNo}&accountNo=${accountNo}`,
        {
          cache: 'default',
          credentials: 'include',
          headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'bearer ' + token,
            'User-Agent': 'Mozilla/5.0',
            'X-IBM-Client-Id': 'b7402e9d66808f762ccedbe42c20668e',
          },
          method: 'GET',
          mode: 'cors',
          redirect: 'follow',
          referrer: 'https://myslt.slt.lk/',
          referrerPolicy: 'strict-origin-when-cross-origin',
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setBillHistory(data);
      console.log('Bill history data:', data);
    } catch (error) {
      console.error('Bill history fetch error:', error);
      setBillHistory(null);
    } finally {
      setLoadingBillHistory(false);
    }
  };

  const handleAmountChange = (billIndex, value) => {
    // Convert the input value to a number if it's not empty, otherwise use 0
    const numberValue = value === '' ? 0 : Number(value);
    setPaymentAmounts({
      ...paymentAmounts,
      [billIndex]: numberValue
    });
  };

  useEffect(() => {
    fetchBalance();
    fetchBillHistory();
  }, []);

  useEffect(() => {
    // Initialize payment amounts with outstanding balances when balance data is loaded
    if (balance?.dataBundle?.listofbillingInquiryType?.length > 0) {
      const initialAmounts = {};
      balance.dataBundle.listofbillingInquiryType.forEach((bill, index) => {
        // Just use the outstanding balance value directly
        initialAmounts[index] = bill.outstandingBalance > 0 ? bill.outstandingBalance : 0;
        setPaymentAmounts(initialAmounts[0]);
      });
    
    }
  }, [balance]);

  const renderLoading = (message) => (
    <div className="flex flex-col items-center justify-center p-10">
      <motion.div
        initial={{ scale: 0.8, opacity: 0.5 }}
        animate={{ scale: 1.2, opacity: 1 }}
        transition={{ 
          repeat: Infinity, 
          repeatType: "reverse", 
          duration: 0.8 
        }}
        className="text-primary text-5xl mb-3"
      >
        <MdOutlinePayments />
      </motion.div>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="text-lg font-medium text-gray-600 dark:text-gray-300"
      >
        {message}
      </motion.p>
    </div>
  );

  return (
    <div className="container my-5">
      {/* Balance Section */}
      <div className="mb-8">
        {loadingBalance ? (
          renderLoading("Loading bill payment data...")
        ) : balance && balance.isSuccess !== undefined ? (
          <div className="bg-base-200 p-10 flex shadow-lg card">
            <div className="">
              <h5 className="text-green-500 card-title">
                {balance.isSuccess ? 'Success' : 'Error'} | Bill Payment
                <span className="cursor-pointer ml-2" onClick={fetchBalance}>
                  <RxReload />
                </span>
              </h5>

              {balance.errorMessege && (
                <p className="text-danger">{balance.errorMessege}</p>
              )}

              {balance.dataBundle?.listofbillingInquiryType?.length > 0 ? (
                <div className="my-5 w-full flex flex-wrap justify-center md:justify-center">
                  {balance.dataBundle.listofbillingInquiryType.map(
                    (bill, index) => (
                      <div key={index} className="card bg-base-300 w-full md:w-max mb-3 shadow-lg hover:scale-105 transition-transform duration-300">
                        <div className="card-body">
                          <h6 className="card-title text-red-600 mb-5">Bill for {bill.lastBillDate}</h6>
                          <p>
                            <strong>Bill Amount:</strong> {bill.billAmount}
                          </p>
                          <p>
                            <strong>Last Bill Date:</strong> {bill.lastBillDate}
                          </p>
                          <p>
                            <strong>Payment Due Date:</strong> {bill.paymentDueDate}
                          </p>
                          <p>
                            <strong>Last Payment Date:</strong> {bill.lastPaymentDate}
                          </p>
                          <p>
                            <strong>Last Payment Amount:</strong> {bill.lastPaymentAmount}
                          </p>
                          <p className={`p-3 rounded-xl mt-5 ${bill.outstandingBalance <= 0 ? "bg-green-800 text-white" : "bg-red-800 text-white"}`}>
                            <strong>Outstanding Balance:</strong> {bill.outstandingBalance} 
                          </p>
                          
                          {bill.outstandingBalance  && (
                            <div className="mt-6 bg-gradient-to-r from-blue-100 to-blue-50 p-4 rounded-xl shadow-md border border-blue-200">
                              <h6 className="font-bold text-blue-800 mb-3 flex items-center">
                                <FaMoneyBillWave className="mr-2" /> Pay Your Bill
                              </h6>
                              <form 
                                action="https://billpay.slt.lk/confirm.php" 
                                method="POST" 
                                target="_blank"
                                className="flex flex-col gap-2"
                              >
                                <input 
                                  hidden 
                                  name="EventSource" 
                                  defaultValue={getCookie('slt_connection') || ''} 
                                />
                                <div className="flex flex-col">
                                  <label className="text-sm text-gray-600 mb-1">Payment Amount:</label>
                                  <input 
                                    type="number" 
                                    name="vpc_Amount" 
                                    value={paymentAmounts[index] !== undefined ? paymentAmounts : bill.outstandingBalance}
                                    onChange={(e) => handleAmountChange(index, e.target.value)}
                                    className="input input-bordered input-sm w-full" 
                                    
                                  />
                                </div>
                                <button 
                                  type="submit" 
                                  className="btn btn-sm btn-primary text-white mt-2 w-full flex items-center justify-center gap-2"
                                >
                                  <MdOutlinePayments /> Pay Now
                                </button>
                              </form>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <p>No payment details available.</p>
              )}
            </div>
          </div>
        ) : (
          <p className="text-danger">No bill payment data available. Please try again.</p>
        )}
      </div>

      {/* Bill History Section */}
      <div>
        {loadingBillHistory ? (
          renderLoading("Loading bill history data...")
        ) : billHistory && billHistory.isSuccess !== undefined ? (
          <div className="bg-base-200 p-10 shadow-lg card">
            <div className="">
              <h5 className="text-green-500 card-title">
                {billHistory.isSuccess ? 'Success' : 'Error'} | Bill History{' '}
                <span className="cursor-pointer" onClick={fetchBillHistory}>
                  <RxReload />
                </span>
              </h5>

              {billHistory.errorMessege && (
                <p className="text-danger">{billHistory.errorMessege}</p>
              )}

              {billHistory.dataBundle?.listofBillHistoryDetail?.length > 0 ? (
                <div className="my-5 flex flex-wrap justify-center md:justify-between">
                  {billHistory.dataBundle.listofBillHistoryDetail.map(
                    (bill, index) => (
                      <div key={index} className="card mb-3 shadow-lg w-max hover:scale-105">
                        <div className="card-body">
                          <h6 className="card-title text-red-600">Bill for {bill.billViewMonth === "NA" ? new Date(bill.billMonth).toLocaleDateString('en-CA').slice(0, 7).replace('-', '/') : bill.billViewMonth}</h6>
                          <p>
                            <strong>Bill Month:</strong> {bill.billMonth}
                          </p>
                          <p>
                            <strong>Bill Value:</strong> {bill.billValue}
                          </p>
                          <p>
                            <strong>Payments:</strong> {bill.payments}
                          </p>
                          <p>
                            <strong>Outstanding:</strong> {bill.outstanding}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <p>No bill history available.</p>
              )}
            </div>
          </div>
        ) : (
          <p className="text-danger">No bill history data available. Please try again.</p>
        )}
      </div>
    </div>
  );
};

export default ManageBillPayment;
