import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// This component acts as the opposite of ProtectedRoute
// If a user is already logged in, they will be redirected to the dashboard
// instead of showing login or registration pages
const RedirectAuthenticatedUser = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  
  // If a location state with a 'from' property exists, redirect back to that URL
  // Otherwise, redirect to dashboard
  const from = location.state?.from?.pathname || '/dashboard';
  
  if (currentUser) {
    // User is already logged in, redirect to dashboard or previous location
    return <Navigate to={from} replace />;
  }

  // User is not logged in, render children (login/register pages)
  return children;
};

export default RedirectAuthenticatedUser;
