import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebaseConfig';
import { collection, query, where, getDocs } from "firebase/firestore";
import { motion } from 'framer-motion';
import { FaPhone, FaLink, FaCheckCircle, FaUser, FaUserShield, FaCopy, FaMale, FaFemale, FaUserTie } from 'react-icons/fa';
import { MdAccountCircle, MdEmail, MdPublic, MdEmojiPeople } from 'react-icons/md';
import { GiAlienStare } from 'react-icons/gi';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

const Users = () => {
    const { username } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [metaReady, setMetaReady] = useState(false);
    const navigate = useNavigate();

    const DEFAULT_META = {
        title: 'User Profile | officialkasun.com',
        description: 'View user profile on officialkasun.com',
        image: 'https://officialkasun.com/logo.png',
    };

    const formatRole = (role) => {
        // Check if role is a string before attempting to use string methods
        if (typeof role !== 'string') {
            return String(role || ''); // Convert to string or return empty string if falsy
        }
        
        return role.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    // Add a helper function to consistently render gender-specific icons
    const renderGenderIcon = (gender, size = "text-5xl") => {
        if (!gender) return <GiAlienStare className={size} />;
        
        const genderLower = gender.toLowerCase();
        if (genderLower === 'male') return <FaMale className={size} />;
        if (genderLower === 'female') return <FaFemale className={size} />;
        return <FaUserTie className={size} />; // Business icon for "other"
    };

    // New function to check if viewing own profile
    useEffect(() => {
        const redirectIfOwnProfile = async () => {
            // Only proceed if auth is initialized and has current user
            if (auth && auth.currentUser) {
                try {

                 
                    
                    // Get the current user's data from Firestore
                    const currentUserQuery = query(
                        collection(firestore, "users"),
                        where("uid", "==", auth.currentUser.uid)
                    );
                    const currentUserSnapshot = await getDocs(currentUserQuery);
                    
                    // If we found the user and their username matches the URL parameter
                    if (!currentUserSnapshot.empty) {
                        const currentUserData = currentUserSnapshot.docs[0].data();
                        if (currentUserData.username === username) {
                            navigate('/myaccount', { replace: true });
                            return true; // Return true to indicate redirection happened
                        }
                    }
                } catch (error) {
                    console.error("Error checking if viewing own profile:", error);
                }
            }
            return false; // No redirection happened
        };

        // Main fetch function
        const fetchUser = async () => {
            try {
                // First check if this is the user's own profile
                const redirected = await redirectIfOwnProfile();
                
                // If redirected, don't continue with fetching
                if (redirected) return;
                
                const q = query(collection(firestore, "users"), where("username", "==", username));
                const querySnapshot = await getDocs(q);
                
                if (querySnapshot.empty) {
                    setError("User not found");
                    setLoading(false);
                    setMetaReady(true);
                    return;
                }

                const userData = querySnapshot.docs[0].data();
                if (!userData.show_public) {
                    setError("This profile is private");
                    setLoading(false);
                    setMetaReady(true);
                    return;
                }

                else if (!userData.is_active) {
                    setError("This profile has been deactivated");
                    setLoading(false);
                    setMetaReady(true);
                    return;
                }

                setUser(userData);
                // Add a slight delay to ensure meta tags are updated after data is loaded
                const timeoutId = setTimeout(() => {
                    setMetaReady(true);
                }, 2000); // 2 second delay
                setLoading(false);
                
                return () => clearTimeout(timeoutId);
            } catch (error) {
                console.error("Error fetching user:", error);
                setError("Error loading user profile");
                setLoading(false);
                setMetaReady(true);
            }
        };

        fetchUser();
    }, [username, navigate]);

    const copyProfileLink = () => {
        const link = `https://officialkasun.com/u/${username}`;
        navigator.clipboard.writeText(link)
            .then(() => toast.success('Profile link copied to clipboard!'))
            .catch(() => toast.error('Failed to copy link'));
    };

    const getMetaImage = () => {
        if (!metaReady) return DEFAULT_META.image;
        return user?.profilePicture || DEFAULT_META.image;
    };

    const getMetaTitle = () => {
        if (!metaReady) return DEFAULT_META.title;
        return user ? `${user.name} (@${user.username}) | officialkasun.com` : DEFAULT_META.title;
    };

    const getMetaDescription = () => {
        if (!metaReady) return DEFAULT_META.description;
        return user ? `View ${user.name}'s profile on officialkasun.com` : DEFAULT_META.description;
    };

    if (loading) {
        return <div className="flex justify-center items-center min-h-screen">
            <span className="loading loading-spinner loading-lg"></span>
        </div>;
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="alert alert-error text-white text-xl font-semibold w-max">
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>{error}</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>{getMetaTitle()}</title>
                <meta name="description" content={getMetaDescription()} />

                {/* OpenGraph Meta Tags */}
                <meta property="og:title" content={getMetaTitle()} />
                <meta property="og:description" content={getMetaDescription()} />
                <meta property="og:image" content={getMetaImage()} />
                <meta property="og:url" content={`https://officialkasun.com/u/${username}`} />
                <meta property="og:type" content="profile" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={getMetaTitle()} />
                <meta name="twitter:description" content={getMetaDescription()} />
                <meta name="twitter:image" content={getMetaImage()} />
            </Helmet>
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="min-h-screen bg-base-200"
            >
                {/* Cover Photo Section */}
                <div className="relative">
                    <div className="h-48 sm:h-64 md:h-80 bg-gradient-to-r from-blue-500 to-indigo-600 overflow-hidden rounded-b-lg shadow-lg">
                        {user.coverPhoto && (
                            <img 
                                src={user.coverPhoto}
                                alt="Cover" 
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.style.display = 'none';
                                }}
                            />
                        )}
                    </div>
                    
                    {/* Profile Picture Overlay */}
                    <div className="absolute -bottom-16 left-4 sm:left-8 md:left-12">
                        <div className="relative">
                            <div className="w-28 h-28 sm:w-36 sm:h-36 rounded-full border-4 border-base-100 overflow-hidden bg-base-100">
                                {user.profilePicture ? (
                                    <img 
                                        src={user.profilePicture} 
                                        alt="Profile" 
                                        className="w-full h-full object-cover"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.style.display = 'none';
                                            e.target.nextSibling.style.display = 'flex';
                                        }}
                                    />
                                ) : null}
                                <div className={`w-full h-full rounded-full bg-neutral-focus text-neutral-content ${user.profilePicture ? 'hidden' : 'flex'} items-center justify-center`}>
                                    {renderGenderIcon(user.gender)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Profile Content */}
                <div className="container mx-auto px-4 pt-20 pb-10">
                    {/* Profile Header */}
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-end mb-6">
                        <div>
                            <h1 className="text-2xl font-bold flex items-center gap-2">
                                {user.name}
                                {user.is_verified && (
                                    <FaCheckCircle className="text-blue-500 text-lg" title="Verified Account" />
                                )}
                            </h1>
                            <div className="flex items-center gap-2">
                                <p className="text-base-content text-opacity-70">@{user.username}</p>
                                
                            </div>
                        </div>
                        
                        <div className="flex items-center gap-2 mt-2 md:mt-0">
                            <div className="form-control">
                                <div className="flex items-center gap-2">
                                    {user.show_public && (
                                        <span className="label-text flex items-center gap-1">
                                            <MdPublic className="text-base-content" /> 
                                            Public Profile
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Profile Navigation Tabs */}
                    <div className="tabs tabs-bordered mb-6">
                        <a className="tab tab-bordered tab-active">Profile</a>
                        {user.bio && <a className="tab tab-bordered">About</a>}
                    </div>

                    {/* Profile Content */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {/* Left Column - About */}
                        <div className="md:col-span-1">
                            <div className="card bg-base-100 shadow-md">
                                <div className="card-body">
                                    <h2 className="card-title flex items-center gap-2">
                                        <FaUser /> About
                                    </h2>
                                    
                                    <div className="divider my-2"></div>
                                    
                                    {/* Email */}
                                    {user.email && (
                                        <div className="flex items-center gap-3 mb-2">
                                            <MdEmail className="text-base-content text-opacity-70" />
                                            <div className="flex-1 min-w-0">
                                                <p className="text-sm text-base-content text-opacity-70">Email</p>
                                                <div className="flex items-center justify-between gap-1">
                                                    <p className="text-base-content truncate">{user.email}</p>
                                                    <button 
                                                        className="btn btn-ghost btn-xs"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(user.email);
                                                            toast.success('Email copied to clipboard');
                                                        }}
                                                    >
                                                        <FaCopy />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {/* Phone */}
                                    {user.phone && (
                                        <div className="flex items-center gap-3 mb-2">
                                            <FaPhone className="text-base-content text-opacity-70" />
                                            <div className="flex-1 min-w-0">
                                                <p className="text-sm text-base-content text-opacity-70">Phone</p>
                                                <p className="text-base-content">{user.phone}</p>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {/* Gender */}
                                    {user.gender && (
                                        <div className="flex items-center gap-3 mb-2">
                                            <div className="text-base-content text-opacity-70">
                                                {renderGenderIcon(user.gender, "text-lg")}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <p className="text-sm text-base-content text-opacity-70">Gender</p>
                                                <p className="text-base-content capitalize">{user.gender}</p>
                                            </div>
                                        </div>
                                    )}
                                   
                                    
                                    {/* Bio/Description */}
                                    {user.bio && (
                                        <div className="mt-4">
                                            <p className="text-sm text-base-content text-opacity-70 mb-1">Bio</p>
                                            <p className="text-base-content">{user.bio}</p>
                                        </div>
                                    )}

                                    {/* Website */}
                                    {user.website && (
                                        <div className="mt-4">
                                            <p className="text-sm text-base-content text-opacity-70 mb-1">Website</p>
                                            <a 
                                                href={user.website}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-primary hover:underline break-words"
                                            >
                                                {user.website}
                                            </a>
                                        </div>
                                    )}

                                    {/* Actions */}
                                    <div className="mt-6 flex flex-wrap gap-2">
                                        <button 
                                            onClick={copyProfileLink}
                                            className="btn btn-primary btn-sm gap-2"
                                        >
                                            <FaLink />
                                            Share Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Right Column - Additional Info */}
                        <div className="md:col-span-2">
                            {/* About section */}
                            {user.about && (
                                <div className="card bg-base-100 shadow-md mb-6">
                                    <div className="card-body">
                                        <h2 className="card-title">About</h2>
                                        <div className="divider my-2"></div>
                                        <p>{user.about}</p>
                                    </div>
                                </div>
                            )}
                            
                            {/* Achievements section */}
                            {user.achievements && (
                                <div className="card bg-base-100 shadow-md mb-6">
                                    <div className="card-body">
                                        <h2 className="card-title">Achievements</h2>
                                        <div className="divider my-2"></div>
                                        <p>{user.achievements}</p>
                                    </div>
                                </div>
                            )}
                            
                            {/* Skills section */}
                            {user.skills && user.skills.length > 0 && (
                                <div className="card bg-base-100 shadow-md mb-6">
                                    <div className="card-body">
                                        <h2 className="card-title">Skills</h2>
                                        <div className="divider my-2"></div>
                                        <div className="flex flex-wrap gap-2">
                                            {user.skills.map((skill, index) => (
                                                <div key={index} className="badge badge-outline">{skill}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default Users;