import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebaseConfig';
import { FaExclamationTriangle, FaSearch, FaCcVisa, FaCcMastercard, FaCcAmex, FaMobileAlt, FaQrcode, FaShare, FaTimes } from 'react-icons/fa';
import { useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import StorageIcon from '@mui/icons-material/Storage';
import PaymentIcon from '@mui/icons-material/Payment';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/Paid';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { motion } from 'framer-motion';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { QRCodeSVG } from 'qrcode.react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const PayTrack = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [payment, setPayment] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [hasTracked, setHasTracked] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);

  const STATUS_MAPPING = {
    // PayHere statuses
    '2': { status: 'Success', color: 'bg-green-100 text-green-800', border: 'border-green-300', chipColor: 'success' },
    '0': { status: 'Pending', color: 'bg-yellow-100 text-yellow-800', border: 'border-yellow-300', chipColor: 'warning' },
    '-1': { status: 'Canceled', color: 'bg-gray-100 text-gray-800', border: 'border-gray-300', chipColor: 'default' },
    '-2': { status: 'Failed', color: 'bg-red-100 text-red-800', border: 'border-red-300', chipColor: 'error' },
    '-3': { status: 'Chargedback', color: 'bg-purple-100 text-purple-800', border: 'border-purple-300', chipColor: 'secondary' },
    // iPay statuses
    'A': { status: 'Success', color: 'bg-green-100 text-green-800', border: 'border-green-300', chipColor: 'success' },
    'P': { status: 'Pending', color: 'bg-yellow-100 text-yellow-800', border: 'border-yellow-300', chipColor: 'warning' },
    'C': { status: 'Canceled', color: 'bg-gray-100 text-gray-800', border: 'border-gray-300', chipColor: 'default' },
    'F': { status: 'Failed', color: 'bg-red-100 text-red-800', border: 'border-red-300', chipColor: 'error' }
  };

  const PAYMENT_METHOD_MAPPING = {
    'VISA': { icon: FaCcVisa, type: 'Card Payment' },
    'MASTER': { icon: FaCcMastercard, type: 'Card Payment' },
    'AMEX': { icon: FaCcAmex, type: 'Card Payment' },
    'EZCASH': { icon: FaMobileAlt, type: 'Mobile Payment' },
    'MCASH': { icon: FaMobileAlt, type: 'Mobile Payment' },
    'GENIE': { icon: FaQrcode, type: 'QR / APP Payment' },
    'VISHWA': { icon: FaQrcode, type: 'QR / APP Payment' },
    'PAYAPP': { icon: FaQrcode, type: 'QR / APP Payment' },
    'HNB': { icon: FaQrcode, type: 'QR / APP Payment' },
    'FRIMI': { icon: FaQrcode, type: 'QR / APP Payment' },
    // Default for iPay or unknown methods
    '': { icon: CreditCardIcon, type: 'Payment' }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser({ ...user, fullName: userSnap.data().fullName, role: userSnap.data().role });
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
      
      // Only set the search term, don't trigger search
      const orderId = searchParams.get('order_id');
      if (orderId) {
        setSearchTerm(orderId);
      }
    });

    return () => unsubscribe();
  }, [searchParams]);

  // Modify the useEffect for URL parameter
  useEffect(() => {
    const orderId = searchParams.get('order_id');
    if (orderId && !loading && user && !hasTracked) {
      setHasTracked(true);
      handleSearch(null, true); // Pass true to indicate initial load
    }
  }, [user, loading, searchParams]);

  const processPaymentData = (paymentData) => {
    // Check if it's iPay or PayHere based on provider
    if (paymentData.provider && paymentData.provider.name === 'ipay') {
      return {
        isIpay: true,
        orderId: paymentData.transaction_id,
        statusCode: paymentData.status,
        statusMessage: paymentData.status_message,
        amount: paymentData.amount,
        admin_checked: paymentData.admin_checked,
        admin_checked_at: paymentData.admin_checked_at,
        currency: paymentData.currency,
        paymentMethod: paymentData.metadata?.payment_method || '',
        timestamp: paymentData.timestamp,
        transactionRef: paymentData.provider.raw_response.transactionReference,
        cardDetails: null,
        customData: {
          item: paymentData.metadata?.custom2 || '',
          courseId: paymentData.metadata?.custom1 || ''
        }
      };
    } else {
      // Assume PayHere
      return {
        isIpay: false,
        orderId: paymentData.transaction_id || paymentData.raw_response?.order_id,
        statusCode: paymentData.status || paymentData.status_code,
        statusMessage: paymentData.status_message,
        admin_checked: paymentData.admin_checked,
        admin_checked_at: paymentData.admin_checked_at,
        amount: paymentData.amount || '',
        currency: paymentData.currency || '',
        paymentMethod: paymentData.raw_response?.method || paymentData.payment_method || '',
        timestamp: paymentData.timestamp,
        transactionRef: paymentData.payment_id || '',
        cardDetails: {
          cardNo: paymentData.raw_response?.card_no || (paymentData.provider?.card_details?.masked_number || ''),
          cardHolder: paymentData.raw_response?.card_holder_name || (paymentData.provider?.card_details?.holder_name || ''),
          cardExpiry: paymentData.raw_response?.card_expiry || (paymentData.provider?.card_details?.expiry || '')
        },
        customData: {
          item: paymentData.raw_response?.custom_2 || '',
          courseId: paymentData.raw_response?.custom_1 || ''
        }
      };
    }
  };

  const handleSearch = async (e, isInitialLoad = false) => {
    if (e) {
      e.preventDefault();
    }
    
    const orderId = isInitialLoad ? searchParams.get('order_id') : searchTerm.trim();
    
    if (!orderId) {
      toast.warn('Please enter an Order ID');
      return;
    }
  
    if (!isInitialLoad) {
      navigate(`/payments/tracker?order_id=${orderId}`);
    }
  
    setLoading(true);
    setPayment(null);
    setPaymentRequest(null);
    setError(null);
  
    try {
      const paymentsSnapshot = await getDocs(query(collection(firestore, 'payments'), where('transaction_id', '==', orderId)));
      
      if (paymentsSnapshot.empty) {
        // Try with the old field name order_id as fallback
        const paymentsSnapshotAlt = await getDocs(query(collection(firestore, 'payments'), where('order_id', '==', orderId)));
        
        if (paymentsSnapshotAlt.empty) {
          setError('No payment found with this Order ID');
          toast.error('No payment found with this Order ID');
          setLoading(false);
          return;
        }
      }
      
      const paymentDoc = paymentsSnapshot.empty ? paymentsSnapshot.docs[0] : paymentsSnapshot.docs[0];
      const paymentData = paymentDoc.data();
      
      // Process the payment data to normalize format
      const processedPayment = processPaymentData(paymentData);
      setPayment(processedPayment);

     
      

      // Fetch payment request
      const paymentRequestsSnapshot = await getDocs(query(collection(firestore, 'payment_requests'), where('order_id', '==', orderId)));
      
      if (!paymentRequestsSnapshot.empty) {
        const paymentRequest = paymentRequestsSnapshot.docs[0];
        const paymentRequestData = paymentRequest.data();
        
        // Only update if pr_updated_date doesn't exist
        if (!paymentRequestData.pr_updated_date && user) {
          let status = 'pending';
          
          if (processedPayment.isIpay) {
            if (processedPayment.statusCode === 'A') status = 'success';
            else if (processedPayment.statusCode === 'C') status = 'canceled';
            else if (processedPayment.statusCode === 'F') status = 'failed';
          } else {
            if (processedPayment.statusCode === '2') status = 'success';
            else if (processedPayment.statusCode === '-1') status = 'canceled';
            else if (processedPayment.statusCode === '-2') status = 'failed';
            else if (processedPayment.statusCode === '-3') status = 'chargedback';
          }
          
          await updateDoc(doc(firestore, 'payment_requests', paymentRequest.id), {
            status,
            pr_updated_date: new Date()
          });
          
          toast.success('Payment status updated successfully');
        }
        
        // Fetch updated payment request
        const updatedPaymentRequestDoc = await getDoc(doc(firestore, 'payment_requests', paymentRequest.id));
        setPaymentRequest(updatedPaymentRequestDoc.data());
      }

      toast.info(`Payment found with status: ${getStatusLabel(processedPayment.statusCode)}`);
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Error processing payment details');
      toast.error('Error processing payment details');
    } finally {
      setLoading(false);
    }
  };

  const getStatusLabel = (statusCode) => {
    return STATUS_MAPPING[statusCode]?.status || 'Unknown';
  };

  const getPaymentMethodIcon = (method) => {
    const methodMapping = PAYMENT_METHOD_MAPPING[method] || PAYMENT_METHOD_MAPPING[''];
    return React.createElement(methodMapping.icon, {
      className: "w-8 h-8 text-primary"
    });
  };

  const getPaymentMethodType = (method) => {
    return PAYMENT_METHOD_MAPPING[method]?.type || 'Payment';
  };

  const handleOpenQrModal = () => {
    setQrModalOpen(true);
  };

  const handleCloseQrModal = () => {
    setQrModalOpen(false);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <form onSubmit={handleSearch} className="max-w-md mx-auto mb-8">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter Order ID..."
              className="w-full px-4 py-3 pl-12 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
              disabled={loading}
            />
            <div className="absolute inset-y-0 left-3 flex items-center">
              <FaSearch className="w-5 h-5 text-gray-400" />
            </div>
            <button
              type="submit"
              className="absolute inset-y-0 right-3 px-4 flex items-center bg-blue-600 text-white rounded-lg h-10 mt-1 font-semibold hover:bg-blue-900 disabled:bg-gray-400"
              disabled={loading}
            >
              {loading ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                'Track'
              )}
            </button>
          </div>
        </form>
      </motion.div>

      {loading && (
        <div className="flex justify-center my-8">
          <span className="loading loading-spinner loading-lg text-primary"></span>
        </div>
      )}

      {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex items-center justify-center gap-2 p-4 bg-red-100 text-red-700 rounded-lg"
        >
          <FaExclamationTriangle />
          <p>{error}</p>
        </motion.div>
      )}

      {payment && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className='bg-blue-500 w-full md:w-max mx-auto p-1 rounded-lg shadow-xl '
        >
          <Card className="max-w-2xl mx-auto p-1 rounded-lg shadow-xl">
            <CardContent className='bg-base-200 p-2 rounded-lg shadow-xl'>
              <div className="flex justify-center mb-4">
                <button 
                  className='flex items-center gap-2 bg-primary text-white px-4 py-2 rounded-lg hover:bg-blue-600 font-semibold'
                  onClick={handleOpenQrModal}
                >
                  <FaQrcode />
                  QR Code
                </button>
              </div>
              <div className={`mb-6 p-4 rounded-lg ${STATUS_MAPPING[payment.statusCode].color} ${STATUS_MAPPING[payment.statusCode].border} border`}>
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-bold">Payment Status</h2>
                  <Chip 
                    label={getStatusLabel(payment.statusCode)} 
                    color={STATUS_MAPPING[payment.statusCode].chipColor}
                    variant="outlined"
                    size="medium"
                  />
                </div>
                <p className="mt-2">{payment.statusMessage}</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-base-100 p-4 rounded-lg text-base-content">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold text-orange-700 flex items-center gap-2">
                      <CalendarTodayIcon fontSize="small" />
                      Order Details
                    </h3>
                    <p className="text-lg font-medium">{payment.orderId}</p>
                    {payment.transactionRef && (
                      <p className="text-sm text-gray-600">Transaction Ref: {payment.transactionRef}</p>
                    )}
                  </div>
                  <div>
                    <h3 className="font-semibold text-orange-700 flex items-center gap-2">
                      <CreditCardIcon fontSize="small" />
                      Payment Method
                    </h3>
                    <div className="flex items-center gap-2 mt-1">
                      {payment.paymentMethod ? (
                        <>
                          {getPaymentMethodIcon(payment.paymentMethod)}
                          <div>
                            <p className="text-lg">{payment.paymentMethod || 'Not specified'}</p>
                            <p className="text-sm text-gray-600">{getPaymentMethodType(payment.paymentMethod)}</p>
                          </div>
                        </>
                      ) : (
                        <p>Provider: {payment.isIpay ? 'iPay' : 'PayHere'}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold text-orange-700 flex items-center gap-2">
                      <PaidIcon fontSize="small" />
                      Amount
                    </h3>
                    <p className="text-lg">{payment.currency} {payment.amount}</p>
                  </div>
                </div>

                <div className="space-y-4">
                  {payment.cardDetails && (payment.cardDetails.cardNo || payment.cardDetails.cardHolder) && (
                    <div>
                      <h3 className="font-semibold text-orange-700 flex items-center gap-2">
                        <CreditCardIcon fontSize="small" />
                        Card Information
                      </h3>
                      {payment.cardDetails.cardNo && <p>Card Number: {payment.cardDetails.cardNo}</p>}
                      {payment.cardDetails.cardHolder && <p>Card Holder: {payment.cardDetails.cardHolder}</p>}
                      {payment.cardDetails.cardExpiry && <p>Expiry: {payment.cardDetails.cardExpiry}</p>}
                    </div>
                  )}
                  <div>
                    <h3 className="font-semibold text-orange-700 flex items-center gap-2">
                      <CalendarTodayIcon fontSize="small" />
                      Transaction Time
                    </h3>
                    <p>{moment(payment.timestamp.toDate()).format('MMMM D, YYYY h:mm A')}</p>
                  </div>
                  {payment.customData && payment.customData.item && (
                    <div>
                      <h3 className="font-semibold text-orange-700 flex items-center gap-2">
                        <CurrencyExchangeIcon fontSize="small" />
                        Purchase Details
                      </h3>
                      <p>{payment.customData.item}</p>
                      {payment.customData.courseId && <p className="text-sm text-gray-600">Course: {payment.customData.courseId}</p>}
                    </div>
                  )}
                </div>
              </div>

              {paymentRequest && (
                <motion.div 
                  className="mt-8 w-full"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.5 }}
                >
                  <h3 className="font-semibold text-orange-700 mb-4 w-full justify-center items-center text-center text-xl">Timeline</h3>
                  <Timeline position="alternate" className='bg-base-300 w-full rounded-lg p-4'>
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="error"
                      >
                        {moment(paymentRequest.timestamp.toDate()).format('MMMM D, YYYY h:mm:ss A')}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color='error'>
                          <PaymentIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span" color='error'>
                          Payment Created
                        </Typography>
                        <Typography><span className='text-base-content'>for</span> <span className='font-semibold text-yellow-600'>{paymentRequest.items}</span></Typography>
                      </TimelineContent>
                    </TimelineItem>
                    
                    {paymentRequest.pr_updated_date && (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="primary"
                        >
                          {moment(paymentRequest.pr_updated_date.toDate()).format('MMMM D, YYYY h:mm:ss A')}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color='primary'>
                            <StorageIcon/>
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span" color='primary'>
                            Server Side Validation
                          </Typography>
                          <Typography className='text-blue-400'>Status: {paymentRequest.status}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    )}

                    {(payment.admin_checked && payment.admin_checked_at) && (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="warning"
                        >
                          {moment(payment.admin_checked_at.toDate()).format('MMMM D, YYYY h:mm:ss A')}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="warning">
                            <VerifiedUserIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span" color='warning'>
                            Server Checked
                          </Typography>
                          <Typography className='italic text-base-content'>by Admin</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    )}

                    {(paymentRequest.admin_checked && paymentRequest.admin_checked_at) && (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="success"
                        >
                          {moment(paymentRequest.admin_checked_at.toDate()).format('MMMM D, YYYY h:mm:ss A')}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="success">
                            <TaskAltIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span" color='success'>
                            Payment Checked
                          </Typography>
                          <Typography className='italic text-base-content'>by Admin</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    )}
                  </Timeline>
                </motion.div>
              )}
            </CardContent>
          </Card>
        </motion.div>
      )}

      {/* QR Code Modal */}
      <Dialog
        open={qrModalOpen}
        onClose={handleCloseQrModal}
        aria-labelledby="qr-code-dialog-title"
      >
        <DialogTitle id="qr-code-dialog-title">
          <div className="flex justify-between items-center">
            <span>Scan this QR Code</span>
            <IconButton aria-label="close" onClick={handleCloseQrModal} size="small">
              <FaTimes />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center p-4">
            <QRCodeSVG 
              value={window.location.href}
              size={200}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"H"}
              includeMargin={true}
            />
            <p className="mt-4 text-center text-sm text-gray-600">
              Scan to open this page on another device
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQrModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PayTrack;