import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
    
  const { currentUser, logout } = useAuth();
  const location = useLocation();

  // Function to get cookie by name
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  // Function to delete all related cookies
  const deleteAllCookies = () => {
    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "slt_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "slt_connection=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  useEffect(() => {
    // Check if user is authenticated but username cookie is missing
    if (currentUser && !getCookie('username')) {
      console.log('Username cookie missing but user still authenticated. Logging out...');
      deleteAllCookies(); // Clear all cookies
      logout(); // Log the user out
    }
  }, [currentUser, logout]);

  if (!currentUser) {
    // Redirect to login page with the full current URL as a query parameter
    const currentFullUrl = encodeURIComponent(window.location.href);
    return <Navigate to={`/login?redirect=${currentFullUrl}`} replace />;
  }

  return children;
};

export default ProtectedRoute;
