import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import { auth, firestore, storage } from '../../firebaseConfig';
import { doc, getDoc, updateDoc, query, collection, where, getDocs } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaUser, FaPhone, FaUserShield, FaCopy, FaEdit, FaCamera, FaCheckCircle, FaLink, FaTrash } from 'react-icons/fa';
import { MdPublic, MdVisibility, MdAccountCircle, MdRestartAlt, MdEmail, MdFilterAlt, MdCrop, MdTextFields } from 'react-icons/md';
import Cookies from 'js-cookie';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageFilters from 'react-image-filters';

const MyAccountPage = () => {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [showPublic, setShowPublic] = useState(false);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingUserData, setLoadingUserData] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    // Photo related states
    const [uploadingProfile, setUploadingProfile] = useState(false);
    const [uploadingCover, setUploadingCover] = useState(false);
    const [photoUploadProgress, setPhotoUploadProgress] = useState(0);
    const [photoEditMode, setPhotoEditMode] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(null);
    const [currentPhotoType, setCurrentPhotoType] = useState(null); // 'profile' or 'cover'
    const [crop, setCrop] = useState({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [photoText, setPhotoText] = useState('');
    const [filterType, setFilterType] = useState('none');
    const imgRef = useRef(null);
    const photoInputRef = useRef(null);
    const coverPhotoInputRef = useRef(null);

    const badWords = [
      'fuck', 'shit', 'ass', 'bitch', 'dick', 'porn', 'sexy', 'xxx',
      'bastard', 'cunt', 'damn', 'whore', 'slut', 'piss', 'bloody',
      'wanker', 'boob', 'hell', 'crap', 'idiot', 'stupid', 'dumb',
      'nude', 'nsfw', 'sex', 'porno', 'adult', 'explicit'
    ];

    const containsBadWords = (text) => {
        const lowercaseText = text.toLowerCase();
        return badWords.some(word => lowercaseText.includes(word));
    };

    const validateUsername = (username) => {
        if (username.length < 5) {
            toast.error("Username must be at least 5 characters long");
            return false;
        }
        if (containsBadWords(username)) {
            toast.error("Username contains inappropriate words");
            return false;
        }
        if (!/^[a-zA-Z0-9._]+$/.test(username)) {
            toast.error("Username can only contain letters, numbers, dots and underscores");
            return false;
        }
        return true;
    };

    const validateName = (name) => {
        if (name.length < 4) {
            toast.error("Name must be at least 4 characters long");
            return false;
        }
        if (containsBadWords(name)) {
            toast.error("Name contains inappropriate words");
            return false;
        }
        if (!/^[a-zA-Z\s]+$/.test(name)) {
            toast.error("Name can only contain letters and spaces");
            return false;
        }
        return true;
    };

    const validatePhone = (phone) => {
        const validPrefixes = ['070', '071', '072', '074', '075', '076', '077', '078'];
        return /^[0-9]{10}$/.test(phone) && validPrefixes.includes(phone.substring(0, 3));
    };

    const checkUsername = async (username) => {
        if (!validateUsername(username)) return false;
        const q = query(collection(firestore, "users"), where("username", "==", username));
        const querySnapshot = await getDocs(q);
        const isAvailable = querySnapshot.empty || username === user.username;
        if (!isAvailable) {
            toast.error("Username already taken");
        }
        return isAvailable;
    };

    const copyProfileLink = () => {
        const link = `https://officialkasun.com/u/${username}`;
        navigator.clipboard.writeText(link)
            .then(() => toast.success('Profile link copied to clipboard!'))
            .catch(() => toast.error('Failed to copy link'));
    };

    const formatRole = (role) => {
        return role.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const isUsernameValid = (username) => {
        return username && 
               username.length >= 5 && 
               !containsBadWords(username) && 
               /^[a-zA-Z0-9._]+$/.test(username);
    };

    // State to track active edit tab
    const [activeEditTab, setActiveEditTab] = useState('crop');

    // Define text style options
    const [textColor, setTextColor] = useState('#ffffff');
    const [textShadow, setTextShadow] = useState(true);
    const [textPosition, setTextPosition] = useState('top-left');

    // Create preview image with text overlay
    const getTextOverlayStyles = () => {
        let position = {};
        switch(textPosition) {
            case 'top-left':
                position = { top: '10px', left: '10px' };
                break;
            case 'top-center':
                position = { top: '10px', left: '50%', transform: 'translateX(-50%)' };
                break;
            case 'top-right':
                position = { top: '10px', right: '10px' };
                break;
            case 'center':
                position = { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
                break;
            case 'bottom-left':
                position = { bottom: '10px', left: '10px' };
                break;
            case 'bottom-center':
                position = { bottom: '10px', left: '50%', transform: 'translateX(-50%)' };
                break;
            case 'bottom-right':
                position = { bottom: '10px', right: '10px' };
                break;
            default:
                position = { top: '10px', left: '10px' };
        }
        
        return {
            color: textColor,
            textShadow: textShadow ? '1px 1px 2px rgba(0,0,0,0.8)' : 'none',
            position: 'absolute',
            ...position,
            fontWeight: 'bold',
            fontSize: '1.25rem',
            maxWidth: '90%',
            wordBreak: 'break-word',
            zIndex: 10
        };
    };

    // Reset image edit modal when closed
    const resetImageEditModal = () => {
        setPhotoEditMode(false);
        setCurrentPhoto(null);
        setCompletedCrop(null);
        setCrop({ 
            unit: '%', 
            width: 100, 
            height: 100, 
            x: 0, 
            y: 0 
        });
        setFilterType('none');
        setPhotoText('');
        setTextColor('#ffffff');
        setTextPosition('top-left');
    };

    // For ReactCrop
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef(null);
    
    // When the image is loaded in crop view
    const onImageLoad = (image) => {
        imgRef.current = image;
        return false; // Return false to prevent completion right away
    };

    // Photo handling functions
    const handlePhotoChange = (e, type) => {
        const file = e.target.files[0];
        if (!file) return;

        // Check file size (max 5MB before compression)
        if (file.size > 5 * 1024 * 1024) {
            toast.error("File is too large. Maximum size is 5MB.");
            return;
        }

        // Check file type
        if (!file.type.match('image.*')) {
            toast.error("Please select an image file.");
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            // Set both the currentPhoto for general use and imgSrc specifically for ReactCrop
            setCurrentPhoto(reader.result);
            setImgSrc(reader.result);
            
            setCurrentPhotoType(type);
            setPhotoEditMode(true);
            setActiveEditTab('crop');
            
            // Set initial crop based on photo type
            if (type === 'profile') {
                setCrop({ 
                    unit: '%', 
                    width: 80, 
                    height: 80, 
                    x: 10, 
                    y: 10,
                    aspect: 1  // Square aspect ratio for profile pics
                });
            } else {
                // Cover photo starts with a landscape crop
                setCrop({ 
                    unit: '%', 
                    width: 100, 
                    height: 50, 
                    x: 0, 
                    y: 25 
                });
            }
            
            setFilterType('none');
            setPhotoText('');
        };
        reader.readAsDataURL(file);
    };

    // Compress image to be under 0.5MB
    const compressImage = (dataUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;
                
                // Calculate new dimensions while maintaining aspect ratio
                const MAX_WIDTH = 1200;
                const MAX_HEIGHT = 1200;
                
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                
                // Add text if provided
                if (photoText) {
                    ctx.font = '24px Arial';
                    ctx.fillStyle = 'white';
                    ctx.strokeStyle = 'black';
                    ctx.lineWidth = 2;
                    ctx.strokeText(photoText, 20, 40);
                    ctx.fillText(photoText, 20, 40);
                }

                // Try compression at different quality levels
                let quality = 0.9;
                let result = canvas.toDataURL('image/jpeg', quality);
                let size = new Blob([result]).size;
                
                // Continue lowering quality until size is under 0.5MB
                while (size > 0.5 * 1024 * 1024 && quality > 0.1) {
                    quality -= 0.1;
                    result = canvas.toDataURL('image/jpeg', quality);
                    size = new Blob([result]).size;
                }
                
                resolve(result);
            };
            img.src = dataUrl;
        });
    };

    // Fixed applyCrop function that properly handles the image
    const applyCrop = () => {
        if (!imgRef.current || !completedCrop?.width || !completedCrop?.height) {
            console.log("Cannot apply crop - missing reference or crop dimensions");
            return null;
        }

        const image = imgRef.current;
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        
        const cropWidth = completedCrop.width * scaleX;
        const cropHeight = completedCrop.height * scaleY;
        
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        
        const ctx = canvas.getContext('2d');
        
        // Apply circular mask for profile photos if needed
        if (currentPhotoType === 'profile' && cropWidth === cropHeight) {
            ctx.beginPath();
            ctx.arc(
                cropWidth / 2, 
                cropHeight / 2, 
                cropWidth / 2, 
                0, 
                2 * Math.PI
            );
            ctx.clip();
        }
        
        // Draw the cropped image
        ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            cropWidth,
            cropHeight,
            0,
            0,
            cropWidth,
            cropHeight
        );
        
        // For debugging
        console.log("Created cropped image");
        
        return canvas.toDataURL('image/jpeg');
    };

    // Apply filters to the image
    const applyFilter = (image, filter) => {
        return new Promise((resolve) => {
            if (filter === 'none') {
                resolve(image);
                return;
            }
            
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                
                // Draw original image
                ctx.drawImage(img, 0, 0);
                
                // Apply filter
                switch (filter) {
                    case 'grayscale':
                        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                        const data = imageData.data;
                        for (let i = 0; i < data.length; i += 4) {
                            const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                            data[i] = avg; // red
                            data[i + 1] = avg; // green
                            data[i + 2] = avg; // blue
                        }
                        ctx.putImageData(imageData, 0, 0);
                        break;
                    case 'sepia':
                        ctx.filter = 'sepia(100%)';
                        ctx.drawImage(img, 0, 0);
                        break;
                    case 'blur':
                        ctx.filter = 'blur(3px)';
                        ctx.drawImage(img, 0, 0);
                        break;
                    case 'brightness':
                        ctx.filter = 'brightness(150%)';
                        ctx.drawImage(img, 0, 0);
                        break;
                    case 'contrast':
                        ctx.filter = 'contrast(200%)';
                        ctx.drawImage(img, 0, 0);
                        break;
                    default:
                        break;
                }
                
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.src = image;
        });
    };

    // Upload the edited image
    const uploadPhoto = async () => {
        try {
            console.log("Starting photo upload process");
            if (!user || !currentPhoto) {
                console.log("Missing user or photo");
                return;
            }
            
            let finalImage = currentPhoto;
            console.log("Initial image set");
            
            // Apply crop if available
            if (completedCrop && completedCrop.width && completedCrop.height) {
                console.log("Applying crop...");
                const croppedImage = applyCrop();
                if (croppedImage) {
                    console.log("Crop applied successfully");
                    finalImage = croppedImage;
                } else {
                    console.log("Failed to apply crop");
                }
            } else {
                console.log("No crop to apply or crop dimensions missing");
            }
            
            // Apply filter if selected
            if (filterType !== 'none') {
                console.log("Applying filter:", filterType);
                try {
                    finalImage = await applyFilter(finalImage, filterType);
                    console.log("Filter applied successfully");
                } catch (error) {
                    console.error("Error applying filter:", error);
                }
            }
            
            // Add text if provided
            if (photoText) {
                console.log("Adding text overlay...");
                try {
                    finalImage = await applyText(finalImage, photoText);
                    console.log("Text added successfully");
                } catch (error) {
                    console.error("Error adding text:", error);
                }
            }
            
            console.log("Processing complete, compressing image...");
            // Compress the image
            finalImage = await compressImage(finalImage);
            console.log("Image compressed");
            
            const uploadType = currentPhotoType === 'profile' ? 'profile_photos' : 'cover_photos';
            setUploadingProfile(currentPhotoType === 'profile');
            setUploadingCover(currentPhotoType === 'cover');
            setPhotoUploadProgress(0);
            
            // Convert base64 to blob
            const response = await fetch(finalImage);
            const blob = await response.blob();
            
            // Create unique filename
            const fileName = `${user.uid}_${Date.now()}.jpg`;
            const storageRef = ref(storage, `${uploadType}/${fileName}`);
            
            // Start upload
            console.log("Starting file upload to Firebase...");
            const uploadTask = uploadBytesResumable(storageRef, blob);
            
            uploadTask.on('state_changed', 
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setPhotoUploadProgress(progress);
                },
                (error) => {
                    console.error("Upload error:", error);
                    toast.error("Upload failed. Please try again.");
                    setUploadingProfile(false);
                    setUploadingCover(false);
                },
                async () => {
                    // Upload complete, get download URL
                    console.log("Upload complete, getting download URL");
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    
                    // Update user database record
                    const fieldToUpdate = currentPhotoType === 'profile' ? 'profilePicture' : 'coverPhoto';
                    
                    // Delete old photo if exists
                    await deleteOldPhoto(currentPhotoType);
                    
                    // Update user document
                    await updateDoc(doc(firestore, 'users', user.uid), { 
                        [fieldToUpdate]: downloadURL
                    });
                    
                    // Update local state
                    setUser(prev => ({ ...prev, [fieldToUpdate]: downloadURL }));
                    
                    toast.success(`${currentPhotoType === 'profile' ? 'Profile' : 'Cover'} photo updated successfully`);
                    setUploadingProfile(false);
                    setUploadingCover(false);
                    resetImageEditModal();
                }
            );
        } catch (error) {
            console.error("Upload process error:", error);
            toast.error("An error occurred during upload.");
            setUploadingProfile(false);
            setUploadingCover(false);
        }
    };

    // Apply text to the image
    const applyText = async (image, text) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                
                // Draw original image
                ctx.drawImage(img, 0, 0);
                
                // Configure text style
                ctx.font = 'bold 24px Arial';
                ctx.fillStyle = textColor;
                
                // Add text shadow if enabled
                if (textShadow) {
                    ctx.shadowColor = 'rgba(0, 0, 0, 0.8)';
                    ctx.shadowBlur = 4;
                    ctx.shadowOffsetX = 2;
                    ctx.shadowOffsetY = 2;
                }
                
                // Position text
                let x = 20;
                let y = 40;
                
                switch(textPosition) {
                    case 'top-center':
                        x = canvas.width / 2;
                        y = 40;
                        ctx.textAlign = 'center';
                        break;
                    case 'top-right':
                        x = canvas.width - 20;
                        y = 40;
                        ctx.textAlign = 'right';
                        break;
                    case 'center':
                        x = canvas.width / 2;
                        y = canvas.height / 2;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        break;
                    case 'bottom-left':
                        x = 20;
                        y = canvas.height - 20;
                        ctx.textBaseline = 'bottom';
                        break;
                    case 'bottom-center':
                        x = canvas.width / 2;
                        y = canvas.height - 20;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
                        break;
                    case 'bottom-right':
                        x = canvas.width - 20;
                        y = canvas.height - 20;
                        ctx.textAlign = 'right';
                        ctx.textBaseline = 'bottom';
                        break;
                    default: // top-left
                        break;
                }
                
                // Add text
                ctx.fillText(text, x, y);
                
                // Reset shadow
                ctx.shadowColor = 'transparent';
                
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.src = image;
        });
    };

    // Delete the old photo from storage if it exists
    const deleteOldPhoto = async (type) => {
        try {
            const fieldToCheck = type === 'profile' ? 'profilePicture' : 'coverPhoto';
            const currentURL = user[fieldToCheck];
            
            if (!currentURL) return;
            
            // Extract folder name based on type
            const folderName = type === 'profile' ? 'profile_photos' : 'cover_photos';
            
            // Get just the filename from the URL by parsing it properly
            let fileName;
            
            try {
                // Parse the URL to get just the filename portion
                const url = new URL(currentURL);
                // The object path in Firebase Storage URLs is in the 'o' parameter
                // It's URL encoded, so we need to decode it
                const objectPath = decodeURIComponent(url.pathname.split('/o/')[1]);
                
                // If the path contains the folder name twice, fix it
                if (objectPath.includes(`${folderName}/${folderName}/`)) {
                    // Extract just the filename without duplicate folders
                    fileName = objectPath.split(`${folderName}/${folderName}/`)[1];
                } else if (objectPath.includes(`${folderName}/`)) {
                    // Normal case - extract filename from the path
                    fileName = objectPath.split(`${folderName}/`)[1];
                } else {
                    // Fallback - just use the path as is
                    fileName = objectPath;
                }
                
                // Remove any query parameters if present
                fileName = fileName.split('?')[0];
                
                console.log(`Extracted filename: ${fileName}`);
            } catch (error) {
                // If URL parsing fails, fall back to the old method
                console.log("URL parsing failed, using fallback method");
                const urlParts = currentURL.split('?')[0].split('/');
                fileName = urlParts[urlParts.length - 1];
            }
            
            console.log(`Attempting to delete: ${folderName}/${fileName}`);
            
            // Create the proper reference to the file
            const fileRef = ref(storage, `${folderName}/${fileName}`);
            await deleteObject(fileRef);
            console.log(`Successfully deleted file: ${folderName}/${fileName}`);
        } catch (error) {
            console.error("Error deleting old photo:", error);
            console.log("Continuing despite error (file may have been deleted already)");
        }
    };

    // Delete photo handler
    const handleDeletePhoto = async (type) => {
        try {
            setLoading(true);
            const fieldToUpdate = type === 'profile' ? 'profilePicture' : 'coverPhoto';
            
            // Delete from storage
            await deleteOldPhoto(type);
            
            // Update database
            await updateDoc(doc(firestore, 'users', user.uid), { 
                [fieldToUpdate]: null 
            });
            
            // Update local state
            setUser(prev => ({ ...prev, [fieldToUpdate]: null }));
            
            toast.success(`${type === 'profile' ? 'Profile' : 'Cover'} photo removed`);
        } catch (error) {
            toast.error(`Failed to delete ${type} photo`);
        } finally {
            setLoading(false);
        }
    };

    const handleShowPublicToggle = async (checked) => {
        if (checked && !isUsernameValid(username)) {
            toast.error("You must set a valid username before making your profile public");
            return;
        }

        try {
            setLoading(true);
            await updateDoc(doc(firestore, 'users', user.uid), { show_public: checked });
            setShowPublic(checked);
            toast.success(`Profile visibility ${checked ? 'enabled' : 'disabled'}`);
        } catch (error) {
            toast.error("Error updating profile visibility");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (field, value) => {
        switch(field) {
            case 'name':
                setName(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'gender':
                setGender(value);
                break;
        }
    };

    const handleUpdate = async (field, value) => {
        setLoading(true);
        try {
            // Local validation first
            let isValid = true;
            let shouldProceed = true;

            switch(field) {
                case 'name':
                    isValid = validateName(value);
                    break;
                case 'username':
                    isValid = validateUsername(value);
                    if (isValid) {
                        shouldProceed = await checkUsername(value);
                    }
                    break;
                case 'phone':
                    isValid = validatePhone(value);

                    if(!isValid){
                        toast.error("Invalid phone number");
                    }
                    break;
                case 'gender':
                    // Gender doesn't need validation as it's from a select
                    isValid = true;
                    break;
            }

            if (!isValid || !shouldProceed) {
                setLoading(false);
                return;
            }

            // If username is being updated and profile is public, ensure it's valid
            if (field === 'username' && showPublic && !isUsernameValid(value)) {
                await updateDoc(doc(firestore, 'users', user.uid), {
                    [field]: value,
                    show_public: false
                });
                setShowPublic(false);
                toast.info("Profile visibility has been set to private due to username change");
            } else {
                await updateDoc(doc(firestore, 'users', user.uid), { [field]: value });
            }

            setUser(prev => ({ ...prev, [field]: value }));
            toast.success(`${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully`);
        } catch (error) {
            toast.error("Error updating profile");
        } finally {
            setLoading(false);
        }
    };

    const handleReset = (field) => {
        switch(field) {
            case 'name':
                setName(user.name || '');
                break;
            case 'username':
                setUsername(user.username || '');
                break;
            case 'phone':
                setPhone(user.phone || '');
                break;
            case 'gender':
                setGender(user.gender || '');
                break;
        }
        toast.info(`${field.charAt(0).toUpperCase() + field.slice(1)} reset to original value`);
    };

    useEffect(() => {
        const cookieUsername = Cookies.get('username');
        if (cookieUsername) {
            setLoadingUserData(true);
        }

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setLoadingUserData(true);
                try {
                    const userRef = doc(firestore, 'users', user.uid);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = userSnap.data();
                        // If username is invalid, force show_public to false
                        const shouldBePublic = userData.show_public && isUsernameValid(userData.username);
                        if (userData.show_public && !shouldBePublic) {
                            await updateDoc(userRef, { show_public: false });
                        }
                        setUser({ ...user, ...userData });
                        setName(userData.name || '');
                        setUsername(userData.username || '');
                        setPhone(userData.phone || '');
                        setGender(userData.gender || '');
                        setShowPublic(shouldBePublic);
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                } finally {
                    setLoadingUserData(false);
                }
            } else {
                setUser(null);
                setLoadingUserData(false);
            }
        });
        return () => unsubscribe();
    }, []);

    if (loadingUserData) {
        return (
            <div className='flex flex-col mt-20 gap-4 w-full justify-center items-center'>
                <div className="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
                <p className="text-base-content">Loading your account information...</p>
            </div>
        );
    }

    if (!user) {
        return (
            <div className='flex flex-col mt-20 gap-2 w-full justify-center items-center'>
                <Link to={"/login/?redirect=" + location.href} className="w-max btn btn-warning text-gray-900 hover:bg-warning-focus shadow-lg">
                    Please login to access this page
                </Link>
            </div>
        );
    }

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="min-h-screen bg-base-200"
        >
            {/* Cover Photo Section */}
            <div className="relative">
                <div className="h-48 sm:h-64 md:h-80 overflow-hidden rounded-b-lg shadow-lg relative">
                    {user.coverPhoto ? (
                        <img 
                            src={user.coverPhoto} 
                            alt="Cover" 
                            className="w-full h-full object-cover"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = null;
                            }}
                        />
                    ) : (
                        <div className="h-full bg-gradient-to-r from-blue-500 to-indigo-600"></div>
                    )}
                    <div className="absolute bottom-0 right-0 p-4 flex gap-2">
                        <input
                            type="file"
                            ref={coverPhotoInputRef}
                            onChange={(e) => handlePhotoChange(e, 'cover')}
                            hidden
                            accept="image/*"
                        />
                        {user.coverPhoto && (
                            <button 
                                className="btn btn-circle btn-sm bg-base-100 bg-opacity-70 hover:bg-opacity-100"
                                onClick={() => handleDeletePhoto('cover')}
                                disabled={loading || uploadingCover}
                            >
                                <FaTrash className="text-error" />
                            </button>
                        )}
                        <button 
                            className="btn btn-circle btn-sm bg-base-100 bg-opacity-70 hover:bg-opacity-100"
                            onClick={() => coverPhotoInputRef.current.click()}
                            disabled={uploadingCover}
                        >
                            <FaCamera />
                        </button>
                    </div>
                    {uploadingCover && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="w-64 bg-base-100 p-4 rounded-lg">
                                <progress 
                                    className="progress progress-primary w-full" 
                                    value={photoUploadProgress} 
                                    max="100"
                                ></progress>
                                <p className="text-center mt-2">Uploading: {Math.round(photoUploadProgress)}%</p>
                            </div>
                        </div>
                    )}
                </div>
                
                {/* Profile Picture Overlay */}
                <div className="absolute -bottom-16 left-4 sm:left-8 md:left-12">
                    <div className="relative">
                        <div className="w-28 h-28 sm:w-36 sm:h-36 rounded-full border-4 border-base-100 overflow-hidden bg-base-100">
                            {user.profilePicture ? (
                                <img 
                                    src={user.profilePicture} 
                                    alt="Profile" 
                                    className="w-full h-full object-cover"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.style.display = 'none';
                                        e.target.nextSibling.style.display = 'block';
                                    }}
                                />
                            ) : (
                                <MdAccountCircle className="w-full h-full text-gray-400" />
                            )}
                            {uploadingProfile && (
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                                    <div className="w-20 h-20 flex items-center justify-center">
                                        <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="absolute bottom-0 right-0 flex gap-1">
                            <input
                                type="file"
                                ref={photoInputRef}
                                onChange={(e) => handlePhotoChange(e, 'profile')}
                                hidden
                                accept="image/*"
                            />
                            {user.profilePicture && (
                                <button 
                                    className="btn btn-circle btn-sm bg-base-100"
                                    onClick={() => handleDeletePhoto('profile')}
                                    disabled={loading || uploadingProfile}
                                >
                                    <FaTrash className="text-error" />
                                </button>
                            )}
                            <button 
                                className="btn btn-circle btn-sm bg-base-100"
                                onClick={() => photoInputRef.current.click()}
                                disabled={uploadingProfile}
                            >
                                <FaCamera className="text-base-content" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Photo Edit Modal */}
            {photoEditMode && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4">
                    <div className="bg-base-100 rounded-lg w-full max-w-4xl p-6 max-h-[90vh] overflow-y-auto">
                        <h2 className="text-xl font-bold mb-4">Edit {currentPhotoType === 'profile' ? 'Profile' : 'Cover'} Photo</h2>
                        
                        {/* Tabs for editing options */}
                        <div className="tabs tabs-boxed mb-4">
                            <a 
                                className={`tab ${activeEditTab === 'crop' ? 'tab-active' : ''}`}
                                onClick={() => setActiveEditTab('crop')}
                            >
                                <MdCrop className="mr-2" /> Crop
                            </a>
                            <a 
                                className={`tab ${activeEditTab === 'filter' ? 'tab-active' : ''}`}
                                onClick={() => setActiveEditTab('filter')}
                            >
                                <MdFilterAlt className="mr-2" /> Filters
                            </a>
                            <a 
                                className={`tab ${activeEditTab === 'text' ? 'tab-active' : ''}`}
                                onClick={() => setActiveEditTab('text')}
                            >
                                <MdTextFields className="mr-2" /> Text
                            </a>
                        </div>
                        
                        <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                            {/* Image Preview Area */}
                            <div className="md:col-span-3 bg-base-300 p-2 rounded-lg flex items-center justify-center">
                                <div className="relative max-w-full max-h-[50vh]">
                                    {activeEditTab === 'crop' && imgSrc ? (
                                        <ReactCrop
                                            src={imgSrc}
                                            crop={crop}
                                            onChange={(c) => setCrop(c)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            onImageLoaded={onImageLoad}
                                            circularCrop={currentPhotoType === 'profile'}
                                        >
                                            <img
                                                ref={imgRef}
                                                alt="Crop"
                                                src={imgSrc}
                                                style={{ maxHeight: '50vh', maxWidth: '100%' }}
                                            />
                                        </ReactCrop>
                                    ) : (
                                        <div className="relative">
                                            <img 
                                                src={currentPhoto}
                                                alt="Preview" 
                                                ref={imgRef}
                                                className="max-h-[50vh] max-w-full"
                                                style={{
                                                    filter: filterType === 'grayscale' ? 'grayscale(100%)' :
                                                            filterType === 'sepia' ? 'sepia(100%)' :
                                                            filterType === 'blur' ? 'blur(3px)' :
                                                            filterType === 'brightness' ? 'brightness(150%)' :
                                                            filterType === 'contrast' ? 'contrast(200%)' : 'none'
                                                }}
                                            />
                                            
                                            {/* Text overlay */}
                                            {photoText && activeEditTab === 'text' && (
                                                <div style={getTextOverlayStyles()}>
                                                    {photoText}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            
                            {/* Controls Area - Takes 2/5 of space on larger screens */}
                            <div className="md:col-span-2">
                                {activeEditTab === 'crop' && (
                                    <div className="mb-4">
                                        <h3 className="font-medium mb-2">Crop Options</h3>
                                        <div className="flex flex-col gap-2">
                                            <p className="text-sm text-base-content text-opacity-70">
                                                Drag corners to resize. Drag inside to reposition.
                                            </p>
                                            <div className="form-control">
                                                <label className="label">
                                                    <span className="label-text">Aspect Ratio</span>
                                                </label>
                                                <select 
                                                    className="select select-bordered w-full"
                                                    value={crop.aspect ? (crop.aspect === 1 ? 'square' : 
                                                                        crop.aspect === 16/9 ? 'landscape' : 
                                                                        crop.aspect === 9/16 ? 'portrait' : 'free') : 'free'}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === 'free') {
                                                            setCrop({ ...crop, aspect: undefined });
                                                        } else if (value === 'square') {
                                                            setCrop({ ...crop, aspect: 1, width: 80, height: 80, x: 10, y: 10 });
                                                        } else if (value === 'landscape') {
                                                            setCrop({ ...crop, aspect: 16/9, width: 80, height: 45, x: 10, y: 25 });
                                                        } else if (value === 'portrait') {
                                                            setCrop({ ...crop, aspect: 9/16, width: 45, height: 80, x: 25, y: 10 });
                                                        }
                                                    }}
                                                >
                                                    <option value="free">Free Form</option>
                                                    <option value="square">Square (1:1)</option>
                                                    <option value="landscape">Landscape (16:9)</option>
                                                    <option value="portrait">Portrait (9:16)</option>
                                                </select>
                                            </div>
                                            
                                            {/* Reset crop button */}
                                            <button 
                                                className="btn btn-sm btn-outline mt-2"
                                                onClick={() => {
                                                    if (currentPhotoType === 'profile') {
                                                        setCrop({ 
                                                            unit: '%', 
                                                            width: 80, 
                                                            height: 80, 
                                                            x: 10, 
                                                            y: 10,
                                                            aspect: 1  // Square aspect ratio for profile pics
                                                        });
                                                    } else {
                                                        setCrop({ 
                                                            unit: '%', 
                                                            width: 100, 
                                                            height: 50, 
                                                            x: 0, 
                                                            y: 25 
                                                        });
                                                    }
                                                }}
                                            >
                                                Reset Crop
                                            </button>
                                        </div>
                                    </div>
                                )}
                                
                                {activeEditTab === 'filter' && (
                                    <div className="mb-4">
                                        <h3 className="font-medium mb-2">Filter Options</h3>
                                        <div className="form-control">
                                            <select 
                                                className="select select-bordered w-full"
                                                value={filterType}
                                                onChange={(e) => setFilterType(e.target.value)}
                                            >
                                                <option value="none">None</option>
                                                <option value="grayscale">Grayscale</option>
                                                <option value="sepia">Sepia</option>
                                                <option value="blur">Blur</option>
                                                <option value="brightness">Bright</option>
                                                <option value="contrast">Contrast</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                                
                                {activeEditTab === 'text' && (
                                    <div className="mb-4">
                                        <h3 className="font-medium mb-2">Text Overlay</h3>
                                        <div className="form-control mb-3">
                                            <input
                                                type="text"
                                                placeholder="Enter text to overlay on image"
                                                className="input input-bordered w-full"
                                                value={photoText}
                                                onChange={(e) => setPhotoText(e.target.value)}
                                            />
                                        </div>
                                        
                                        <div className="form-control mb-3">
                                            <label className="label">
                                                <span className="label-text">Text Color</span>
                                            </label>
                                            <div className="flex items-center gap-2">
                                                <input
                                                    type="color"
                                                    value={textColor}
                                                    onChange={(e) => setTextColor(e.target.value)}
                                                    className="w-10 h-10 cursor-pointer"
                                                />
                                                <div className="flex-1">
                                                    <select 
                                                        className="select select-bordered w-full"
                                                        value={textColor}
                                                        onChange={(e) => setTextColor(e.target.value)}
                                                    >
                                                        <option value="#ffffff">White</option>
                                                        <option value="#000000">Black</option>
                                                        <option value="#ff0000">Red</option>
                                                        <option value="#00ff00">Green</option>
                                                        <option value="#0000ff">Blue</option>
                                                        <option value="#ffff00">Yellow</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="form-control mb-3">
                                            <label className="label cursor-pointer">
                                                <span className="label-text">Text Shadow</span>
                                                <input 
                                                    type="checkbox" 
                                                    className="toggle toggle-primary" 
                                                    checked={textShadow}
                                                    onChange={(e) => setTextShadow(e.target.checked)}
                                                />
                                            </label>
                                        </div>
                                        
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Text Position</span>
                                            </label>
                                            <select 
                                                className="select select-bordered w-full"
                                                value={textPosition}
                                                onChange={(e) => setTextPosition(e.target.value)}
                                            >
                                                <option value="top-left">Top Left</option>
                                                <option value="top-center">Top Center</option>
                                                <option value="top-right">Top Right</option>
                                                <option value="center">Center</option>
                                                <option value="bottom-left">Bottom Left</option>
                                                <option value="bottom-center">Bottom Center</option>
                                                <option value="bottom-right">Bottom Right</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                                
                                {/* File size info */}
                                <div className="text-sm text-base-content text-opacity-70 mb-4 mt-6">
                                    <p>Your image will be compressed to under 0.5MB for optimal loading.</p>
                                </div>
                                
                                {/* Action Buttons */}
                                <div className="flex justify-end gap-2 mt-4">
                                    <button 
                                        className="btn btn-ghost"
                                        onClick={resetImageEditModal}
                                    >
                                        Cancel
                                    </button>
                                    <button 
                                        className={`btn btn-primary ${uploadingProfile || uploadingCover ? 'loading' : ''}`}
                                        onClick={uploadPhoto}
                                        disabled={uploadingProfile || uploadingCover}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Main Profile Content */}
            <div className="container mx-auto px-4 pt-20 pb-10">
                {/* Profile Header */}
                <div className="flex flex-col md:flex-row justify-between items-start md:items-end mb-6">
                    <div>
                        <div className='flex flex-row w-full gap-1'><h1 className="text-2xl font-bold">{name || 'Set Your Name'} </h1>  {user.is_verified && (
                                                                    <FaCheckCircle className="text-blue-500 text-xl" title="Verified Account" />
                                                                )}</div>
                        <p className="text-base-content text-opacity-70">@{username || 'username'}</p>
                    </div>
                    
                    <div className="flex items-center gap-2 mt-2 md:mt-0">
                        <div className="form-control">
                            <label className="cursor-pointer flex items-center gap-2">
                                <span className="label-text flex items-center gap-1">
                                    <MdPublic className="text-base-content" /> 
                                    Public Profile
                                </span>
                                <input 
                                    type="checkbox" 
                                    className="toggle toggle-primary toggle-sm"
                                    checked={showPublic}
                                    onChange={(e) => handleShowPublicToggle(e.target.checked)}
                                    disabled={!isUsernameValid(username)}
                                />
                            </label>
                        </div>
                    </div>
                </div>

                {/* Profile Navigation Tabs */}
                <div className="tabs tabs-bordered mb-6">
                    <a className="tab tab-bordered tab-active">Profile</a>
            
                
                    {/* <a className="tab tab-bordered opacity-50">Settings</a> */}
                </div>

                {/* Profile Content */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Left Column - About */}
                    <div className="md:col-span-1">
                        <div className="card bg-base-100 shadow-md">
                            <div className="card-body">
                                <h2 className="card-title flex items-center gap-2">
                                    <FaUser /> About
                                </h2>
                                
                                <div className="divider my-2"></div>
                                
                                {/* Email */}
                                <div className="flex items-center gap-3 mb-2">
                                    <MdEmail className="text-base-content text-opacity-70" />
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm text-base-content text-opacity-70">Email</p>
                                        <div className="flex items-center justify-between gap-1">
                                            <p className="text-base-content truncate">{user.email}</p>
                                            <button 
                                                className="btn btn-ghost btn-xs"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(user.email);
                                                    toast.success('Email copied to clipboard');
                                                }}
                                            >
                                                <FaCopy />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Phone */}
                                <div className="flex items-center gap-3 mb-2">
                                    <FaPhone className="text-base-content text-opacity-70" />
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm text-base-content text-opacity-70">Phone</p>
                                        <p className="text-base-content">{phone || 'Not set'}</p>
                                    </div>
                                    <button 
                                        className="btn btn-ghost btn-xs"
                                        onClick={() => document.getElementById('phone-modal').showModal()}
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                
                                {/* Gender */}
                                <div className="flex items-center gap-3 mb-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-base-content text-opacity-70" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M10 15.464C10.0635 15.4659 12 17.5 12 17.5C12 17.5 13.9365 15.4659 14 15.464" />
                                        <rect x="6" y="3" width="12" height="9" rx="1" />
                                        <path d="M8 12h8v8a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-8Z" />
                                    </svg>
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm text-base-content text-opacity-70">Gender</p>
                                        <p className="text-base-content capitalize">{gender || 'Not set'}</p>
                                    </div>
                                    <button 
                                        className="btn btn-ghost btn-xs"
                                        onClick={() => document.getElementById('gender-modal').showModal()}
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                
                                {/* User ID */}
                                <div className="flex items-center gap-3">
                                    <FaUserShield className="text-base-content text-opacity-70" />
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm text-base-content text-opacity-70">User ID</p>
                                        <div className="flex items-center justify-between gap-1">
                                            <p className="text-base-content text-xs truncate">{user.uid}</p>
                                            <button 
                                                className="btn btn-ghost btn-xs"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(user.uid);
                                                    toast.success('User ID copied to clipboard');
                                                }}
                                            >
                                                <FaCopy />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Roles */}
                                <div className="mt-4">
                                    <p className="text-sm text-base-content text-opacity-70 mb-2 flex items-center gap-2">
                                        <FaUserShield /> Roles
                                    </p>
                                    <div className="flex flex-wrap gap-2">
                                        {user.role?.map((role, index) => (
                                            <span key={index} className="badge badge-primary">
                                                {formatRole(role)}
                                            </span>
                                        ))}
                                    </div>
                                </div>

                          {/* Actions */}
                                                            <div className="mt-6 flex flex-wrap gap-2">
                                                                <button 
                                                                    onClick={copyProfileLink}
                                                                    className="btn btn-primary btn-sm gap-2"
                                                                >
                                                                    <FaLink />
                                                                    Share Profile
                                                                </button>
                                                            </div>
                                
                            </div>
                        </div>
                    </div>
                    
                    {/* Right Column - Edit Profile */}
                    <div className="md:col-span-2">
                        <div className="card bg-base-100 shadow-md mb-6">
                            <div className="card-body">
                                <h2 className="card-title">Edit Profile</h2>
                                
                                <div className="divider my-2"></div>
                                
                                {/* Name Field */}
                                <div className="form-control mb-4">
                                    <label className="label">
                                        <span className="label-text font-medium">Name</span>
                                    </label>
                                    <div className="flex flex-col sm:flex-row gap-2">
                                        <input 
                                            type="text" 
                                            value={name}
                                            onChange={(e) => handleInputChange('name', e.target.value)}
                                            className="input input-bordered w-full"
                                            placeholder="Enter your name"
                                        />
                                        <div className="flex gap-2 justify-end sm:justify-start">
                                            <button 
                                                className={`btn btn-primary flex-1 sm:flex-none ${loading ? 'loading' : ''}`}
                                                onClick={() => name !== user.name && handleUpdate('name', name)}
                                                disabled={loading || name === user.name}
                                            >
                                                Save
                                            </button>
                                            <button 
                                                className="btn btn-ghost btn-square"
                                                onClick={() => handleReset('name')}
                                                disabled={loading || name === user.name}
                                                title="Reset to saved value"
                                            >
                                                <MdRestartAlt className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Username Field */}
                                <div className="form-control mb-4">
                                    <label className="label">
                                        <span className="label-text font-medium">Username</span>
                                    </label>
                                    <div className="flex flex-col sm:flex-row gap-2">
                                        <div className="flex items-center flex-1">
                                            <span className="bg-base-200 px-3 py-2 rounded-l-lg text-base-content text-opacity-70">@</span>
                                            <input 
                                                type="text" 
                                                value={username}
                                                onChange={(e) => handleInputChange('username', e.target.value)}
                                                className="input input-bordered rounded-l-none w-full"
                                                placeholder="Choose a username"
                                            />
                                        </div>
                                        <div className="flex gap-2 justify-end sm:justify-start">
                                            <button 
                                                className={`btn btn-primary flex-1 sm:flex-none ${loading ? 'loading' : ''}`}
                                                onClick={() => username !== user.username && handleUpdate('username', username)}
                                                disabled={loading || username === user.username}
                                            >
                                                Save
                                            </button>
                                            <button 
                                                className="btn btn-ghost btn-square"
                                                onClick={() => handleReset('username')}
                                                disabled={loading || username === user.username}
                                                title="Reset to saved value"
                                            >
                                                <MdRestartAlt className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                    {username && !isUsernameValid(username) && (
                                        <div className="text-error text-sm mt-1">
                                            Username must be at least 5 characters with no inappropriate words
                                        </div>
                                    )}
                                </div>
                                
                                {/* Phone Field */}
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text font-medium">Phone Number</span>
                                    </label>
                                    <div className="flex flex-col sm:flex-row gap-2">
                                        <input 
                                            type="text" 
                                            value={phone}
                                            onChange={(e) => handleInputChange('phone', e.target.value)}
                                            className="input input-bordered w-full"
                                            placeholder="Enter phone number (e.g., 0764783434)"
                                        />
                                        <div className="flex gap-2 justify-end sm:justify-start">
                                            <button 
                                                className={`btn btn-primary flex-1 sm:flex-none ${loading ? 'loading' : ''}`}
                                                onClick={() => phone !== user.phone && handleUpdate('phone', phone)}
                                                disabled={loading || phone === user.phone}
                                            >
                                                Save
                                            </button>
                                            <button 
                                                className="btn btn-ghost btn-square"
                                                onClick={() => handleReset('phone')}
                                                disabled={loading || phone === user.phone}
                                                title="Reset to saved value"
                                            >
                                                <MdRestartAlt className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Gender Field */}
                                <div className="form-control mt-4">
                                    <label className="label">
                                        <span className="label-text font-medium">Gender</span>
                                    </label>
                                    <div className="flex flex-col sm:flex-row gap-2">
                                        <select 
                                            className="select select-bordered w-full"
                                            value={gender || ''}
                                            onChange={(e) => handleInputChange('gender', e.target.value)}
                                        >
                                            <option value="" disabled>Select your gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                        <div className="flex gap-2 justify-end sm:justify-start">
                                            <button 
                                                className={`btn btn-primary flex-1 sm:flex-none ${loading ? 'loading' : ''}`}
                                                onClick={() => gender !== user.gender && handleUpdate('gender', gender)}
                                                disabled={loading || gender === user.gender}
                                            >
                                                Save
                                            </button>
                                            <button 
                                                className="btn btn-ghost btn-square"
                                                onClick={() => handleReset('gender')}
                                                disabled={loading || gender === user.gender}
                                                title="Reset to saved value"
                                            >
                                                <MdRestartAlt className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Phone Edit Modal */}
            <dialog id="phone-modal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg mb-4">Update Phone Number</h3>
                    <div className="form-control">
                        <input 
                            type="text" 
                            value={phone}
                            onChange={(e) => handleInputChange('phone', e.target.value)}
                            className="input input-bordered w-full"
                            placeholder="Enter phone number (e.g., 0764783434)"
                        />
                        <p className="text-sm text-base-content text-opacity-70 mt-2">
                            Please enter a valid Sri Lankan phone number
                        </p>
                    </div>
                    <div className="modal-action">
                        <button 
                            className="btn btn-ghost"
                            onClick={() => document.getElementById('phone-modal').close()}
                        >
                            Cancel
                        </button>
                        <button 
                            className={`btn btn-primary ${loading ? 'loading' : ''}`}
                            onClick={() => {
                                if (phone !== user.phone) {
                                    handleUpdate('phone', phone);
                                }
                                document.getElementById('phone-modal').close();
                            }}
                            disabled={loading || phone === user.phone || !validatePhone(phone)}
                        >
                            Save
                        </button>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>

            {/* Gender Edit Modal */}
            <dialog id="gender-modal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg mb-4">Update Gender</h3>
                    <div className="form-control">
                        <select 
                            className="select select-bordered w-full"
                            value={gender || ''}
                            onChange={(e) => handleInputChange('gender', e.target.value)}
                        >
                            <option value="" disabled>Select your gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="modal-action">
                        <button 
                            className="btn btn-ghost"
                            onClick={() => document.getElementById('gender-modal').close()}
                        >
                            Cancel
                        </button>
                        <button 
                            className={`btn btn-primary ${loading ? 'loading' : ''}`}
                            onClick={() => {
                                if (gender !== user.gender) {
                                    handleUpdate('gender', gender);
                                }
                                document.getElementById('gender-modal').close();
                            }}
                            disabled={loading || gender === user.gender || !gender}
                        >
                            Save
                        </button>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </motion.div>
    );
};

export default MyAccountPage;