import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';
import AdminPage from './AdminPage'; // Assuming you have an AdminPage component
import StudentPage from './StudentPage'; // Assuming you have a StudentPage component
import TutorPage from './TutorPage'; // Assuming you have a TutorPage component
import Button from '@mui/material/Button'; // Material-UI Button component
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI Loading Spinner
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import ClientPage from './ClientPage';
import SLTPage from './SLTPage';
import LovePage from './LovePage';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [adminRole, setAdminRole] = useState(false);
  const [studentRole, setStudentRole] = useState(false);
  const [sltRole, setSltRole] = useState(false);
  const [clientRole, setClientRole] = useState(false);
  const [tutorRole, setTutorRole] = useState(false);
  const [loveRole, setLoveRole] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [currentPage, setCurrentPage] = useState(null); // State to manage the current page
  const [isHeaderVisible, setIsHeaderVisible] = useState(true); // State to track header visibility
  const [dateTime, setDateTime] = useState(new Date()); // State for current date and time
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Update date and time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);
    
    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const boardParam = searchParams.get('board');
    
    if (boardParam && ['admin', 'student', 'tutor', 'client', 'slt', 'love'].includes(boardParam)) {
      setCurrentPage(boardParam);
    } else if (!boardParam) {
      setCurrentPage(null);
    }
  }, [searchParams]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userRef = doc(firestore, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            // Add null check for role
            const userRole = userData.role || [];
            setUser({ ...user, name: userData.name, role: userRole, id: userData.id }); // Set user data
            
            // Check URL parameter after setting user
            const boardParam = searchParams.get('board');
            if (boardParam && ['admin', 'student', 'tutor', 'client', 'slt', 'love'].includes(boardParam)) {
              const roleMatch = {
                admin: userRole.includes('admin'),
                student: userRole.includes('student'),
                tutor: userRole.includes('tutor'),
                client: userRole.includes('client'),
                slt: userRole.includes('slt'),
                love: userRole.includes('love')
              };
              // Only set the page if user has the required role
              if (roleMatch[boardParam]) {
                setCurrentPage(boardParam);
              } else {
                // User doesn't have access to this board, reset to dashboard
                setCurrentPage(null);
                navigate('/dashboard', { replace: true });
              }
            }
          } else {
            setUser({ ...user, role: [] }); // Set empty role array if no user data exists
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUser(null);
          // Navigate to dashboard with no parameters in case of error
          navigate('/dashboard', { replace: true });
        }
      } else {
        // User is signed out
        setUser(null);
        setCurrentPage(null);
        // Navigate to dashboard with no parameters when signed out
        navigate('/dashboard', { replace: true });
      }
      setLoading(false); // Set loading to false once user data is fetched
    });

    return () => unsubscribe();
  }, [searchParams, navigate]);

  useEffect(() => {
    if (user && user.role) {
      setAdminRole(user.role.includes('admin'));
      setStudentRole(user.role.includes('student'));
      setTutorRole(user.role.includes('tutor'));
      setClientRole(user.role.includes('client'));
      setSltRole(user.role.includes('slt'));
      setLoveRole(user.role.includes('love'));

   
      
    } else {
      setAdminRole(false);
      setStudentRole(false);
      setTutorRole(false);
      setClientRole(false);
      setSltRole(false);
      setLoveRole(false);
    }
  }, [user]);

  const toggleHeader = () => {
    setIsHeaderVisible(!isHeaderVisible);
  };

  const renderPage = () => {
    if (currentPage === 'admin') {
      return <div className='mt-10 w-full'><AdminPage /></div>;
    } else if (currentPage === 'student') {
      return <div className='mt-10 w-full'><StudentPage/> </div>;
    } else if (currentPage === 'tutor') {
      return <div className='mt-10 w-full'><TutorPage /></div>;
    }else if (currentPage === 'client') {
      return <div className='mt-10 w-full'><ClientPage /></div>;
    } else if (currentPage === 'slt') {
      return <div className='mt-10 w-full'><SLTPage /></div>;
    }else if (currentPage === 'love') {
      return <div className='mt-10 w-full'><LovePage /></div>;
    } else {
      
      
      return null;
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    
    if (page === null) {
      navigate('/dashboard', { replace: true });
    } else {
      navigate(`/dashboard?board=${page}`, { replace: true });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-20 w-full">
        <CircularProgress /> {/* Material-UI Loading Spinner */}
      </div>
    );
  }

  return (
    <div className={`min-h-screen w-full overscroll-auto`}>
      <div className="container mx-auto p-4 w-full">
       

        {user && (adminRole || studentRole || tutorRole || clientRole || sltRole || loveRole) ? (
          <>
            <motion.div 
              className="flex fixed justify-between items-center mb-24 w-full left-0 z-20 bg-yellow-400 p-4"
              initial={{ top: '80px', height: '100px' }}
              animate={{ 
                top: isHeaderVisible ? '80px' : '-100px',
                height: '100px'
              }}
              transition={{ duration: 0.3 }}
            >
              {currentPage && (
              <motion.button
                onClick={() => {
                  handlePageChange(searchParams.get('board'));
                  setCurrentPage(null);
                }}
                className="fixed z-20 bg-blue-500 hover:bg-blue-600 text-white rounded-full h-10 w-10 font-semibold -mt-[30px] shadow-lg flex items-center justify-center"
                initial={{ top: '140px' }}
                animate={{ 
                  top: isHeaderVisible ? '140px' : '40px'
                }}
                transition={{ duration: 0.3 }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <ArrowBackIosNewIcon />
              </motion.button>
            )}
              
             <div className='w-full flex justify-center items-center gap-2 flex-col'>
             <div className="text-blue-600 text-3xl font-bold absolute right-10 cursor-pointer hover:text-base-100"><h1 
              onClick={() => {
                handlePageChange(null);
                setCurrentPage(null);
              }}
              >Dashboard</h1></div>
                <div className="text-red-600 text-md font-bold absolute right-10 top-[65px] cursor-pointer hover:text-base-100">
                  {dateTime.toLocaleDateString()} {dateTime.toLocaleTimeString()}
                </div>
            
             </div>
            </motion.div>
            
            
            
            <motion.button
              onClick={toggleHeader}
              className="fixed z-20 left-1/2 transform bg-yellow-400 hover:bg-yellow-500 rounded-b-lg px-3 py-1 shadow-lg"
              initial={{ top: '178px' }}
              animate={{ 
                top: isHeaderVisible ? '178px' : '80px'
              }}
              transition={{ duration: 0.3 }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {isHeaderVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </motion.button>
          </>
        ) : (
        <div className='w-full text-center justify-center items-center mt-20 text-2xl font-bold text-red-500'>
          {user === null ? "Please sign in to access the dashboard." : "You are not authorized to access this page!"}
        </div>)}


        {user && currentPage ? (
          renderPage()
        ) : (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-wrap w-full justify-center items-center gap-6 mt-20"
          > 
            {adminRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Admin</h2>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handlePageChange('admin')}
                >
                  Go to Admin Page
                </Button>
              </motion.div>
            )}

            {studentRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Student</h2>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => handlePageChange('student')}
                >
                  Go to Student Page
                </Button>
              </motion.div>
            )}

            {tutorRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Tutor</h2>
                <Button 
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => handlePageChange('tutor')}
                >
                  Go to Tutor Page
                </Button>
              </motion.div>
            )}

{clientRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Client</h2>
                <Button
                  variant="contained"
                  color="warning"
                  fullWidth
                  onClick={() => handlePageChange('client')}
                >
                  Go to Client Page
                </Button>
              </motion.div>
            )}


{sltRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Home Broadband</h2>
                <button
                  className='bg-blue-500 w-full hover:bg-blue-600 text-white font-bold py-1 px-4 rounded'
                  onClick={() => handlePageChange('slt')}
                >
                 Go to SLT HBB
                </button>
              </motion.div>
            )}

{loveRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">
                  {user && user.id ? 
                    (user.id === "aYXxmGvFiBgYh5SwV1r2gOqd2pl1" ? "HaPpiness 💕" : 
                     user.id === "QLTJsKRan6MQQi197UFGlYTYLNh1" ? "Su 🤍💕" : "N/A") 
                  : "Love"}
                </h2>
                <button
                  className='bg-pink-500 w-full hover:bg-pink-600 text-white font-bold py-1 px-4 rounded'
                  onClick={() => handlePageChange('love')}
                >
                Open Day Count
                </button>
              </motion.div>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;