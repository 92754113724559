import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebaseConfig';
import { collection, setDoc, doc, getDocs, query, where } from 'firebase/firestore';
import {
  TextField, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip, FormControlLabel, Switch,
  MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { toast } from 'react-toastify';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dense, setDense] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const roles = ['admin', 'tutor', 'staff', 'student', 'client'];

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(firestore, 'users'));
    const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersList);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUpdateUser = async () => {
    const usernameQuery = query(collection(firestore, 'users'), where('username', '==', editUser.username));
    const usernameSnapshot = await getDocs(usernameQuery);
    if (!usernameSnapshot.empty && usernameSnapshot.docs[0].id !== editUser.id) {
      toast.error('Username already taken');
      return;
    }
    await setDoc(doc(firestore, 'users', editUser.id), editUser);
    setEditUser(null);
    toast.success('User updated successfully');
    const querySnapshot = await getDocs(collection(firestore, 'users'));
    const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersList);
  };

  const handleUpdateUserField = async (id, field, value) => {
    const userRef = doc(firestore, 'users', id);
    await setDoc(userRef, { [field]: value }, { merge: true });
    const updatedUsers = users.map(user => user.id === id ? { ...user, [field]: value } : user);
    setUsers(updatedUsers);
    toast.success('User updated successfully');
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearch = (event) => {
    setSearchKeyword(event.target.value);
  };

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...filteredUsers]
        .sort((a, b) => (order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy]) ? 1 : -1)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, filteredUsers],
  );

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4">Manage Users</h1>
      <div className='bg-white rounded-sm mt-2 px-2 mb-2 flex items-center gap-2'>
        <TextField
          label="Search by Name"
          value={searchKeyword}
          onChange={handleSearch}
          fullWidth
          margin="normal"
        />
        <IconButton onClick={fetchUsers} title="Refresh users">
          <RefreshIcon />
        </IconButton>
      </div>
      <Dialog open={!!editUser} onClose={() => setEditUser(null)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {editUser && (
            <>
              <TextField
                label="UID"
                value={editUser.id}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Email"
                value={editUser.email}
                onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Name"
                value={editUser.name}
                onChange={(e) => setEditUser({ ...editUser, name: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Phone"
                value={editUser.phone}
                onChange={(e) => setEditUser({ ...editUser, phone: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Username"
                value={editUser.username}
                onChange={(e) => setEditUser({ ...editUser, username: e.target.value })}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Roles</InputLabel>
                <Select
                  multiple
                  value={editUser.role}
                  onChange={(e) => setEditUser({ ...editUser, role: e.target.value })}
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={editUser.is_active}
                    onChange={(e) => setEditUser({ ...editUser, is_active: e.target.checked })}
                  />
                }
                label="Profile Active"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editUser.show_public}
                    onChange={(e) => setEditUser({ ...editUser, show_public: e.target.checked })}
                  />
                }
                label="Profile Visible to Public"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editUser.is_verified || false}
                    onChange={(e) => setEditUser({ ...editUser, is_verified: e.target.checked })}
                  />
                }
                label="User Verified"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Gender</InputLabel>
                <Select
                  value={editUser.gender || ''}
                  onChange={(e) => setEditUser({ ...editUser, gender: e.target.value })}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Profile Picture URL"
                value={editUser.profilePicture}
                onChange={(e) => setEditUser({ ...editUser, profilePicture: e.target.value })}
                fullWidth
                margin="normal"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditUser(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateUser} color="primary">
            Update User
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Toolbar
            sx={[
              {
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              },
              selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              },
            ]}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Users
              </Typography>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < users.length}
                      checked={users.length > 0 && selected.length === users.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all users',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    key="name"
                    align="left"
                    padding="normal"
                    sortDirection={orderBy === 'name' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'name')}
                    >
                      Name
                      {orderBy === 'name' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Username</TableCell>
                  <TableCell align="left">Roles</TableCell>
                  <TableCell align="left">Profile Active</TableCell>
                  <TableCell align="left">Profile Visible to Public</TableCell>
                  <TableCell align="left">Verified</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((user, index) => {
                  const isItemSelected = selected.includes(user.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, user.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={user.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {user.name}
                      </TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.phone}</TableCell>
                      <TableCell align="left">{user.username}</TableCell>
                      <TableCell align="left">{user.role.join(', ')}</TableCell>
                      <TableCell align="left">
                        <Switch
                          checked={user.is_active}
                          onChange={(e) => handleUpdateUserField(user.id, 'is_active', e.target.checked)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Switch
                          checked={user.show_public}
                          onChange={(e) => handleUpdateUserField(user.id, 'show_public', e.target.checked)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Switch
                          checked={user.is_verified || false}
                          onChange={(e) => handleUpdateUserField(user.id, 'is_verified', e.target.checked)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <IconButton onClick={() => setEditUser(user)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </div>
  );
};

export default ManageUsers;
