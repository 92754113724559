import React, { useState, useEffect } from 'react';
import { FaYoutube } from 'react-icons/fa';
import { RxReload } from 'react-icons/rx';
import { motion } from 'framer-motion';
import { MdOutlineDataUsage } from 'react-icons/md';

const ManageAddons = () => {
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ytbutton, setYtButton] = useState(true);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const username = getCookie('slt_connection');
      const slt_token = getCookie('slt_token');
      let number = username ? username : "null";
      let token = slt_token ? slt_token : "null";
      
      const response = await fetch(
        'https://omniscapp.slt.lk/slt/ext/api/BBVAS/GetDashboardVASBundles?subscriberID='+number,
        {
          cache: 'default',
          credentials: 'include',
          headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'bearer ' + token,
            'User-Agent': 'Mozilla/5.0',
            'X-IBM-Client-Id': 'b7402e9d66808f762ccedbe42c20668e',
          },
          method: 'GET',
          mode: 'cors',
          redirect: 'follow',
          referrer: "https://myslt.slt.lk/",
          referrerPolicy: "strict-origin-when-cross-origin"
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setSuccess(data);
      console.log(data);
    } catch (error) {
      console.error(error);
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  const addYTFree = async (e) => {
    setYtButton(false);
    
    try {
      const username = getCookie('slt_connection');
      const slt_token = getCookie('slt_token');
      let number = username ? username : "null";
      let token = slt_token ? slt_token : "null";
      
      const response = await fetch(
        `https://omniscapp.slt.lk/slt/ext/api/TimelyPay/packageActivation?telephoneno=${number}&packageid=2&refrenceNo=2233764`,
        {
          cache: 'default',
          credentials: 'include',
          headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'bearer ' + token,
            'User-Agent': 'Mozilla/5.0',
            'X-IBM-Client-Id': 'b7402e9d66808f762ccedbe42c20668e',
          },
          method: 'POST',
          mode: 'cors',
          redirect: 'follow',
          referrer: "https://myslt.slt.lk/",
          referrerPolicy: "strict-origin-when-cross-origin"
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setYtButton(true);
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container my-5 ">
      {loading ? (
        <div className="flex flex-col items-center justify-center p-10">
          <motion.div
            initial={{ scale: 0.8, opacity: 0.5 }}
            animate={{ scale: 1.2, opacity: 1 }}
            transition={{ 
              repeat: Infinity, 
              repeatType: "reverse", 
              duration: 0.8 
            }}
            className="text-primary text-5xl mb-3"
          >
            <MdOutlineDataUsage />
          </motion.div>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-lg font-medium text-gray-600 dark:text-gray-300"
          >
            Loading add-ons data...
          </motion.p>
        </div>
      ) : success && success.isSuccess !== undefined ? (
        <div className="bg-base-200 p-10 shadow-lg card">
          <div className="">
            <h5 className="text-green-500 card-title">
              {success.isSuccess ? 'Success' : 'Error'} | Add-Ons Data <span className='cursor-pointer' onClick={fetchData}><RxReload /></span>
            </h5>
            {success.errorMessege && (
              <p className="text-danger">{success.errorMessege}</p>
            )}
            <p className="card-text">
              <strong>Reported Time:</strong> {success.dataBundle?.reported_time}
            </p>

            {success.dataBundle?.usageDetails?.length > 0 && (
              <div className="flex md:flex-row flex-col justify-center gap-4 mt-5">
                {success.dataBundle.usageDetails.map(
                  (usage, index) => (
                    <div key={index} className="col-md-4 bg-base-300 card hover:scale-105 shadow-lg">
                      <div className="card mb-3">
                        <div className="card-body">
                          <h6 className="card-title text-red-600">{usage.name}</h6>
                          <p>
                            <strong>Used:</strong>{' '}
                            <span className='text-blue-500 font-semibold'>{usage.used || 'N/A'} {usage.volume_unit}</span> / {usage.limit || 'Unlimited'} {usage.limit ? usage.volume_unit : ""}
                          </p>
                          <p className='text-green-600'>
                            <strong>Expiry Date:</strong>{' '}
                            {usage.expiry_date || 'N/A'}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}

            <div className='mt-5 justify-center flex'>
              <button onClick={addYTFree} disabled={!ytbutton} className='btn bg-red-600 hover:bg-red-700 text-white'><FaYoutube />{ytbutton ? "Add Free YouTube" : "Requesting..."}</button>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-danger">No data available. Please try again.</p>
      )}
    </div>
  );
};

export default ManageAddons;
