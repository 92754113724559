import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getDocs, collection, query, where, documentId } from 'firebase/firestore';
import {
  Box, Typography, useTheme, useMediaQuery, CircularProgress, Card,
  CardContent, List, ListItem, ListItemText, Divider, Alert, Avatar,
  Grid, Chip, Button, Link, Dialog, DialogTitle, DialogContent, 
  DialogActions, Tab, Tabs, IconButton, Paper, Tooltip, Table,
  TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { 
  Download, Visibility, Description, Language, Code,
  AttachMoney, AccessTime, Public, People, Person,
  Engineering, PendingActions, HourglassEmpty, Build, TaskAlt, Cancel, Pause,
  Category, Business, Check
} from '@mui/icons-material';

const MyProjects = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState(0);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loadingLogos, setLoadingLogos] = useState({});
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [projectUsers, setProjectUsers] = useState({});
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");

  useEffect(() => {
    fetchProjectData();
  }, []);

  const fetchProjectData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Get the current user
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError("You need to be logged in to view your project details.");
        setLoading(false);
        return;
      }

      // Get user document with project assignments
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      
      if (!userDoc.exists()) {
        setError("User profile not found.");
        setLoading(false);
        return;
      }

      const userData = userDoc.data();
      
      // Check if user has projects
      if (!userData.projects || userData.projects.length === 0) {
        setError("You don't have any projects associated with your account.");
        setLoading(false);
        return;
      }

      // Collect all project IDs for batch fetching
      const projectIds = userData.projects.map(project => project.projectId);
      
      // Create a map of project roles by project ID for easy access
      const projectRolesMap = userData.projects.reduce((map, project) => {
        map[project.projectId] = {
          role: project.role || [],
          assignedAt: project.assignedAt || null
        };
        return map;
      }, {});

      // Batch fetch all projects
      const projectsQuery = query(
        collection(firestore, "projects"), 
        where(documentId(), "in", projectIds)
      );
      
      const projectSnapshots = await getDocs(projectsQuery);
      
      if (projectSnapshots.empty) {
        setError("No project information found.");
        setLoading(false);
        return;
      }
      
      // Transform project data
      const projectsData = projectSnapshots.docs.map(doc => {
        const projectData = doc.data();
        return {
          id: doc.id,
          ...projectData,
          userRole: projectRolesMap[doc.id].role || [],
          assignedAt: projectRolesMap[doc.id].assignedAt || null
        };
      });
      
      setProjects(projectsData);
      
      // Fetch users for all projects
      await fetchProjectUsers(projectsData);
      
    } catch (error) {
      console.error("Error fetching project data:", error);
      setError("An error occurred while fetching project data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchProjectUsers = async (projectsData) => {
    setLoadingUsers(true);
    
    try {
      const projectUsersMap = {};
      
      for (const project of projectsData) {
        if (project.users && project.users.length > 0) {
          const userIdsToFetch = project.users
            .map(user => user.userId)
            .filter(id => id); // Filter out any undefined IDs
            
          if (userIdsToFetch.length === 0) continue;
          
          // Create batches of user IDs (Firestore limits "in" queries to 10 items)
          const userIdBatches = [];
          for (let i = 0; i < userIdsToFetch.length; i += 10) {
            userIdBatches.push(userIdsToFetch.slice(i, i + 10));
          }
          
          const projectUserDetails = [];
          
          // Process each batch
          for (const batch of userIdBatches) {
            const usersQuery = query(
              collection(firestore, "users"),
              where(documentId(), "in", batch)
            );
            
            const userSnapshots = await getDocs(usersQuery);
            
            userSnapshots.forEach(doc => {
              const userData = doc.data();
              // Find user roles from project data
              const userInProject = project.users.find(u => u.userId === doc.id);
              
              projectUserDetails.push({
                id: doc.id,
                name: userData.name || userData.displayName || "Unknown User",
                email: userData.email || null,
                username: userData.username || null,
                photoURL: userData.profilePicture || null,
                role: userInProject?.role || []
              });
            });
          }
          
          projectUsersMap[project.id] = projectUserDetails;
        } else {
          projectUsersMap[project.id] = [];
        }
      }
      
      setProjectUsers(projectUsersMap);
    } catch (error) {
      console.error("Error fetching project users:", error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleDocumentView = (docUrl, docName) => {
    // Set the selected document and open modal
    setSelectedDocument({
      url: docUrl,
      name: docName,
      type: detectDocumentType(docUrl, docName)
    });
    setLoadingDocument(true);
    setDocumentModalOpen(true);
  };

  const detectDocumentType = (url, fileName) => {
    // Check file extension and MIME type indicators in the URL or filename
    const urlLower = url?.toLowerCase() || '';
    const fileNameLower = fileName?.toLowerCase() || '';
    
    // PDF detection cases
    if (
      urlLower.endsWith('.pdf') || 
      urlLower.includes('application/pdf') ||
      urlLower.includes('documentcloud') ||
      urlLower.includes('firebasestorage') && urlLower.includes('.pdf') ||
      fileNameLower.endsWith('.pdf') ||
      urlLower.includes('%2Fpdf') ||
      urlLower.includes('pdf%')
    ) {
      return 'pdf';
    }
    
    // Image detection
    if (
      /\.(jpe?g|png|gif|bmp|webp|svg)$/i.test(urlLower) ||
      /\.(jpe?g|png|gif|bmp|webp|svg)$/i.test(fileNameLower) ||
      urlLower.includes('image/')
    ) {
      return 'image';
    }
    
    // Video detection
    if (
      /\.(mp4|webm|ogg|mov|avi)$/i.test(urlLower) ||
      /\.(mp4|webm|ogg|mov|avi)$/i.test(fileNameLower) ||
      urlLower.includes('video/')
    ) {
      return 'video';
    }
    
    // Default to a generic document type
    return 'document';
  };

  const handleDownloadDocument = (url, filename) => {
    // Open document in new window
    window.open(url, '_blank');
  };

  const handleImageLoad = (projectId) => {
    setLoadingLogos(prev => ({...prev, [projectId]: false}));
  };

  const handleImageError = (projectId) => {
    setLoadingLogos(prev => ({...prev, [projectId]: false}));
  };

  const handleDocumentLoad = () => {
    setLoadingDocument(false);
  };

  const handleDocumentError = () => {
    setLoadingDocument(false);
  };

  const handleOpenDescription = (description) => {
    setSelectedDescription(description);
    setDescriptionModalOpen(true);
  };

  const handleCloseDescription = () => {
    setDescriptionModalOpen(false);
  };

  // Set initial loading state for all images
  useEffect(() => {
    if (projects.length > 0) {
      const initialLoadingState = projects.reduce((acc, project) => {
        acc[project.id] = project.image ? true : false;
        return acc;
      }, {});
      setLoadingLogos(initialLoadingState);
    }
  }, [projects]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric', 
        month: 'short', 
        day: 'numeric'
      });
    } catch (e) {
      return dateString;
    }
  };

  // Add this function to check document expiration status
  const getExpirationStatus = (endDate) => {
    if (!endDate) return null;
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const expiryDate = new Date(endDate);
    expiryDate.setHours(0, 0, 0, 0);
    
    const timeDiff = expiryDate.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
    if (daysDiff < 0) {
      return { label: "Expired", color: "error" };
    } else if (daysDiff === 0) {
      return { label: "Expires Today", color: "warning" };
    } else if (daysDiff <= 7) {
      return { label: "Expiring Soon", color: "warning" };
    } else {
      return { label: "Active", color: "success" };
    }
  };

  // Helper function to determine chip properties based on status
  const getStatusChipProps = (status) => {
    switch(status) {
      case 'Just Started':
        return { 
          icon: <HourglassEmpty />, 
          color: 'info', 
          variant: 'default'
        };
      case 'Pending':
        return { 
          icon: <PendingActions />, 
          color: 'warning',
          variant: 'default'
        };
      case 'Under Development':
        return { 
          icon: <Engineering />, 
          color: 'primary',
          variant: 'default' 
        };
      case 'Under Maintainance':
        return { 
          icon: <Build />, 
          color: 'secondary',
          variant: 'default'
        };
      case 'Completed':
        return { 
          icon: <TaskAlt />, 
          color: 'success',
          variant: 'default'
        };
      case 'Cancelled':
        return { 
          icon: <Cancel />, 
          color: 'error',
          variant: 'default'
        };
      case 'On Hold':
        return { 
          icon: <Pause />, 
          color: 'default',
          variant: 'outlined'
        };
      default:
        return { 
          icon: <Check />, 
          color: 'success',
          variant: 'default'
        };
    }
  };

  // Add this function to calculate total amounts due across all projects
  const calculateTotalDueAmount = () => {
    if (!projects || projects.length === 0) return { total: 0, currencies: {} };
    
    const currencies = {};
    let totalInDefaultCurrency = 0;
    const defaultCurrency = 'LKR'; // Default currency if conversion is needed
    
    projects.forEach(project => {
      if (project.balanceDue !== undefined && project.balanceDue > 0) {
        const currency = project.currency || defaultCurrency;
        
        if (!currencies[currency]) {
          currencies[currency] = 0;
        }
        currencies[currency] += project.balanceDue;
        
        // For potential future use if currency conversion is implemented
        totalInDefaultCurrency += project.balanceDue; // Would need conversion rate for accurate multi-currency total
      }
    });
    
    return {
      total: totalInDefaultCurrency,
      currencies: currencies
    };
  };

  const renderProjectTabs = () => {
    if (projects.length <= 0) return null;
    
    // Get total amounts due
    const totalDue = calculateTotalDueAmount();
    const hasDueAmount = Object.keys(totalDue.currencies).length > 0;
    
    return (
      <>
        {/* Add summary of total amounts due */}
        {hasDueAmount && (
          <Paper 
            className="bg-base-200 p-4 mb-4"
            sx={{ 
              borderLeft: '4px solid',
              borderColor: theme.palette.error.main,
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <Typography variant="h6" className="text-error font-bold flex items-center">
              <AttachMoney sx={{ mr: 1 }} /> Total Amount Due
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {Object.entries(totalDue.currencies).map(([currency, amount]) => (
                <Chip
                  key={currency}
                  label={`${formatCurrency(amount, currency)}`}
                  color="error"
                  sx={{ fontWeight: 'bold' }}
                />
              ))}
            </Box>
          </Paper>
        )}
        
        <Box 
          className="p-4"
          sx={{ 
            display: 'flex', 
            overflowX: 'auto', 
            gap: 2,
            pb: 1,
            mb: 2,
            '&::-webkit-scrollbar': {
              height: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.light,
              borderRadius: '3px',
            }
          }}
        >
          {projects.map((project, index) => (
            <Card 
              key={project.id} 
              sx={{ 
                minWidth: 220,
                cursor: 'pointer',
                transform: selectedProject === index ? 'scale(1.02)' : 'scale(1)',
                border: selectedProject === index ? `2px solid ${theme.palette.primary.main}` : 'none',
                transition: 'all 0.3s ease',
              }}
              onClick={() => setSelectedProject(index)}
              className={selectedProject === index ? "bg-base-300 hover:scale-105" : "bg-base-200 hover:scale-105"}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  {project.image ? (
                    <Box sx={{ position: 'relative', width: 60, height: 60, mr: 1 }}>
                      {loadingLogos[project.id] && (
                        <Box
                          sx={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            zIndex: 1
                          }}
                        >
                          <CircularProgress size={30} />
                        </Box>
                      )}
                      <Avatar 
                        src={project.image} 
                        alt={project.title || project.id}
                        variant="rounded"
                        sx={{ 
                          width: 60, 
                          height: 60, 
                          visibility: loadingLogos[project.id] ? 'hidden' : 'visible'
                        }}
                        imgProps={{
                          onLoad: () => handleImageLoad(project.id),
                          onError: () => handleImageError(project.id)
                        }}
                      />
                    </Box>
                  ) : (
                    <Avatar 
                      sx={{ width: 60, height: 60, mr: 1, bgcolor: theme.palette.primary.main }}
                      variant="rounded"
                    >
                      <Code />
                    </Avatar>
                  )}
                  <Box>
                    <Typography variant="h6" noWrap sx={{ maxWidth: 150 }}>
                      {project.title || project.id}
                    </Typography>
                    <Chip 
                      icon={project.isActive ? <Check /> : <ErrorOutline />}
                      label={project.isActive ? "Active" : "Inactive"}
                      color={project.isActive ? "success" : "error"}
                      size="small"
                    />
                  </Box>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {project.category || 'Project'}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                  Since {project.year || 'N/A'}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                  Your role: {project.userRole.join(", ")}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </>
    );
  };

  const renderDocumentModal = () => {
    const docType = selectedDocument?.type || 
                   detectDocumentType(selectedDocument?.url, selectedDocument?.name);
    
    return (
      <Dialog
        open={documentModalOpen}
        onClose={() => setDocumentModalOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              {selectedDocument?.name || 'Project Document'}
            </Typography>
            <IconButton
              onClick={() => handleDownloadDocument(selectedDocument?.url, selectedDocument?.name)}
              color="primary"
            >
              <Download />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {selectedDocument?.url && (
            <Box sx={{ position: 'relative', minHeight: '500px' }}>
              {loadingDocument && (
                <Box sx={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  bgcolor: 'rgba(0,0,0,0.05)',
                  zIndex: 1
                }}>
                  <CircularProgress />
                </Box>
              )}
              
              {docType === 'pdf' ? (
                <iframe 
                  src={`${selectedDocument.url}#toolbar=0&view=FitH`} 
                  width="100%" 
                  height="500px" 
                  title="PDF Viewer"
                  style={{ border: 'none' }}
                  onLoad={handleDocumentLoad}
                  onError={handleDocumentError}
                  frameBorder="0"
                />
              ) : docType === 'image' ? (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  bgcolor: 'black',
                  p: 2,
                  borderRadius: 1,
                  minHeight: '500px'
                }}>
                  <img 
                    src={selectedDocument.url} 
                    alt="Project Document" 
                    style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain' }}
                    onLoad={handleDocumentLoad}
                    onError={handleDocumentError}
                  />
                </Box>
              ) : docType === 'video' ? (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  p: 2,
                  borderRadius: 1,
                  minHeight: '500px'
                }}>
                  <video 
                    controls 
                    style={{ maxWidth: '100%', maxHeight: '70vh' }}
                    onLoadedData={handleDocumentLoad}
                    onError={handleDocumentError}
                  >
                    <source src={selectedDocument.url} />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              ) : (
                // Fallback to iframe for any other document type
                <iframe 
                  src={selectedDocument.url} 
                  width="100%" 
                  height="500px" 
                  title="Document Viewer"
                  style={{ border: 'none' }}
                  onLoad={handleDocumentLoad}
                  onError={handleDocumentError}
                  frameBorder="0"
                />
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => handleDownloadDocument(selectedDocument?.url, selectedDocument?.name)}
            startIcon={<Download />}
          >
            Download
          </Button>
          <Button onClick={() => setDocumentModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderDescriptionModal = () => {
    return (
      <Dialog
        open={descriptionModalOpen}
        onClose={handleCloseDescription}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Description</DialogTitle>
        <DialogContent className="bg-base-100">
          <Typography variant="body1" className="text-base-content whitespace-pre-wrap">
            {selectedDescription}
          </Typography>
        </DialogContent>
        <DialogActions className="bg-base-100">
          <Button onClick={handleCloseDescription}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const formatCurrency = (amount, currencyCode = 'LKR') => {
    if (amount === undefined || amount === null) return 'N/A';
    
    try {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode || 'LKR',
        minimumFractionDigits: 2
      }).format(amount);
    } catch (e) {
      return `${currencyCode || 'LKR'} ${amount}`;
    }
  };

  const calculateDiscountAmount = (item) => {
    if (!item.discount || !item.discount.value || !item.unitPrice) return 0;
    
    if (item.discount.type === 'percentage') {
      return (item.unitPrice * item.quantity * item.discount.value) / 100;
    } else {
      return item.discount.value;
    }
  };

  const renderProjectDetails = () => {
    if (projects.length === 0) return null;
    
    const project = projects[selectedProject];
    
    if (!project.isActive) {
      return (
        <Alert severity="info" sx={{ mt: 2 }}>
          <Typography variant="h6">Project Inactive</Typography>
          <Typography>
            The project "{project.title || project.id}" is currently inactive. 
            Please contact the project manager for more information.
          </Typography>
        </Alert>
      );
    }

    return (
      <Card sx={{ mt: 2 }} className="bg-base-200">
        <CardContent className='bg-base-100 text-base-content'>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 3, 
            flexWrap: 'wrap', 
            gap: 2,
            flexDirection: isMobile ? 'column' : 'row'
          }}>
            {project.image && (
              <Box sx={{ position: 'relative', width: isMobile ? 150 : 100, height: isMobile ? 150 : 100 }}>
                {loadingLogos[project.id] && (
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      zIndex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Avatar 
                  src={project.image} 
                  alt={project.title || project.id} 
                  sx={{ 
                    width: isMobile ? 150 : 100, 
                    height: isMobile ? 150 : 100,
                    mr: isMobile ? 0 : 2,
                    visibility: loadingLogos[project.id] ? 'hidden' : 'visible'
                  }}
                  variant="rounded"
                  imgProps={{
                    onLoad: () => handleImageLoad(project.id),
                    onError: () => handleImageError(project.id)
                  }}
                />
              </Box>
            )}
            <Box sx={{ textAlign: isMobile ? 'center' : 'left', width: isMobile ? '100%' : 'auto' }}>
              <Typography variant="h4" gutterBottom component="div" className="text-primary select-text">
                {project.title || project.id}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                justifyContent: isMobile ? 'center' : 'flex-start',
                flexWrap: 'wrap'
              }}>
                <Chip 
                  icon={getStatusChipProps(project.status).icon}
                  label={project.status || "Active"} 
                  color={getStatusChipProps(project.status).color}
                  variant={getStatusChipProps(project.status).variant}
                  size="small" 
                  sx={{ 
                    mr: 1,
                    fontWeight: 'medium',
                    '& .MuiChip-label': { 
                      px: 1 
                    },
                    boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                  }}
                />
                {project.year && (
                  <Typography variant="body2" color="inherit">
                    <span className='font-semibold'>Since {project.year}</span>
                  </Typography>
                )}
                {project.assignedAt && (
                  <Tooltip title="Date you were assigned to this project">
                    <Typography variant="body2" color="inherit" sx={{ ml: 1 }}>
                      Assigned: {formatDate(project.assignedAt)}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
              <Typography variant="body2" color="inherit" sx={{ mt: 1 }}>
                Your role: {project.userRole.join(", ")}
              </Typography>
            </Box>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card variant="outlined" className="bg-base-100" >
                <CardContent className='bg-base-200 text-base-content'>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Category sx={{ mr: 1 }} /> Project Information
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary="Category" 
                        secondary={project.category || "Not specified"}
                        primaryTypographyProps={{ color: 'primary', variant: 'body2' }}
                        secondaryTypographyProps={{ color: 'inherit', variant: 'body1' }}
                      />
                    </ListItem>

                    <Divider component="li" />
                    <ListItem>
                      <ListItemText 
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AccessTime fontSize="small" sx={{ mr: 1 }} /> 
                            Project Duration
                          </Box>
                        }
                        secondary={project.duration || "Not specified"}
                        primaryTypographyProps={{ color: 'primary', variant: 'body2' }}
                        secondaryTypographyProps={{ color: 'inherit', variant: 'body1' }}
                      />
                    </ListItem>

                    <Divider component="li" />
                    <ListItem>
                      <ListItemText 
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Public fontSize="small" sx={{ mr: 1 }} /> 
                            Country
                          </Box>
                        }
                        secondary={project.country || "Not specified"}
                        primaryTypographyProps={{ color: 'primary', variant: 'body2' }}
                        secondaryTypographyProps={{ color: 'inherit', variant: 'body1' }}
                      />
                    </ListItem>

                    <Divider component="li" />
                    <ListItem>
                      <ListItemText 
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Business fontSize="small" sx={{ mr: 1 }} /> 
                            Client Type
                          </Box>
                        }
                        secondary={project.clientType || "Not specified"}
                        primaryTypographyProps={{ color: 'primary', variant: 'body2' }}
                        secondaryTypographyProps={{ color: 'inherit', variant: 'body1' }}
                      />
                    </ListItem>
                    
                    {project.description && (
                      <>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Description fontSize="small" sx={{ mr: 1 }} /> 
                                Description
                              </Box>
                            }
                            secondary={project.description}
                            primaryTypographyProps={{ color: 'primary', variant: 'body2' }}
                            secondaryTypographyProps={{ color: 'inherit', variant: 'body1' }}
                          />
                        </ListItem>
                      </>
                    )}

                    {project.estimatedCost && (
                      <>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AttachMoney fontSize="small" sx={{ mr: 1 }} /> 
                                Budget
                              </Box>
                            }
                            secondary={`${project.estimatedCost} ${project.currency || ''}`}
                            primaryTypographyProps={{ color: 'primary', variant: 'body2' }}
                            secondaryTypographyProps={{ color: 'inherit', variant: 'body1' }}
                          />
                        </ListItem>
                      </>
                    )}

                    {project.link && (
                      <>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Language className='text-blue-700' fontSize="small" sx={{ mr: 1 }} /> 
                                <span className='text-blue-700'> Website/Link</span>
                              </Box>
                            }
                            secondary={
                              <Link className='select-text' href={project.link} target="_blank" rel="noopener">
                                {project.link}
                              </Link>
                            }
                            primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                          />
                        </ListItem>
                      </>
                    )}

                    {project.alias && (
                      <>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Description className='text-blue-700' fontSize="small" sx={{ mr: 1 }} /> 
                                <span className='text-blue-700'>Project Alias</span>
                              </Box>
                            }
                            secondary={project.alias}
                            primaryTypographyProps={{ color: 'primary', variant: 'body2' }}
                            secondaryTypographyProps={{ color: 'inherit', variant: 'body1' }}
                          />
                        </ListItem>
                      </>
                    )}
                  </List>

                  {project.technologies && project.technologies.length > 0 && (
                    <Box mt={2}>
                      <Typography variant="subtitle2" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                        <Code fontSize="small" sx={{ mr: 1 }} /> Technologies Used
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {project.technologies.map((tech, idx) => (
                          <Chip 
                            key={idx} 
                            label={tech} 
                            size="small" 
                            color="primary" 
                            variant="outlined" 
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Card variant="outlined" className="bg-base-100">
                <CardContent className='bg-base-200 text-base-content'>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <People sx={{ mr: 1 }} /> Project Team
                  </Typography>
                  
                  {loadingUsers ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                      <CircularProgress size={30} />
                    </Box>
                  ) : projectUsers[project.id]?.length > 0 ? (
                    <List dense>
                      {projectUsers[project.id].map((user, index) => (
                        <React.Fragment key={user.id || index}>
                          {index > 0 && <Divider component="li" />}
                          <ListItem>
                            <Avatar 
                              src={user.photoURL} 
                              alt={user.name}
                              sx={{ mr: 2, width: 40, height: 40 }}
                            >
                              <Person />
                            </Avatar>
                            <ListItemText
                              primary={user.name}
                              secondary={
                                <Box>
                                  <div className='flex w-full flex-col gap-1'>
                                  {user.email && (
                                    <Typography variant="body2" component="span">
                                      <a className='text-blue-500 hover:text-blue-600 hover:underline' href={`mailto:${user.email}`} target='_blank'>{user.email}</a>
                                    </Typography>
                                  )}
                                     {user.username && (
                                    <Typography variant="body2" component="span">
                                      <a className='text-blue-500 hover:text-blue-600 hover:underline' href={`https://officialkasun.com/u/${user.username}`} target='_blank'>@{user.username}</a>
                                    </Typography>
                                  )}
                                  </div>
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                                    {user.role?.map((role, idx) => (
                                      <Chip 
                                        key={idx} 
                                        label={role} 
                                        size="small" 
                                        variant="outlined"
                                        color='primary'
                                        sx={{ fontSize: '0.7rem' }}
                                      />
                                    ))}
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
                      No team members found for this project.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            
            {/* Add financial information section */}
            {(project.charges && project.charges.length > 0) || 
              project.amountPaid !== undefined || 
              project.balanceDue !== undefined || 
              project.totalAmount !== undefined ? (
              <Grid item xs={12}>
                <Card variant="outlined" className="bg-base-100 mt-2">
                  <CardContent className='bg-base-200 text-base-content'>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      <AttachMoney sx={{ mr: 1 }} /> Financial Information
                    </Typography>
                    
                    {/* Summary of financial data */}
                    <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      {project.totalAmount !== undefined && (
                        <Paper sx={{ p: 2, flex: 1, minWidth: 180 }} className="bg-base-100">
                          <Typography variant="subtitle2" color="text.secondary">Total Amount</Typography>
                          <Typography variant="h6" className="font-bold text-primary">
                            {formatCurrency(project.totalAmount, project.currency)}
                          </Typography>
                        </Paper>
                      )}
                      
                      {project.amountPaid !== undefined && (
                        <Paper sx={{ p: 2, flex: 1, minWidth: 180 }} className="bg-base-100">
                          <Typography variant="subtitle2" color="text.secondary">Amount Paid</Typography>
                          <Typography variant="h6" className="font-bold text-success">
                            {formatCurrency(project.amountPaid, project.currency)}
                          </Typography>
                        </Paper>
                      )}
                      
                      {project.balanceDue !== undefined && (
                        <Paper sx={{ p: 2, flex: 1, minWidth: 180 }} className="bg-base-100">
                          <Typography variant="subtitle2" color="text.secondary">Balance Due</Typography>
                          <Typography variant="h6" className={`font-bold ${project.balanceDue > 0 ? 'text-error' : 'text-success'}`}>
                            {formatCurrency(project.balanceDue, project.currency)}
                          </Typography>
                        </Paper>
                      )}

                      {project.totalDiscount !== undefined && project.totalDiscount > 0 && (
                        <Paper sx={{ p: 2, flex: 1, minWidth: 180 }} className="bg-base-100">
                          <Typography variant="subtitle2" color="text.secondary">Total Discount</Typography>
                          <Typography variant="h6" className="font-bold text-info">
                            {formatCurrency(project.totalDiscount, project.currency)}
                          </Typography>
                        </Paper>
                      )}
                    </Box>
                    
                    {/* Itemized charges table */}
                    {project.charges && project.charges.length > 0 && (
                      <>
                        <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                          Itemized Charges
                        </Typography>
                        <Box sx={{ overflowX: 'auto' }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell><Typography variant="subtitle2"><span className='text-blue-600' >Item </span></Typography></TableCell>
                                <TableCell><Typography variant="subtitle2"><span className='text-blue-600' >Description </span></Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle2"><span className='text-blue-600' >Quantity </span></Typography></TableCell>
                                <TableCell align="right"><Typography variant="subtitle2"><span className='text-blue-600' >Unit Price </span></Typography></TableCell>
                                <TableCell align="right"><Typography variant="subtitle2"><span className='text-blue-600' >Discount </span></Typography></TableCell>
                                <TableCell align="right"><Typography variant="subtitle2"><span className='text-blue-600' >Subtotal </span></Typography></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {project.charges.map((charge) => {
                                const discountAmount = calculateDiscountAmount(charge);
                                return (
                                  <TableRow key={charge.id}>
                                    <TableCell>
                                      <Typography variant="body2"><span className='text-base-content'>{charge.item}</span></Typography>
                                      {charge.nextDueDate && (
                                        <Typography variant="caption" color="text.secondary">
                                          <span className='text-red-500 font-semibold italic'>Next due: {formatDate(charge.nextDueDate)}</span>
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2" className="text-base-content" sx={{ maxWidth: 250 }}>
                                        {charge.description && charge.description.length > 100 ? (
                                          <>
                                            {charge.description.substring(0, 100)}...{' '}
                                            <Link 
                                              component="button"
                                              variant="body2"
                                              onClick={() => handleOpenDescription(charge.description)}
                                              className="text-blue-500 hover:text-blue-700 hover:underline"
                                            >
                                              See more
                                            </Link>
                                          </>
                                        ) : (
                                          charge.description
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography variant="body2"> <span className='text-base-content' >{charge.quantity} </span></Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Typography variant="body2">
                                      <span className='text-base-content' >{formatCurrency(charge.unitPrice, project.currency)}</span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      {charge.discount && charge.discount.value ? (
                                        <Typography variant="body2">
                                          <span className='text-base-content' >{charge.discount.type === 'percentage' 
                                            ? `${charge.discount.value}%` 
                                            : formatCurrency(charge.discount.value, project.currency)} </span>
                                        </Typography>
                                      ) : (
                                        <Typography variant="body2">-</Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="right">
                                      <Typography variant="body2" className="font-medium">
                                      <span className='text-green-500' >{formatCurrency(charge.subtotal, project.currency)}</span>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {/* Summary row */}
                              <TableRow>
                                <TableCell colSpan={4} />
                                <TableCell align="right">
                                  <Typography variant="subtitle2"> <span className='text-base-content' >Total:</span></Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography variant="subtitle2" className="font-bold">
                                  <span className='text-green-700' >{formatCurrency(project.totalAmount, project.currency)}</span>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ) : null}

            {/* Document section - updated to use documents array */}
            <Grid item xs={12}>
              <Card variant="outlined" className="bg-base-100 mt-2">
                <CardContent className='bg-base-200 text-base-content'>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Description sx={{ mr: 1 }} /> Project Documents
                  </Typography>
                  
                  {(project.documents && project.documents.length > 0) ? (
                    <List dense>
                      {project.documents.map((doc, index) => (
                        <React.Fragment key={doc.id || index}>
                          {index > 0 && <Divider component="li" />}
                          <ListItem
                            secondaryAction={
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <Tooltip title="View Document">
                                  <IconButton edge="end" onClick={() => handleDocumentView(
                                    doc.link, 
                                    doc.title || doc.fileName || 'Document'
                                  )}>
                                    <Visibility className='text-base-content' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Download Document">
                                  <IconButton edge="end" onClick={() => handleDownloadDocument(
                                    doc.link, 
                                    doc.fileName || doc.title || 'document'
                                  )}>
                                    <Download className='text-base-content' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography variant="body1" className="font-medium">
                                  {doc.title || doc.fileName || 'Untitled Document'}
                                </Typography>
                              }
                              secondary={
                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 0.5 }}>
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                    {doc.issueDate && (
                                      <Typography variant="caption">
                                        <span className='text-base-content'>Issued: {formatDate(doc.issueDate)}</span>
                                      </Typography>
                                    )}
                                    {doc.uploadDate && (
                                      <Typography variant="caption">
                                        <span className='text-base-content'>Uploaded: {formatDate(doc.uploadDate)}</span>
                                      </Typography>
                                    )}
                                    {doc.endDate && (
                                      <Typography variant="caption">
                                        <span className='text-base-content'>Valid Until: {formatDate(doc.endDate)}</span>
                                      </Typography>
                                    )}
                                    {doc.fileSize && (
                                      <Typography variant="caption">
                                        <span className='text-base-content'>Size: {(doc.fileSize / 1024 / 1024).toFixed(2)} MB</span>
                                      </Typography>
                                    )}
                                  </Box>
                                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 0.5 }}>
                                    <Chip 
                                      size="small" 
                                      label={doc.isUrl ? 'External URL' : (doc.fileType || doc.type || 'File')} 
                                      color={doc.isUrl ? 'info' : 'primary'}
                                      variant="outlined"
                                    />
                                    {doc.endDate && (() => {
                                      const status = getExpirationStatus(doc.endDate);
                                      return (
                                        <Chip 
                                          size="small" 
                                          label={status.label} 
                                          color={status.color} 
                                          variant="outlined"
                                        />
                                      );
                                    })()}
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </List>
                  ) : project.documentUrl ? (
                    <Box sx={{ mt: 1 }}>
                      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                        <Button 
                          variant="outlined" 
                          startIcon={<Visibility />}
                          onClick={() => handleDocumentView(
                            project.documentUrl, 
                            project.documentName || 'Project Documentation'
                          )}
                        >
                          View
                        </Button>
                        <Button 
                          variant="outlined" 
                          startIcon={<Download />}
                          onClick={() => handleDownloadDocument(
                            project.documentUrl, 
                            project.documentName || 'Project Documentation'
                          )}
                        >
                          Download
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
                      <span className='text-red-500'>No documents available for this project.</span>
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography variant="h5" className="text-base-content">
          My {projects.length > 1 ? 'Projects' : 'Project'}
        </Typography>
        
        <Button 
          variant="outlined" 
          onClick={fetchProjectData}
          disabled={loading}
        >
          Refresh
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }} className="bg-base-200">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="info" sx={{ mt: 2 }}>
          {error}
        </Alert>
      ) : (
        <>
          {renderProjectTabs()}
          {renderProjectDetails()}
        </>
      )}
      
      {renderDocumentModal()}
      {renderDescriptionModal()}
    </Box>
  );
};

export default MyProjects;
