import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import { FaExclamationTriangle, FaSearch, FaCode, FaTimes } from 'react-icons/fa';

const AllProjects = ({ mode, count }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [fixedSearch, setFixedSearch] = useState('');
  const [imageLoading, setImageLoading] = useState({});

  useEffect(() => {
    fetchProjects();
  }, []);
  const fetchProjectsx = async (term = '') => {
    setLoading(true);
    setFixedSearch(term);
    try {
        let q = collection(firestore, 'projects');
        if (term) {
            const termLower = term.toLowerCase();
            q = query(q, where('lowerTitle', '>=', termLower), where('lowerTitle', '<=', termLower + '\uf8ff'));
        }
        const querySnapshot = await getDocs(q);
        const projectsList = querySnapshot.docs.map(doc => doc.data());
        setProjects(projectsList);
    } catch (error) {
        console.error("Error fetching projects:", error);
    } finally {
        setLoading(false);
    }
};
const fetchProjects = async (term = '') => {
    setLoading(true);
    setFixedSearch(term);
    try {
        let q = collection(firestore, 'projects');
        
        // Filter for active AND public projects
        q = query(q, where('isActive', '==', true), where('isPublic', '==', true));
        
        // Add search term filter if provided
        if (term) {
            const termLower = term.toLowerCase();
            q = query(q, where('lowerTitle', '>=', termLower), where('lowerTitle', '<=', termLower + '\uf8ff'));
        }
        
        const querySnapshot = await getDocs(q);
        const projectsList = querySnapshot.docs.map(doc => doc.data());
        setProjects(projectsList);
    } catch (error) {
        console.error("Error fetching projects:", error);
    } finally {
        setLoading(false);
    }
};

  const searchProject = () => {
    fetchProjects(searchTerm);
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchProject();
    }
  };

  const handleImageLoad = (projectId) => {
    setImageLoading(prev => ({ ...prev, [projectId]: false }));
  };

  const handleImageError = (projectId) => {
    setImageLoading(prev => ({ ...prev, [projectId]: false }));
  };

  const displayedProjects = count ? projects.slice(0, count) : projects;

  const searchBar = () => (
    <div className="w-full mb-8">
      <div className="max-w-md mx-auto relative">
        <input
          type="text"
          placeholder="Browse Projects..."
          className="w-full px-4 py-3 pl-12 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary input input-bordered"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
          <FaSearch className="w-5 h-5 text-gray-400" />
        </div>
        
        {searchTerm && (
          <button
            onClick={() => {
              setSearchTerm('');
              fetchProjects('');
            }}
            className="absolute inset-y-0 right-12 flex items-center px-2 text-gray-400 hover:text-gray-600 transition-colors"
            aria-label="Clear search"
          >
            <FaTimes className="w-4 h-4" />
          </button>
        )}
        
        <button
          onClick={searchProject}
          className="absolute inset-y-0 right-2 flex items-center px-2 text-primary hover:text-primary-focus transition-colors"
          aria-label="Search projects"
        >
          <div className="bg-primary/10 p-1 rounded-full">
            <FaSearch className="w-4 h-4" />
          </div>
        </button>
      </div>
      <div className='mt-2 text-sm text-red-600 italic w-full justify-center text-center items-center'>
        {fixedSearch ? 
          <span>Showing results for "<strong>{fixedSearch}</strong>" - <button onClick={() => fetchProjects('')} className="text-primary hover:underline">clear search</button></span> : 
          'Explore our publicly available projects.'}
      </div>
    </div>
  );

  if (loading) {
    return (
      <>
          {mode !== 'nosearch' && searchBar()}
      <div className=" flex items-center justify-center">
        <span className="loading loading-spinner loading-lg text-red-500"></span>
      </div>
      </>
    );
  }

  if (!loading && projects.length === 0) {
    return (
      <>
        {mode !== 'nosearch' && searchBar()}
        <div className=" flex flex-col items-center justify-center gap-4 p-4">
          <FaExclamationTriangle className="text-6xl text-error animate-pulse" />
          <h2 className="text-3xl font-bold text-center">No Projects Found</h2>
          <p className="text-center text-lg text-gray-600">
            It seems there are no available projects { fixedSearch && "under the \"" +fixedSearch.toString() + "\""} at the moment.  </p>
        </div>
      </>
    );
  }

return (
    <div className="w-full flex flex-wrap gap-8 p-4 justify-center">
        {mode !== 'nosearch' && searchBar()}
      
        {displayedProjects.map((project, index) => {
            // Set initial loading state for this project's image
            if (project.image && imageLoading[project.id] === undefined) {
                setImageLoading(prev => ({ ...prev, [project.id]: true }));
            }
            
            return (
                <div 
                    key={index}
                    className="card bg-base-100 rounded-lg shadow-lg overflow-hidden p-6 transition-all duration-300 hover:scale-105 w-full sm:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)]"
                >
                    <div className='flex flex-col gap-3'>
                        <div className="flex items-center justify-center h-32 relative">
                            {project.image ? (
                                <>
                                    {imageLoading[project.id] && (
                                        <div className="absolute inset-0 flex justify-center items-center">
                                            <span className="loading loading-spinner loading-md text-primary"></span>
                                        </div>
                                    )}
                                    <img
                                        src={project.image}
                                        alt={project.title}
                                        className={`w-32 p-2 object-cover border-4 border-primary rounded-md ${imageLoading[project.id] ? 'opacity-0' : 'opacity-100'}`}
                                        onLoad={() => handleImageLoad(project.id)}
                                        onError={() => handleImageError(project.id)}
                                    />
                                </>
                            ) : (
                                <div className="flex justify-center items-center w-32 h-32 border-4 border-primary rounded-md bg-base-200">
                                    <FaCode className="text-4xl text-primary" />
                                </div>
                            )}
                        </div>
                        <div className="text-center">
                            <h2 className="text-xl font-semibold text-base-content">{project.title}</h2>
                            <p className="text-gray-500 mt-2 line-clamp-3">{project.description}</p>
                        </div>
                        <div className="text-center">
                            <h2 className="text-xl text-blue-400 font-semibold">Used Technologies</h2>
                            <p className="text-orange-700 font-semibold mt-2 line-clamp-3">{project.technologies.join(', ')}</p>
                        </div>
                        <div className="text-center flex flex-row justify-center items-center gap-2 w-full">
                            <div className="text-xl text-yellow-600 font-semibold">Since : </div>
                            <div className="text-red-600 text-xl font-semibold line-clamp-3">{project.year}</div>
                        </div>
                        <div className="flex justify-center">
                            <a
                                href={project.link} target='_blank' rel='noreferrer'
                                className="btn btn-primary mt-4 px-8 hover:bg-primary-focus transition-colors"
                            >
                                View Project
                            </a>
                        </div>
                    </div>
                </div>
            );
        })}
    </div>
);
};

export default AllProjects;