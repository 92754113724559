import * as React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';

import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MenuIcon from '@mui/icons-material/Menu';
import MyBusiness from './client/MyBusiness';
import MyProducts from './client/MyProducts';


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  { icon: <MenuIcon />, name: 'Menu', code: 'menu' },

];

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(null);
  const [direction, setDirection] = React.useState('down');
  const [hidden, setHidden] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Common menu items array used in both drawer and grid
  const menuItems = [
    { text: 'My Business', icon: <StoreIcon />, code: 'my-business', description: 'Manage your businesses' },
    { text: 'My Products', icon: <InventoryIcon />, code: 'my-products', description: 'Manage your products' },
    // Add other menu items here as needed
  ];

  useEffect(() => {
    const page = searchParams.get('page');
    const board = searchParams.get('board');
    if (page && board === 'client') {
      setCurrentPage(page);
    }
  }, [searchParams]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuClick = (text) => () => {
    switch (text) {
      case 'menu':
        toggleDrawer(true)();
        break;
   
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const handleClick = (text) => () => {
    switch (text) {
      case 'my-business':
      case 'my-products':
        setCurrentPage(text);
        setSearchParams({ board: 'client', page: text });
        break;
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const renderPage = () => {
    if (currentPage === 'my-business') {
      return <MyBusiness />;
    }

    else if (currentPage === 'my-products') {
      return <MyProducts />;
    }
    else {
      return null;
    }
  };

  const DrawerList = (
    <div className="bg-base-300 text-base-content h-screen">
      
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={item.text} disablePadding className='hover:scale-105 duration-500 transition-all ease-in-out hover:font-semibold'>
              <ListItemButton onClick={handleClick(item.code)}>
                <ListItemIcon>
                  {React.cloneElement(item.icon, { className: 'text-base-content' })}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    </div>
  );

  return (

    <div>
      <div className='z-20 w-full fixed flex justify-start items-center top-[230px] left-20 '> 
      <Box sx={{ position: 'absolute', mt: 3, height: 320 }}>
        <StyledSpeedDial
          ariaLabel="Admin Buddy"
          hidden={hidden}
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          direction={direction}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              
              onClick={handleMenuClick(action.code)}
            />
          ))}
        </StyledSpeedDial>
      </Box>
      </div>
    <div className="flex justify-center items-center">
     
      {currentPage === null ? (
        <div className='w-full flex flex-col gap-4'>
          <div className='flex flex-col justify-center items-center gap-5 mt-5'>
          <h1 className="text-3xl font-bold">Welcome! </h1>
          <p className="text-lg">Welcome to the Client Dashboard!</p>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            onClick={handleMenuClick('menu')}
            tabIndex={-1}
            startIcon={<MenuIcon />}
          > Client Menu
          </Button>
        </div>
        
        <div id='client-options' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6'>
          {menuItems.map((item) => (
            <div 
              key={item.text} 
              onClick={handleClick(item.code)}
              className='bg-base-100 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center gap-3 cursor-pointer hover:shadow-xl hover:scale-105 duration-300 transition-all ease-in-out border border-base-300'
            >
              <div className='p-3 rounded-full bg-base-200'>
                {React.cloneElement(item.icon, { className: 'text-primary text-4xl' })}
              </div>
              <h3 className='text-xl font-semibold'>{item.text}</h3>
              <p className='text-sm text-center text-base-content/70'>{item.description || `Manage your ${item.text.toLowerCase()}`}</p>
            </div>
          ))}
        </div>
        </div>
      ) : (
        <div className='w-full'>{renderPage()}</div>
      )}

      </div>

      <div>
          
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </div>
     
    </div>
  );
}
