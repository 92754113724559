import * as React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ManageUsers from './admin/ManageUsers';
import ManageCourses from './admin/ManageCourses';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import InventoryIcon from '@mui/icons-material/Inventory';
import PaymentsIcon from '@mui/icons-material/Payments';
import MenuIcon from '@mui/icons-material/Menu';
import ManageProjects from './admin/ManageProjects';
import ManageServices from './admin/ManageServices';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ManagePayments from './admin/ManagePayments';
import ManageClients from './admin/ManageClients';
import Person4Icon from '@mui/icons-material/Person4';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  { icon: <MenuIcon />, name: 'Menu', code: 'menu' },
];

// Common menu items array for drawer and grid
const menuItems = [
  { text: 'Manage Users', icon: <PeopleIcon className='text-base-content' />, code: 'manage-users', description: 'Manage your users' },
  { text: 'Manage Courses', icon: <LibraryBooksIcon className='text-base-content' />, code: 'manage-courses', description: 'Manage your courses' },
  { text: 'Manage Projects', icon: <InventoryIcon className='text-base-content' />, code: 'manage-projects', description: 'Manage your projects' },
  { text: 'Manage Services', icon: <MiscellaneousServicesIcon className='text-base-content' />, code: 'manage-services', description: 'Manage your services' },
  { text: 'Manage Payments', icon: <PaymentsIcon className='text-base-content' />, code: 'manage-payments', description: 'Manage your payments' },
  { text: 'Manage Clients', icon: <Person4Icon className='text-base-content' />, code: 'manage-clients', description: 'Manage your clients' },
];

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(null);
  const [direction, setDirection] = React.useState('down');
  const [hidden, setHidden] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get('page');
    const board = searchParams.get('board');
    if (page && board === 'admin') {
      setCurrentPage(page);
    }
  }, [searchParams]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuClick = (text) => () => {
    switch (text) {
      case 'menu':
        toggleDrawer(true)();
        break;
   
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const handleClick = (text) => () => {
    switch (text) {
      case 'manage-users':
      case 'manage-courses':
      case 'manage-projects':
      case 'manage-services':
      case 'manage-payments':
      case 'manage-clients':
        setCurrentPage(text);
        setSearchParams({ board: 'admin', page: text });
        break;
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const renderPage = () => {
    if (currentPage === 'manage-users') {
      return <ManageUsers />;
    } else if (currentPage === 'manage-courses') {
      return <ManageCourses />;
    } else if (currentPage === 'manage-projects') {
      return <ManageProjects />;
    }
    else if (currentPage === 'manage-services') {
      return <ManageServices />;
    }

    else if (currentPage === 'manage-payments') {
      return <ManagePayments />;
    }

    else if (currentPage === 'manage-clients') {
      return <ManageClients />;
    }
    else {
      return null;
    }
  };

  const DrawerList = (
    <div className="bg-base-300 text-base-content h-screen">
      
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={item.text} disablePadding className='hover:scale-105 duration-500 transition-all ease-in-out hover:font-semibold'>
              <ListItemButton onClick={handleClick(item.code)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    </div>
  );

  return (

    <div>
      <div className='z-20 w-full fixed flex justify-start items-center top-[230px] left-20 '> 
      <Box sx={{ position: 'absolute', mt: 3, height: 320 }}>
        <StyledSpeedDial
          ariaLabel="Admin Buddy"
          hidden={hidden}
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          direction={direction}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              
              onClick={handleMenuClick(action.code)}
            />
          ))}
        </StyledSpeedDial>
      </Box>
      </div>
    <div className="flex justify-center items-center">
     
      {currentPage === null ? (
        <div>
        <div className='flex flex-col justify-center items-center gap-5 m-5'>
          <h1 className="text-3xl font-bold">Admin Dashboard</h1>
          <p className="text-lg">Welcome to the Admin Dashboard!</p>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            onClick={handleMenuClick('menu')}
            tabIndex={-1}
            startIcon={<MenuIcon />}
          > Admin Menu
          </Button>
        </div>
         <div id='admin-options' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6'>
         {menuItems.map((item) => (
           <div 
             key={item.text} 
             onClick={handleClick(item.code)}
             className='bg-base-100 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center gap-3 cursor-pointer hover:shadow-xl hover:scale-105 duration-300 transition-all ease-in-out border border-base-300'
           >
             <div className='p-3 rounded-full bg-base-200'>{item.icon}</div>
             <h3 className='text-xl font-semibold'>{item.text}</h3>
             <p className='text-sm text-center text-base-content/70'>{item.description}</p>
           </div>
         ))}
       </div>
       </div>
      ) : (
        <div className='w-full'>{renderPage()}</div>
      )}

      </div>

      <div>
          
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </div>
     
    </div>
  );
}
