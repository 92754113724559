import React, { useState, useEffect } from 'react';
import { firestore, storage, auth } from '../../../firebaseConfig';
import { collection, setDoc, doc, getDocs, query, where, updateDoc, arrayUnion, arrayRemove, addDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import {
  TextField, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip, FormControlLabel, Switch,
  MenuItem, Select, InputLabel, FormControl, Autocomplete, Tabs, Tab, Chip, Grid, List, 
  ListItem, ListItemText, ListItemSecondaryAction, Card, CardContent, CardMedia, CardActions,
  FormHelperText, Badge
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { toast } from 'react-toastify';

// Tab Panel Component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// File Preview Dialog Component
function FilePreviewDialog({ open, onClose, fileUrl, fileType }) {
  const isPdf = fileType === 'application/pdf' || fileUrl?.endsWith('.pdf');
  const isImage = fileType.startsWith('image/');
  const isDoc = fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  const handleDownload = () => {
    window.open(fileUrl, '_blank');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(fileUrl);
    toast.success('Link copied to clipboard');
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        File Preview
        <IconButton onClick={handleCopyLink} title="Copy link">
          <ContentCopyIcon />
        </IconButton>
        <IconButton onClick={handleDownload} title="Download">
          <FileDownloadIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isPdf ? (
          <iframe 
            src={`${fileUrl}#toolbar=0&navpanes=0`} 
            width="100%" 
            height="500px" 
            title="PDF Preview" 
            style={{border: 'none'}}
          />
        ) : isImage ? (
          <img 
            src={fileUrl} 
            alt="Document Preview" 
            style={{maxWidth: '100%', maxHeight: '500px', display: 'block', margin: '0 auto'}} 
          />
        ) : isDoc ? (
          <iframe 
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`} 
            width="100%" 
            height="500px" 
            title="Document Preview" 
            style={{border: 'none'}}
          />
        ) : (
          <Typography variant="body1">Unsupported file type for preview</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const ManageClientsAndBusinesses = () => {
  const [tabValue, setTabValue] = useState(0);
  const [clients, setClients] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [editClient, setEditClient] = useState(null);
  const [editBusiness, setEditBusiness] = useState(null);
  const [newBusiness, setNewBusiness] = useState({});
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [projectRole, setProjectRole] = useState([]);
  const [businessRole, setBusinessRole] = useState([]);
  const [customRoleDialogOpen, setCustomRoleDialogOpen] = useState(false);
  const [customRole, setCustomRole] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [editingProjectRole, setEditingProjectRole] = useState(null);
  const [editingBusinessRole, setEditingBusinessRole] = useState(null);
  const [editProjectRole, setEditProjectRole] = useState([]);
  const [editBusinessRole, setEditBusinessRole] = useState([]);

  // File management state
  const [businessLogo, setBusinessLogo] = useState(null);
  const [brDocument, setBrDocument] = useState(null);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState({ url: '', type: '' });
  
  // Business emails and phones
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  
  // Business category state
  const [customCategoryDialogOpen, setCustomCategoryDialogOpen] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  
  // Client table state
  const [clientOrder, setClientOrder] = useState('asc');
  const [clientOrderBy, setClientOrderBy] = useState('name');
  const [selectedClients, setSelectedClients] = useState([]);
  const [clientPage, setClientPage] = useState(0);
  const [clientRowsPerPage, setClientRowsPerPage] = useState(5);
  const [clientDense, setClientDense] = useState(false);
  const [clientSearchKeyword, setClientSearchKeyword] = useState('');
  
  // Business table state
  const [businessOrder, setBusinessOrder] = useState('asc');
  const [businessOrderBy, setBusinessOrderBy] = useState('name');
  const [selectedBusinessesRows, setSelectedBusinessesRows] = useState([]);
  const [businessPage, setBusinessPage] = useState(0);
  const [businessRowsPerPage, setBusinessRowsPerPage] = useState(5);
  const [businessDense, setBusinessDense] = useState(false);
  const [businessSearchKeyword, setBusinessSearchKeyword] = useState('');

  const roles = ['admin', 'billing', 'technical', 'owner', 'other'];
  
  const businessRoles = [
    'CEO', 'Founder', 'Co-Founder', 'CTO', 'CFO', 'COO', 
    'Director', 'VP', 'Manager', 'Team Lead', 'Developer',
    'Designer', 'Product Manager', 'Project Manager',
    'Marketing Manager', 'Sales Manager', 'HR Manager',
    'Consultant', 'Contractor', 'Advisor', 'Investor',
    'Board Member', 'Chairman', 'Secretary', 'Treasurer',
    'other'
  ];

  // Business categories
  const businessCategories = [
    'Accounting & Finance', 'Agriculture', 'Automotive', 'Banking',
    'Biotechnology', 'Construction', 'Consulting', 'Education',
    'Electronics', 'Energy & Utilities', 'Entertainment', 'Fashion',
    'Food & Beverage', 'Gaming', 'Government', 'Healthcare',
    'Hospitality', 'Information Technology', 'Insurance', 'Legal Services',
    'Manufacturing', 'Marketing & Advertising', 'Media', 'Mining',
    'Non-profit', 'Pharmaceuticals', 'Real Estate', 'Retail',
    'Shipping & Logistics', 'Telecommunications', 'Transportation',
    'Travel & Tourism', 'Wholesale', 'other'
  ];

  // Handle custom role addition
  const handleAddCustomRole = () => {
    if (customRole.trim() !== '') {
      setBusinessRole([...businessRole, customRole.trim()]);
      setCustomRole('');
      setCustomRoleDialogOpen(false);
    }
  };

  // Handle custom category addition
  const handleAddCustomCategory = () => {
    if (customCategory.trim() !== '') {
      setEditBusiness({
        ...editBusiness,
        business_category: customCategory.trim()
      });
      setCustomCategory('');
      setCustomCategoryDialogOpen(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchClients = async () => {
    const querySnapshot = await getDocs(query(collection(firestore, 'users'), where('role', 'array-contains', 'client')));
    const clientsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setClients(clientsList);
  };

  const fetchProjects = async () => {
    const querySnapshot = await getDocs(collection(firestore, 'projects'));
    const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProjects(projectsList);
  };

  const fetchBusinesses = async () => {
    const querySnapshot = await getDocs(collection(firestore, 'businesses'));
    const businessesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setBusinesses(businessesList);
  };

  useEffect(() => {
    fetchClients();
    fetchProjects();
    fetchBusinesses();
  }, []);

  // File handling functions
  const handleFileChange = (event, type) => {
    if (!event.target.files || event.target.files.length === 0) return;
    
    const file = event.target.files[0];
    
    if (type === 'logo') {
      setBusinessLogo(file);
      // Create a preview
      const reader = new FileReader();
      reader.onload = () => {
        setEditBusiness({
          ...editBusiness,
          logo_preview: reader.result
        });
      };
      reader.readAsDataURL(file);
    } else if (type === 'br') {
      setBrDocument(file);
      // Store file information
      setEditBusiness({
        ...editBusiness,
        br_document_name: file.name,
        br_document_type: file.type
      });
    }
  };

  // Add a function to check authentication and refresh token if needed
  const ensureAuthenticated = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      toast.error('You are not logged in. Please sign in again.');
      console.error('User is not logged in.');
      // Redirect to login page or handle authentication error
      return false;
    }
    
    try {
      // Force token refresh to ensure we have a fresh one
      await currentUser.getIdToken(true);
      console.log('Token refreshed successfully.');
      return true;
    } catch (error) {
      toast.error('Authentication error: ' + error.message);
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  // Modify handleFileUpload to use ensureAuthenticated
  const handleFileUpload = async (file, businessId, type) => {
    if (!file) return null;
    
    try {
      // Check authentication before proceeding
      const isAuthenticated = await ensureAuthenticated();
      if (!isAuthenticated) return null;
      
      const folderPath = type === 'logo' ? 'business_logo' : 'br_docs';
      const fileExtension = file.name.split('.').pop();
      const fileName = `${businessId}_${Date.now()}.${fileExtension}`;
      const storageRef = ref(storage, `${folderPath}/${fileName}`);
      
      // Get fresh token before upload
      const idToken = await auth.currentUser.getIdToken(true);
      console.log('Uploading file with token:', idToken);
      
      // Upload with authentication metadata
      await uploadBytes(storageRef, file, {
        customMetadata: {
          userId: auth.currentUser.uid,
          uploadedAt: new Date().toISOString()
        }
      });
      
      const downloadUrl = await getDownloadURL(storageRef);
      
      return {
        url: downloadUrl,
        path: `${folderPath}/${fileName}`,
        name: file.name,
        type: file.type
      };
    } catch (error) {
      console.error("Upload error:", error);
      
      // Provide more specific error messages
      if (error.code === 'storage/unauthenticated') {
        toast.error('Authentication error: Please sign in again');
      } else if (error.code === 'storage/unauthorized') {
        toast.error('You do not have permission to upload files');
      } else {
        toast.error(`Error uploading ${type}: ${error.message}`);
      }
      
      return null;
    }
  };

  // Modify handleDeleteFile to use ensureAuthenticated
  const handleDeleteFile = async (filePath) => {
    if (!filePath) return;
    
    try {
      // Check authentication before proceeding
      const isAuthenticated = await ensureAuthenticated();
      if (!isAuthenticated) return;
      
      const fileRef = ref(storage, filePath);
      await deleteObject(fileRef);
      toast.success('File deleted successfully');
    } catch (error) {
      console.error("Delete error:", error);
      
      // Provide more specific error messages
      if (error.code === 'storage/unauthenticated') {
        toast.error('Authentication error: Please sign in again');
      } else if (error.code === 'storage/unauthorized') {
        toast.error('You do not have permission to delete this file');
      } else if (error.code === 'storage/object-not-found') {
        toast.warning('File has already been deleted or does not exist');
      } else {
        toast.error(`Error deleting file: ${error.message}`);
      }
    }
  };

  // Business CRUD operations
  const handleCreateBusiness = async () => {
    try {
      setIsUpdating(true);
      // Check authentication before proceeding
      if (!(await ensureAuthenticated())) return;
      
      // Use editBusiness instead of newBusiness since our form updates editBusiness
      if (!editBusiness.name || editBusiness.name.trim() === '') {
        toast.error('Business name is required');
        return;
      }
      
      const businessData = {
        ...editBusiness,
        emails: editBusiness.emails || [],
        phones: editBusiness.phones || [],
        active: editBusiness.active !== false, // Default to true if not set
        createdAt: new Date().toISOString(),
        createdBy: auth.currentUser.uid // Add the creator's ID
      };
      
      // Remove preview data if exists
      delete businessData.logo_preview;
      
      const businessDoc = await addDoc(collection(firestore, 'businesses'), businessData);
      
      // Upload files if any
      if (businessLogo) {
        const logoData = await handleFileUpload(businessLogo, businessDoc.id, 'logo');
        if (logoData) {
          await updateDoc(doc(firestore, 'businesses', businessDoc.id), {
            logo_url: logoData.url,
            logo_path: logoData.path
          });
        }
      }
      
      if (brDocument) {
        const brData = await handleFileUpload(brDocument, businessDoc.id, 'br');
        if (brData) {
          await updateDoc(doc(firestore, 'businesses', businessDoc.id), {
            br_document_url: brData.url,
            br_document_path: brData.path,
            br_document_name: brData.name,
            br_document_type: brData.type
          });
        }
      }
      
      setEditBusiness(null);
      setBusinessLogo(null);
      setBrDocument(null);
      toast.success('Business created successfully');
      fetchBusinesses();
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      toast.error('Error creating business: ' + error.message);
    }
  };

  const handleUpdateBusiness = async () => {
    try {
      setIsUpdating(true);
      // Check authentication before proceeding
      if (!(await ensureAuthenticated())) return;
      
      const businessId = editBusiness.id;
      const businessData = {
        ...editBusiness,
        emails: editBusiness.emails || [],
        phones: editBusiness.phones || [],
        active: editBusiness.active !== false, // Default to true if not set
        updatedAt: new Date().toISOString()
      };
      
      // Remove any UI-only properties
      delete businessData.logo_preview;
      
      await updateDoc(doc(firestore, 'businesses', businessId), businessData);
      
      // Handle logo update
      if (businessLogo) {
        // Delete old logo if exists
        if (editBusiness.logo_path) {
          await handleDeleteFile(editBusiness.logo_path);
        }
        
        const logoData = await handleFileUpload(businessLogo, businessId, 'logo');
        if (logoData) {
          await updateDoc(doc(firestore, 'businesses', businessId), {
            logo_url: logoData.url,
            logo_path: logoData.path
          });
        }
      }
      
      // Handle BR document update
      if (brDocument) {
        // Delete old document if exists
        if (editBusiness.br_document_path) {
          await handleDeleteFile(editBusiness.br_document_path);
        }
        
        const brData = await handleFileUpload(brDocument, businessId, 'br');
        if (brData) {
          await updateDoc(doc(firestore, 'businesses', businessId), {
            br_document_url: brData.url,
            br_document_path: brData.path,
            br_document_name: brData.name,
            br_document_type: brData.type
          });
        }
      }
      
      setEditBusiness(null);
      setBusinessLogo(null);
      setBrDocument(null);
      toast.success('Business updated successfully');
      fetchBusinesses();
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      toast.error('Error updating business: ' + error.message);
    }
  };

  // Email and Phone handlers
  const handleAddEmail = () => {
    if (newEmail && newEmail.trim()) {
      const emails = editBusiness.emails || [];
      if (!emails.includes(newEmail.trim())) {
        setEditBusiness({
          ...editBusiness,
          emails: [...emails, newEmail.trim()]
        });
        setNewEmail('');
      } else {
        toast.error('Email already exists in the list');
      }
    }
  };

  const handleRemoveEmail = (email) => {
    setEditBusiness({
      ...editBusiness,
      emails: editBusiness.emails.filter(e => e !== email)
    });
  };

  const handleAddPhone = () => {
    if (newPhone && newPhone.trim()) {
      const phones = editBusiness.phones || [];
      if (!phones.includes(newPhone.trim())) {
        setEditBusiness({
          ...editBusiness,
          phones: [...phones, newPhone.trim()]
        });
        setNewPhone('');
      } else {
        toast.error('Phone already exists in the list');
      }
    }
  };

  const handleRemovePhone = (phone) => {
    setEditBusiness({
      ...editBusiness,
      phones: editBusiness.phones.filter(p => p !== phone)
    });
  };

  // Handle file preview
  const handlePreviewFile = (url, type) => {
    setPreviewFile({ url, type });
    setPreviewDialogOpen(true);
  };

  // Client Project operations
  const isProjectAlreadyAssigned = (projectId) => {
    if (!editClient || !editClient.projects) return false;
    return editClient.projects.some(project => project.projectId === projectId);
  };

  const handleAddProjectToClient = async () => {
    try {
      // Check for duplicate assignments
      const newProjects = selectedProjects.filter(project => !isProjectAlreadyAssigned(project.id));
      
      if (newProjects.length === 0) {
        if (selectedProjects.length > 0) {
          toast.error('All selected projects are already assigned to this client');
        } else {
          toast.error('Please select at least one project');
        }
        return;
      }

      const projectData = newProjects.map(project => ({
        projectId: project.id,
        role: projectRole,
        assignedAt: new Date().toISOString()
      }));
      
      await updateDoc(doc(firestore, 'users', editClient.id), {
        projects: arrayUnion(...projectData)
      });
      
      projectData.forEach(async (project) => {
        await updateDoc(doc(firestore, 'projects', project.projectId), {
          users: arrayUnion({ userId: editClient.id, role: project.role })
        });
      });
      
      // Refresh client data instead of closing
      const clientDoc = await getDoc(doc(firestore, 'users', editClient.id));
      if (clientDoc.exists()) {
        setEditClient({ id: clientDoc.id, ...clientDoc.data() });
      }
      
      setSelectedProjects([]);
      setProjectRole([]);
      toast.success('Projects added to client successfully');
    } catch (error) {
      toast.error('Error adding projects: ' + error.message);
    }
  };

  const handleEditProjectRole = async () => {
    try {
      if (!editingProjectRole) return;
      
      // Update the client's project role
      const updatedProjects = editClient.projects.map(project => {
        if (project.projectId === editingProjectRole) {
          return { ...project, role: editProjectRole };
        }
        return project;
      });
      
      await updateDoc(doc(firestore, 'users', editClient.id), {
        projects: updatedProjects
      });
      
      // Update the project's user role
      const projectDoc = await getDoc(doc(firestore, 'projects', editingProjectRole));
      if (projectDoc.exists()) {
        const projectData = projectDoc.data();
        const updatedUsers = projectData.users ? projectData.users.map(user => {
          if (user.userId === editClient.id) {
            return { ...user, role: editProjectRole };
          }
          return user;
        }) : [];
        
        await updateDoc(doc(firestore, 'projects', editingProjectRole), {
          users: updatedUsers
        });
      }
      
      // Refresh client data
      const clientDoc = await getDoc(doc(firestore, 'users', editClient.id));
      if (clientDoc.exists()) {
        setEditClient({ id: clientDoc.id, ...clientDoc.data() });
      }
      
      setEditingProjectRole(null);
      setEditProjectRole([]);
      toast.success('Project role updated successfully');
    } catch (error) {
      toast.error('Error updating project role: ' + error.message);
    }
  };

  const handleRemoveProjectFromClient = async (projectId) => {
    const updatedProjects = editClient.projects.filter(project => project.projectId !== projectId);
    await updateDoc(doc(firestore, 'users', editClient.id), {
      projects: updatedProjects
    });
    await updateDoc(doc(firestore, 'projects', projectId), {
      users: arrayRemove({ userId: editClient.id })
    });
    setEditClient(null);
    toast.success('Project removed from client successfully');
    fetchClients();
  };

  // Client Business operations
  const isBusinessAlreadyAssigned = (businessId) => {
    if (!editClient || !editClient.businesses) return false;
    return editClient.businesses.some(business => business.businessId === businessId);
  };

  const handleAddBusinessToClient = async () => {
    try {
      // Only consider active businesses that aren't already assigned
      const newBusinesses = selectedBusinesses
        .filter(business => business.active !== false)
        .filter(business => !isBusinessAlreadyAssigned(business.id));
      
      if (newBusinesses.length === 0) {
        if (selectedBusinesses.length > 0) {
          const inactiveCount = selectedBusinesses.filter(b => b.active === false).length;
          const duplicateCount = selectedBusinesses.length - inactiveCount - newBusinesses.length;
          
          if (inactiveCount > 0 && duplicateCount > 0) {
            toast.error(`No businesses to add: ${inactiveCount} inactive, ${duplicateCount} already assigned`);
          } else if (inactiveCount > 0) {
            toast.error(`No businesses to add: ${inactiveCount} inactive businesses`);
          } else {
            toast.error('All selected businesses are already assigned to this client');
          }
        } else {
          toast.error('Please select at least one active business');
        }
        return;
      }
      
      const businessData = newBusinesses.map(business => ({
        businessId: business.id,
        role: businessRole,
        assignedAt: new Date().toISOString()
      }));
      
      await updateDoc(doc(firestore, 'users', editClient.id), {
        businesses: arrayUnion(...businessData)
      });
      
      businessData.forEach(async (business) => {
        await updateDoc(doc(firestore, 'businesses', business.businessId), {
          users: arrayUnion({ userId: editClient.id, role: business.role })
        });
      });
      
      // Refresh client data instead of closing
      const clientDoc = await getDoc(doc(firestore, 'users', editClient.id));
      if (clientDoc.exists()) {
        setEditClient({ id: clientDoc.id, ...clientDoc.data() });
      }
      
      setSelectedBusinesses([]);
      setBusinessRole([]);
      toast.success('Businesses added to client successfully');
    } catch (error) {
      toast.error('Error adding businesses: ' + error.message);
    }
  };

  const handleEditBusinessRole = async () => {
    try {
      if (!editingBusinessRole) return;
      
      // Update the client's business role
      const updatedBusinesses = editClient.businesses.map(business => {
        if (business.businessId === editingBusinessRole) {
          return { ...business, role: editBusinessRole };
        }
        return business;
      });
      
      await updateDoc(doc(firestore, 'users', editClient.id), {
        businesses: updatedBusinesses
      });
      
      // Update the business's user role
      const businessDoc = await getDoc(doc(firestore, 'businesses', editingBusinessRole));
      if (businessDoc.exists()) {
        const businessData = businessDoc.data();
        const updatedUsers = businessData.users ? businessData.users.map(user => {
          if (user.userId === editClient.id) {
            return { ...user, role: editBusinessRole };
          }
          return user;
        }) : [];
        
        await updateDoc(doc(firestore, 'businesses', editingBusinessRole), {
          users: updatedUsers
        });
      }
      
      // Refresh client data
      const clientDoc = await getDoc(doc(firestore, 'users', editClient.id));
      if (clientDoc.exists()) {
        setEditClient({ id: clientDoc.id, ...clientDoc.data() });
      }
      
      setEditingBusinessRole(null);
      setEditBusinessRole([]);
      toast.success('Business role updated successfully');
    } catch (error) {
      toast.error('Error updating business role: ' + error.message);
    }
  };

  const handleRemoveBusinessFromClient = async (businessId) => {
    const updatedBusinesses = editClient.businesses.filter(business => business.businessId !== businessId);
    await updateDoc(doc(firestore, 'users', editClient.id), {
      businesses: updatedBusinesses
    });
    await updateDoc(doc(firestore, 'businesses', businessId), {
      users: arrayRemove({ userId: editClient.id })
    });
    setEditClient(null);
    toast.success('Business removed from client successfully');
    fetchClients();
  };

  // Handle business roles and category
  const handleBusinessRoleChange = (event) => {
    const selectedRoles = event.target.value;
    
    // Check if "other" is selected
    if (selectedRoles.includes('other') && !businessRole.includes('other')) {
      setCustomRoleDialogOpen(true);
    }
    
    setBusinessRole(selectedRoles);
  };

  const handleDeleteBusinessRole = (roleToDelete) => {
    setBusinessRole((roles) => roles.filter(role => role !== roleToDelete));
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    
    if (selectedCategory === 'other') {
      setCustomCategoryDialogOpen(true);
    } else {
      setEditBusiness({
        ...editBusiness,
        business_category: selectedCategory
      });
    }
  };

  // Filter businesses for client selection - only show active businesses
  const availableBusinessesForClient = businesses.filter(business => business.active !== false);

  // Client table handlers
  const handleClientRequestSort = (event, property) => {
    const isAsc = clientOrderBy === property && clientOrder === 'asc';
    setClientOrder(isAsc ? 'desc' : 'asc');
    setClientOrderBy(property);
  };

  const handleClientSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = clients.map((n) => n.id);
      setSelectedClients(newSelected);
      return;
    }
    setSelectedClients([]);
  };

  const handleClientClick = (event, id) => {
    const selectedIndex = selectedClients.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedClients, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedClients.slice(1));
    } else if (selectedIndex === selectedClients.length - 1) {
      newSelected = newSelected.concat(selectedClients.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedClients.slice(0, selectedIndex),
        selectedClients.slice(selectedIndex + 1),
      );
    }
    setSelectedClients(newSelected);
  };

  const handleClientChangePage = (event, newPage) => {
    setClientPage(newPage);
  };

  const handleClientChangeRowsPerPage = (event) => {
    setClientRowsPerPage(parseInt(event.target.value, 10));
    setClientPage(0);
  };

  const handleClientChangeDense = (event) => {
    setClientDense(event.target.checked);
  };

  const handleClientSearch = (event) => {
    setClientSearchKeyword(event.target.value);
  };

  // Business table handlers
  const handleBusinessRequestSort = (event, property) => {
    const isAsc = businessOrderBy === property && businessOrder === 'asc';
    setBusinessOrder(isAsc ? 'desc' : 'asc');
    setBusinessOrderBy(property);
  };

  const handleBusinessSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = businesses.map((n) => n.id);
      setSelectedBusinessesRows(newSelected);
      return;
    }
    setSelectedBusinessesRows([]);
  };

  const handleBusinessClick = (event, id) => {
    const selectedIndex = selectedBusinessesRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedBusinessesRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedBusinessesRows.slice(1));
    } else if (selectedIndex === selectedBusinessesRows.length - 1) {
      newSelected = newSelected.concat(selectedBusinessesRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedBusinessesRows.slice(0, selectedIndex),
        selectedBusinessesRows.slice(selectedIndex + 1),
      );
    }
    setSelectedBusinessesRows(newSelected);
  };

  const handleBusinessChangePage = (event, newPage) => {
    setBusinessPage(newPage);
  };

  const handleBusinessChangeRowsPerPage = (event) => {
    setBusinessRowsPerPage(parseInt(event.target.value, 10));
    setBusinessPage(0);
  };

  const handleBusinessChangeDense = (event) => {
    setBusinessDense(event.target.checked);
  };

  const handleBusinessSearch = (event) => {
    setBusinessSearchKeyword(event.target.value);
  };

  // Filter and Sort
  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(clientSearchKeyword.toLowerCase())
  );

  const filteredBusinesses = businesses.filter(business =>
    business.name?.toLowerCase().includes(businessSearchKeyword.toLowerCase())
  );

  const visibleClients = React.useMemo(
    () =>
      [...filteredClients]
        .sort((a, b) => (clientOrder === 'asc' ? a[clientOrderBy] > b[clientOrderBy] : a[clientOrderBy] < b[clientOrderBy]) ? 1 : -1)
        .slice(clientPage * clientRowsPerPage, clientPage * clientRowsPerPage + clientRowsPerPage),
    [clientOrder, clientOrderBy, clientPage, clientRowsPerPage, filteredClients],
  );

  const visibleBusinesses = React.useMemo(
    () =>
      [...filteredBusinesses]
        .sort((a, b) => (businessOrder === 'asc' ? a[businessOrderBy] > b[businessOrderBy] : a[businessOrderBy] < b[businessOrderBy]) ? 1 : -1)
        .slice(businessPage * businessRowsPerPage, businessPage * businessRowsPerPage + businessRowsPerPage),
    [businessOrder, businessOrderBy, businessPage, businessRowsPerPage, filteredBusinesses],
  );

  const emptyClientRows = clientPage > 0 ? Math.max(0, (1 + clientPage) * clientRowsPerPage - filteredClients.length) : 0;
  const emptyBusinessRows = businessPage > 0 ? Math.max(0, (1 + businessPage) * businessRowsPerPage - filteredBusinesses.length) : 0;

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4">Manage Clients and Businesses</h1>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="client and business tabs">
          <Tab label="Manage Clients" />
          <Tab label="Manage Businesses" />
        </Tabs>
      </Box>

      {/* Custom Role Dialog */}
      <Dialog open={customRoleDialogOpen} onClose={() => setCustomRoleDialogOpen(false)}>
        <DialogTitle>Add Custom Business Role</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Custom Role"
            fullWidth
            value={customRole}
            onChange={(e) => setCustomRole(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomRoleDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddCustomRole} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Custom Category Dialog */}
      <Dialog open={customCategoryDialogOpen} onClose={() => setCustomCategoryDialogOpen(false)}>
        <DialogTitle>Add Custom Business Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Custom Category"
            fullWidth
            value={customCategory}
            onChange={(e) => setCustomCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomCategoryDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddCustomCategory} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* File Preview Dialog */}
      <FilePreviewDialog
        open={previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        fileUrl={previewFile.url}
        fileType={previewFile.type}
      />

      {/* Dialog for editing project role */}
      <Dialog open={!!editingProjectRole} onClose={() => setEditingProjectRole(null)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Project Role</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={editProjectRole}
              onChange={(e) => setEditProjectRole(e.target.value)}
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingProjectRole(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditProjectRole} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing business role */}
      <Dialog open={!!editingBusinessRole} onClose={() => setEditingBusinessRole(null)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Business Role</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Business Roles</InputLabel>
            <Select
              multiple
              value={editBusinessRole}
              onChange={(e) => setEditBusinessRole(e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip 
                      key={value} 
                      label={value} 
                      onDelete={() => setEditBusinessRole(roles => roles.filter(role => role !== value))}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ))}
                </Box>
              )}
            >
              {businessRoles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingBusinessRole(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditBusinessRole} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Client Tab */}
      <TabPanel value={tabValue} index={0}>
        <div className='bg-white rounded-sm mt-2 px-2 mb-2 flex items-center gap-2'>
          <TextField
            label="Search Clients by Name"
            value={clientSearchKeyword}
            onChange={handleClientSearch}
            fullWidth
            margin="normal"
          />
          <IconButton onClick={fetchClients} title="Refresh clients">
            <RefreshIcon />
          </IconButton>
        </div>

        <Dialog open={!!editClient} onClose={() => setEditClient(null)} fullWidth maxWidth="md">
          <DialogTitle>Edit Client</DialogTitle>
          <DialogContent>
            {editClient && (
              <>
                <TextField
                  label="UID"
                  value={editClient.id}
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Email"
                  value={editClient.email}
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Name"
                  value={editClient.name}
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Phone"
                  value={editClient.phone}
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Username"
                  value={editClient.username}
                  fullWidth
                  margin="normal"
                  disabled
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Projects</InputLabel>
                  <Autocomplete
                    multiple
                    options={projects}
                    getOptionLabel={(option) => option.lowerTitle || option.title || option.id}
                    onChange={(event, newValue) => setSelectedProjects(newValue)}
                    renderInput={(params) => <TextField {...params} label="Projects" />}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Roles</InputLabel>
                  <Select
                    multiple
                    value={projectRole}
                    onChange={(e) => setProjectRole(e.target.value)}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button onClick={handleAddProjectToClient} color="primary">
                  Add Projects
                </Button>
                <div>
                  <h3 className=' font-semibold mt-5 border-t-2 py-2'>Assigned Projects</h3>
                  {editClient.projects && editClient.projects.map((project) => {
                    const projectDetails = projects.find(p => p.id === project.projectId);
                    return (
                      <div className='w-full bg-base-200 p-3 shadow-xl rounded-lg hover:bg-base-300 text-base-content mt-5' key={project.projectId}>
                        <p>
                          {projectDetails?.title || project.projectId} - 
                          <Chip 
                            label={project.role.join(', ')}
                            color="primary" 
                            size="small"
                            onClick={() => {
                              setEditProjectRole(project.role);
                              setEditingProjectRole(project.projectId);
                            }}
                            sx={{ marginLeft: 1, cursor: 'pointer' }}
                          />
                        </p>
                        <Button onClick={() => handleRemoveProjectFromClient(project.projectId)} color="error">
                          Remove
                        </Button>
                      </div>
                    );
                  })}
                </div>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Businesses</InputLabel>
                  <Autocomplete
                    multiple
                    options={availableBusinessesForClient}
                    getOptionLabel={(option) => option.name || option.id}
                    onChange={(event, newValue) => setSelectedBusinesses(newValue)}
                    renderInput={(params) => <TextField {...params} label="Businesses (Only Active)" />}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Business Roles</InputLabel>
                  <Select
                    multiple
                    value={businessRole}
                    onChange={handleBusinessRoleChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip 
                            key={value} 
                            label={value} 
                            onDelete={() => handleDeleteBusinessRole(value)}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {businessRoles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button onClick={handleAddBusinessToClient} color="primary">
                  Add Businesses
                </Button>
                <div>
                  <h3 className=' font-semibold mt-5 border-t-2 py-2'>Assigned Businesses</h3>
                  {editClient.businesses && editClient.businesses.map((business) => {
                    const businessDetails = businesses.find(b => b.id === business.businessId);
                    return (
                      <div className='w-full bg-base-200 p-3 shadow-xl rounded-lg hover:bg-base-300 text-base-content mt-5' key={business.businessId}>
                        <p>
                          {businessDetails?.name || business.businessId} - 
                          <Chip 
                            label={business.role.join(', ')}
                            color="primary"
                            size="small"
                            onClick={() => {
                              setEditBusinessRole(business.role);
                              setEditingBusinessRole(business.businessId);
                            }}
                            sx={{ marginLeft: 1, cursor: 'pointer' }}
                          />
                        </p>
                        <Button onClick={() => handleRemoveBusinessFromClient(business.businessId)} color="error">
                          Remove
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditClient(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Toolbar
              sx={[
                {
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                },
                selectedClients.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                },
              ]}
            >
              {selectedClients.length > 0 ? (
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selectedClients.length} selected
                </Typography>
              ) : (
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Clients
                </Typography>
              )}
            </Toolbar>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={clientDense ? 'small' : 'medium'}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={selectedClients.length > 0 && selectedClients.length < clients.length}
                        checked={clients.length > 0 && selectedClients.length === clients.length}
                        onChange={handleClientSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all clients',
                        }}
                      />
                    </TableCell>
                    <TableCell
                      key="name"
                      align="left"
                      padding="normal"
                      sortDirection={clientOrderBy === 'name' ? clientOrder : false}
                    >
                      <TableSortLabel
                        active={clientOrderBy === 'name'}
                        direction={clientOrderBy === 'name' ? clientOrder : 'asc'}
                        onClick={(event) => handleClientRequestSort(event, 'name')}
                      >
                        Name
                        {clientOrderBy === 'name' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {clientOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Phone</TableCell>
                    <TableCell align="left">Username</TableCell>
                    <TableCell align="left">Roles</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleClients.map((client, index) => {
                    const isItemSelected = selectedClients.includes(client.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClientClick(event, client.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={client.id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {client.name}
                        </TableCell>
                        <TableCell align="left">{client.email}</TableCell>
                        <TableCell align="left">{client.phone}</TableCell>
                        <TableCell align="left">{client.username}</TableCell>
                        <TableCell align="left">{client.role?.join(', ') || 'N/A'}</TableCell>
                        <TableCell align="left">
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            setEditClient(client);
                          }}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyClientRows > 0 && (
                    <TableRow
                      style={{
                        height: (clientDense ? 33 : 53) * emptyClientRows,
                      }}
                    >
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredClients.length}
              rowsPerPage={clientRowsPerPage}
              page={clientPage}
              onPageChange={handleClientChangePage}
              onRowsPerPageChange={handleClientChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={clientDense} onChange={handleClientChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </TabPanel>

      {/* Business Tab */}
      <TabPanel value={tabValue} index={1}>
        <div className='bg-white rounded-sm mt-2 px-2 mb-2 flex items-center gap-2'>
          <TextField
            label="Search Businesses by Name"
            value={businessSearchKeyword}
            onChange={handleBusinessSearch}
            fullWidth
            margin="normal"
          />
          <IconButton onClick={fetchBusinesses} title="Refresh businesses">
            <RefreshIcon />
          </IconButton>
          <IconButton onClick={() => setEditBusiness({
            name: '', 
            description: '', 
            emails: [], 
            phones: [], 
            active: true
          })} title="Add new business">
            <AddIcon />
          </IconButton>
        </div>

        <Dialog open={!!editBusiness} onClose={() => setEditBusiness(null)} fullWidth maxWidth="md">
          <DialogTitle>{editBusiness && editBusiness.id ? 'Edit Business' : 'Create Business'}</DialogTitle>
          <DialogContent>
            {editBusiness && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {/* Basic Information */}
                  {editBusiness.id && (
                    <TextField
                      label="ID"
                      value={editBusiness.id}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  )}
                  <TextField
                    label="Business Name"
                    value={editBusiness.name || ''}
                    onChange={(e) => setEditBusiness({ ...editBusiness, name: e.target.value })}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Business Category</InputLabel>
                    <Select
                      value={editBusiness.business_category || ''}
                      onChange={handleCategoryChange}
                      label="Business Category"
                    >
                      {businessCategories.map((category) => (
                        <MenuItem key={category} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Business Registration Number"
                    value={editBusiness.business_registration_number || ''}
                    onChange={(e) => setEditBusiness({ ...editBusiness, business_registration_number: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Business Registered Year"
                    value={editBusiness.business_registered_year || ''}
                    onChange={(e) => setEditBusiness({ ...editBusiness, business_registered_year: e.target.value })}
                    fullWidth
                    margin="normal"
                    type="number"
                    inputProps={{ min: 1900, max: new Date().getFullYear() }}
                  />
                  <TextField
                    label="Website"
                    value={editBusiness.website || ''}
                    onChange={(e) => setEditBusiness({ ...editBusiness, website: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editBusiness.active !== false}
                        onChange={(e) => setEditBusiness({ ...editBusiness, active: e.target.checked })}
                      />
                    }
                    label={`Business Status: ${editBusiness.active !== false ? 'Active' : 'Inactive'}`}
                  />
                  <FormHelperText>
                    {editBusiness.active !== false 
                      ? 'This business can be assigned to clients' 
                      : 'This business cannot be assigned to clients'}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Logo and Documents */}
                  <Typography variant="subtitle1" gutterBottom>Business Logo</Typography>
                  {(editBusiness.logo_url || editBusiness.logo_preview) && (
                    <Card>
                      <CardMedia
                        component="img"
                        height="140"
                        image={editBusiness.logo_preview || editBusiness.logo_url}
                        alt="Business Logo"
                      />
                      <CardActions>
                        <Button 
                          size="small" 
                          color="primary" 
                          onClick={() => handlePreviewFile(editBusiness.logo_url, 'image')}
                        >
                          <VisibilityIcon /> Preview
                        </Button>
                        <Button 
                          size="small" 
                          color="secondary" 
                          onClick={() => handleDeleteFile(editBusiness.logo_path)}
                        >
                          <DeleteIcon /> Delete
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                  >
                    Upload Logo
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, 'logo')}
                    />
                  </Button>
                  <TextField
                    label="Logo URL"
                    value={editBusiness.logo_url || ''}
                    onChange={(e) => setEditBusiness({ ...editBusiness, logo_url: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                  <Typography variant="subtitle1" gutterBottom>Business Registration Document</Typography>
                  {editBusiness.br_document_url && (
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={editBusiness.br_document_name}
                          secondary={editBusiness.br_document_type}
                        />
                        <ListItemSecondaryAction>
                          <IconButton 
                            edge="end" 
                            aria-label="preview" 
                            onClick={() => handlePreviewFile(editBusiness.br_document_url, editBusiness.br_document_type)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton 
                            edge="end" 
                            aria-label="delete" 
                            onClick={() => handleDeleteFile(editBusiness.br_document_path)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                  >
                    Upload BR Document
                    <input
                      type="file"
                      hidden
                      accept="application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(e) => handleFileChange(e, 'br')}
                    />
                  </Button>
                  <TextField
                    label="BR Document URL"
                    value={editBusiness.br_document_url || ''}
                    onChange={(e) => setEditBusiness({ ...editBusiness, br_document_url: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* Emails and Phones */}
                  <Typography variant="subtitle1" gutterBottom>Emails</Typography>
                  <List>
                    {editBusiness.emails && editBusiness.emails.map((email, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={email} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveEmail(email)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <ListItem>
                      <TextField
                        label="Add Email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <IconButton edge="end" aria-label="add" onClick={handleAddEmail}>
                        <AddIcon />
                      </IconButton>
                    </ListItem>
                  </List>
                  <Typography variant="subtitle1" gutterBottom>Phones</Typography>
                  <List>
                    {editBusiness.phones && editBusiness.phones.map((phone, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={phone} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemovePhone(phone)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <ListItem>
                      <TextField
                        label="Add Phone"
                        value={newPhone}
                        onChange={(e) => setNewPhone(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <IconButton edge="end" aria-label="add" onClick={handleAddPhone}>
                        <AddIcon />
                      </IconButton>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditBusiness(null)} color="secondary">
              Cancel
            </Button>
            <Button 
              onClick={editBusiness && editBusiness.id ? handleUpdateBusiness : handleCreateBusiness} 
              color="primary"
              disabled={isUpdating}
            >
              {editBusiness && editBusiness.id ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Toolbar
              sx={[
                {
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                },
                selectedBusinessesRows.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                },
              ]}
            >
              {selectedBusinessesRows.length > 0 ? (
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selectedBusinessesRows.length} selected
                </Typography>
              ) : (
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Businesses
                </Typography>
              )}
            </Toolbar>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={businessDense ? 'small' : 'medium'}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={selectedBusinessesRows.length > 0 && selectedBusinessesRows.length < businesses.length}
                        checked={businesses.length > 0 && selectedBusinessesRows.length === businesses.length}
                        onChange={handleBusinessSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all businesses',
                        }}
                      />
                    </TableCell>
                    <TableCell
                      key="name"
                      align="left"
                      padding="normal"
                      sortDirection={businessOrderBy === 'name' ? businessOrder : false}
                    >
                      <TableSortLabel
                        active={businessOrderBy === 'name'}
                        direction={businessOrderBy === 'name' ? businessOrder : 'asc'}
                        onClick={(event) => handleBusinessRequestSort(event, 'name')}
                      >
                        Name
                        {businessOrderBy === 'name' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {businessOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Website</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Phone</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleBusinesses.map((business, index) => {
                    const isItemSelected = selectedBusinessesRows.includes(business.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleBusinessClick(event, business.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={business.id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {business.name}
                        </TableCell>
                        <TableCell align="left">{business.description}</TableCell>
                        <TableCell align="left">{business.website}</TableCell>
                        <TableCell align="left">{business.email}</TableCell>
                        <TableCell align="left">{business.phone}</TableCell>
                        <TableCell align="left">
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            setEditBusiness(business);
                          }}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyBusinessRows > 0 && (
                    <TableRow
                      style={{
                        height: (businessDense ? 33 : 53) * emptyBusinessRows,
                      }}
                    >
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredBusinesses.length}
              rowsPerPage={businessRowsPerPage}
              page={businessPage}
              onPageChange={handleBusinessChangePage}
              onRowsPerPageChange={handleBusinessChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={businessDense} onChange={handleBusinessChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </TabPanel>
    </div>
  );
};

export default ManageClientsAndBusinesses;
